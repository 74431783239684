import { colors } from 'components/variables'
import styled from 'styled-components'

export const NumberWrapper = styled.span`
	.number {
		font-weight: 400;
		font-size: 22px;
		font-weight: normal;
		font-family: 'Montserrat', sans-serif;
		line-height: 20px;
		padding: 0 3px 0 0;
		color: ${colors.black};
	}
`
