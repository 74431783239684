import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import history from 'utils/history'
import { reducer as AuthReducer } from 'containers/Auth/slices'
import { reducer as CommunitiesReducer } from 'containers/Communities/slices'
import { reducer as ProjectsReducer } from 'containers/Projects/slices'
import { reducer as NewsReducer } from 'containers/News/slices'
import { reducer as BranchesReducer } from 'containers/Branches/slices'
import { reducer as PropositionsReducer } from 'containers/Propisitions/slices'
import { reducer as ProjectReducer } from 'containers/Project/slices'
import { reducer as BranchReducer } from 'containers/Branch/slices'
import { reducer as NewItemReducer } from 'containers/New/slices'
import { reducer as HistoriesSuccessReducer } from 'containers/HistoriesSuccess/slices'
import { reducer as HistoryItemReducer } from 'containers/History/slices'
import { reducer as PropositionReducer } from 'containers/Proposition/slices'
import { reducer as CommunityReducer } from 'containers/Community/slices'
import { reducer as UserReducer } from 'containers/slices/user'
import { reducer as ProgramsReducer } from 'containers/Programs/slices'
import { reducer as ProgramReducer } from 'containers/DocumentScreen/slices'

export default function createReducer(injectedReducers = {}) {
	const appReducer = combineReducers({
		router: connectRouter(history),
		auth: AuthReducer,
		communities: CommunitiesReducer,
		community: CommunityReducer,
		projects: ProjectsReducer,
		news: NewsReducer,
		branches: BranchesReducer,
		branch: BranchReducer,
		propositions: PropositionsReducer,
		project: ProjectReducer,
		newItem: NewItemReducer,
		histories: HistoriesSuccessReducer,
		historyItem: HistoryItemReducer,
		proposition: PropositionReducer,
		user: UserReducer,
		programs: ProgramsReducer,
		program: ProgramReducer,
		...injectedReducers,
	})

	const rootReducer = (state, action) => {
		return appReducer(state, action)
	}

	return rootReducer
}
