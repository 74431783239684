import { colors, device } from 'components/variables'
import styled, { css, createGlobalStyle } from 'styled-components'
import { textColor } from 'utils/help-func'

export const TitleLarge = styled.h1`
	${(props) => textColor({ defaultColor: 'black', ...props })}
	font-size: 30px;
	font-weight: ${(props) => props.weight || 'bold'};
	text-align: center;
	line-height: 37px;
	@media ${device.tablet} {
		font-size: 36px;
	}
`

export const SubtitleLarge = styled.h3`
	${(props) => textColor({ defaultColor: 'black', ...props })}
	font-size: 20px;
	font-weight: ${(props) => props.weight || 'normal'};
	line-height: 24px;
	@media ${device.tablet} {
		font-size: 25px;
		line-height: 30px;
	}
	&.blockSlice {
		display: inline-block;
		vertical-align: middle;
		width: 90%;
		text-overflow: ellipsis;
		white-space: pre-wrap;
		overflow: hidden;
		@media ${device.tabletM} {
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: pre-wrap;
			overflow-wrap: break-word;
		}
	}
`

export const LoginSmall = styled.div`
	font-size: 15px;
	${(props) => textColor({ defaultColor: 'darkGrey', ...props })}
	margin-left: auto;
	cursor: pointer;
	&:hover {
		color: ${colors.blue};
		text-decoration: underline;
	}
`

export const TextSmallPt = styled.div`
	font-family: 'PT Sans', 'sans-serif';
	font-size: 15px;
	line-height: ${(props) => (props.lh ? '140%' : '130%')};
	${(props) => textColor({ defaultColor: 'black', ...props })}
	width: 100%;
`

// Image  ------------------

export const FillImage = styled.img`
	width: 100%;
	height: 100%;
	object-fit: fill;
`

export const NoImage = styled.div`
	background-color: #8080806e;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
`

export const NoImg = styled.img`
	width: 138px;
	height: 112px;
	object-fit: fill;
`

// Line   ----------------------

export const Line = styled.hr`
	height: 1px;
	width: 100%;
	background: ${colors.orange};
	margin: 15px 0;
	border: none;
`

// Empty containers for bootstrap --------

export const Container = styled.div``

export const Row = styled.div``

export const Col = styled.div`
	padding-left: 15px;
	padding-right: 15px;
`

// Video ---------------------------

export const ResponsiveVideo = styled.video`
	width: 100%;
	height: auto;
	max-height: ${(props) => `${props.maxHeight}px`};
	aspect-ratio: 16/9;
`

export const VideoSource = styled.source``

// Mock style -----------------

export const ReverseItem = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
	flex-direction: column;
	align-items: center;
	vertical-align: middle;
	@media ${device.tablet} {
		flex-direction: ${(props) => (props.reverse ? 'row-reverse' : 'row')};
	}
`

export const ReverseItemImage = styled.div`
	width: 100%;
	height: 100%;
	max-width: 570px;
	max-height: 320px;
	margin-right: 0;
	margin-right: 0;
	margin-top: 60px;
	@media ${device.tablet} {
		margin-top: 0;
		margin-right: 30px;
		${(props) =>
			props.reverse &&
			css`
				margin-left: 30px;
				margin-right: 0;
			`}
	}
`

export const BigText = styled.div`
	font-family: 'PT Sans', 'sans-serif';
	font-size: 35px;
	line-height: 58px;
	${textColor({ defaultColor: 'black', colorSpan: 'blue' })}
	span {
		font-weight: 500;
	}
	@media ${device.tablet} {
		font-size: 45px;
	}
`

export const Tab = styled.div`
	width: 100%;
	border: 1px solid ${colors.blue};
	color: ${(props) => (props.active ? colors.blue : colors.white)};
	background: ${(props) => (props.active ? colors.white : colors.blue)};
	padding: 22px 0;
	text-align: center;
	font-size: 21px;
	transition: 0.25s;
	margin-top: 30px;
	cursor: pointer;
	&:first-child {
		margin-top: 0;
	}
	&:hover {
		color: ${colors.blue};
		background: ${colors.white};
	}
`

export const ResponseImage = styled.img`
	width: 100%;
`

// global scroll off
export const GlobalStyle = createGlobalStyle`
	${(p) =>
		p.open &&
		css`
			body {
				overflow: hidden;
			}
		`}
`

export const NoData = styled.div`
	text-align: center;
	font-weight: 800;
	font-size: 60px;
	line-height: 60px;
	margin-top: 50px;
	color: ${colors.nodata};
`

export const SupperImg = styled.div`
	width: 100%;
	height: 100%;
	.img {
		width: 100%;
		height: 100%;
		object-fit: contain;
	}
`

export const CommunityImg = styled(SupperImg)`
	.img {
		object-fit: contain;
	}
`

// KPI styled ------------------------

export const KPIWrapper = styled.div`
	height: 380px;
`
