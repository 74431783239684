import { colors, device } from 'components/variables'
import styled from 'styled-components'
import checked from 'static/images/checked.svg'

export const Wrapper = styled.div``

export const CheckBoxHidden = styled.input`
	position: absolute;
	z-index: -1;
	opacity: 0;
`

export const CheckLabel = styled.label`
	display: inline-flex;
	align-items: center;
	user-select: none;
	cursor: pointer;
	font-size: 11px;
	a {
		color: ${colors.orange};
		text-decoration: none;
	}
	&:hover {
		&::before {
			border-color: ${colors.blue};
		}
	}
	&::before {
		content: '';
		display: inline-block;
		width: 18px;
		height: 18px;
		flex-shrink: 0;
		flex-grow: 0;
		border: 1px solid ${(props) => (props.errors ? colors.red : colors.grey)};
		margin-right: 0.5em;
		background-repeat: no-repeat;
		background-position: center center;
		background-image: none;
	}
	${CheckBoxHidden}:checked + &::before {
		border-color: ${colors.blue};
		background-image: url(${checked});
	}
	@media ${device.tablet} {
		font-size: 15px;
		&::before {
			width: 22px;
			height: 22px;
		}
	}
`
