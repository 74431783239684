import React from 'react'
import Slider from 'react-slick'
import { SliderNext, SliderPrev } from 'components/Icons'
import { NewsSliderWrapp } from './style'
import NewsItem from 'components/News/news-item'
import history from 'utils/history'

const SlickButtonFix = ({ currentSlide, slideCount, children, ...props }) => (
	<span {...props}>{children}</span>
)

const settings = {
	dots: false,
	infinite: true,
	speed: 800,
	slidesToShow: 1,
	slidesToScroll: 1,
	adaptiveHeight: true,
	nextArrow: (
		<SlickButtonFix>
			<SliderNext />
		</SlickButtonFix>
	),
	prevArrow: (
		<SlickButtonFix>
			<SliderPrev />
		</SlickButtonFix>
	),
}

function NewsSlider({ news, ...props }) {
	const hendleCallHashteg = (hashtag) => {
		history.push('/news', { detail: hashtag })
	}

	return (
		<NewsSliderWrapp>
			<Slider {...settings}>
				{news.map((item, _index) => (
					<NewsItem
						key={_index}
						item={item}
						mode='small'
						hendleCallHashteg={hendleCallHashteg}
					/>
				))}
			</Slider>
		</NewsSliderWrapp>
	)
}

export default NewsSlider
