import React from 'react'
import Slider from 'react-slick'
import { SliderNext, SliderPrev } from 'components/Icons'
import { HistoriesSliderWrapp } from './style'
import HistoryOneItem from 'components/HistoryItem/historyItem'

const SlickButtonFix = ({ currentSlide, slideCount, children, ...props }) => (
	<span {...props}>{children}</span>
)

const settings = {
	dots: false,
	infinite: true,
	speed: 800,
	slidesToShow: 1,
	slidesToScroll: 1,
	adaptiveHeight: true,
	nextArrow: (
		<SlickButtonFix>
			<SliderNext />
		</SlickButtonFix>
	),
	prevArrow: (
		<SlickButtonFix>
			<SliderPrev />
		</SlickButtonFix>
	),
}

function HistoriesSlider({ histories, ...props }) {
	return (
		<HistoriesSliderWrapp>
			<Slider {...settings}>
				{histories.map((item, _index) => (
					<HistoryOneItem
						className='w-100'
						_index={_index}
						key={_index}
						item={item}
						mode='small'
					/>
				))}
			</Slider>
		</HistoriesSliderWrapp>
	)
}

export default HistoriesSlider
