import React, { memo, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import HeaderPhoto from 'components/HeaderPhoto';
import HeeaderPath from 'components/HeaderPath/headerPath';
import Pagination from 'components/Pagination/pagination';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { actions, select } from './slices';
import HistoryOneItem from 'components/HistoryItem/historyItem';
import GenerBG from 'static/images/historiesBG.png';

import { NoData } from 'components/Styled';

import { HistoriesWrapper, HistoriesContentWrapper } from './style';
import Loader from 'components/Loader';
import { NewsContentWrapper } from 'containers/News/style';
import i18n from 'i18n';

function HistoriesSec(props) {
  const [def_count] = useState(4);
  const [selected, setSelected] = useState(0);
  const { loading, histories, getHistoies, t } = props;
  const links = [
    { generalLink: '/create/history' },
    { label: t('headerPath.home'), link: '/home' },
    { label: t('headerPath.histories'), link: '#' },
  ];
  const handleCallBack = (object) => {
    const obj = { skip: object.selected * def_count, limit: def_count };
    setSelected(object.selected);
    getHistoies(obj);
  };
  const curentlang = i18n.language;
  useEffect(() => {
    getHistoies();
  }, [curentlang]);
  const dataDone = histories.length ? false : true;
  return loading ? (
    <Loader />
  ) : (
    <HistoriesWrapper>
      <HeaderPhoto bg={GenerBG} label={'Історії успіху'} />
      <NewsContentWrapper>
        <HeeaderPath plusLabel={'історію'} admin searchBox links={links} />
        {dataDone ? (
          <NoData>історій не знайдено</NoData>
        ) : (
          <>
            <HistoriesContentWrapper>
              <div className='mt-6'>
                {histories.map((item, _index) => (
                  <div key={_index} className='mb-7'>
                    <HistoryOneItem item={item} />
                  </div>
                ))}
              </div>
              <Pagination
                pageCount={histories.length / def_count}
                callBack={handleCallBack}
                sel={selected}
              />
            </HistoriesContentWrapper>
          </>
        )}
      </NewsContentWrapper>
    </HistoriesWrapper>
  );
}

const mapStateToProps = createStructuredSelector({
  loading: select.loading,
  histories: select.histories,
});

const withConnect = connect(mapStateToProps, {
  getHistoies: actions.getHistoies,
});

export default compose(withConnect, memo, withTranslation())(HistoriesSec);
