import { createSlice } from '@reduxjs/toolkit'

export * from './selectors'

const propositionsSlice = createSlice({
	name: 'propositions',
	initialState: {
		propositions: [],
		count: 0,
		loading: false,
	},
	reducers: {
		getPropositionsStart: (state) => {
			state.loading = true
		},
		getSuccess: (state, { payload }) => {
			state.propositions = payload.data
			state.count = payload.count
			state.loading = false
		},
		getError: (state) => {
			state.loading = false
		},
		getEnd: (state) => {
			state.loading = false
		},
	},
})

export const { actions, reducer } = propositionsSlice
