import React from 'react'
import Slider from 'react-slick'
import {
	Slide,
	SlideImgBlock,
	SliderWrapp,
	SlideText,
	SlideTextBlock,
	SlideTitle,
} from './style'
import { SlideImage, SliderNext, SliderPrev } from 'components/Icons'

const SlickButtonFix = ({ currentSlide, slideCount, children, ...props }) => (
	<span {...props}>{children}</span>
)

const settings = {
	dots: true,
	infinite: true,
	speed: 500,
	slidesToShow: 1,
	slidesToScroll: 1,
	adaptiveHeight: true,
	swipe: false,
	arrows: true,
	nextArrow: (
		<SlickButtonFix className='slick-disable'>
			<SliderNext />
		</SlickButtonFix>
	),
	prevArrow: (
		<SlickButtonFix>
			<SliderPrev />
		</SlickButtonFix>
	),
}

export function HomeSlider({region, invest}) {
	return (
		<SliderWrapp>
			<Slider {...settings}>
				<Slide>
					<SlideTextBlock>
						<SlideTitle>{region}</SlideTitle> <br />
						<SlideText>
							{invest}
						</SlideText>
					</SlideTextBlock>
					<SlideImgBlock>
						{/* <SlideImg src={slideImg1} alt='Slide Image' /> */}
						<a href='#google_map'>
							<SlideImage width='100%' height='100%' />
						</a>
					</SlideImgBlock>
				</Slide>
				{/* <Slide>
					<SlideTextBlock>
						<SlideTitle>Вугільні регіони України</SlideTitle> <br />
						<SlideText>
							Справедлива трансформація та <br /> інвестиції
						</SlideText>
					</SlideTextBlock>
					<SlideImgBlock>
						<SlideImg src={slideImg2} alt='Slide Image' />
					</SlideImgBlock>
				</Slide>
				<Slide>
					<SlideTextBlock>
						<SlideTitle>Вугільні регіони України</SlideTitle> <br />
						<SlideText>
							Справедлива трансформація та <br /> інвестиції
						</SlideText>
					</SlideTextBlock>
					<SlideImgBlock>
						<SlideImg src={slideImg3} alt='Slide Image' />
					</SlideImgBlock>
				</Slide> */}
			</Slider>
		</SliderWrapp>
	)
}
