import React from 'react'
import { TitleLarge } from 'components/Styled'
// import pdf_file from 'static/pdf/sample_3.pdf'
import PdfComponent from 'components/ReaderPDF/test'
import { PDFDownloadLink } from '../style'
import { pdfjs } from 'react-pdf'
import { useTranslation } from 'react-i18next'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

function Passport({ title, pdf, ...props }) {
	const { t } = useTranslation()

	return (
		<>
			{title && <TitleLarge>{title}</TitleLarge>}
			{!!pdf ? (
				<>
					<PdfComponent file={pdf} className='text-center mt-5' />
					<PDFDownloadLink href={pdf} download className='mt-3'>
						{t('downloadDocument.title')}
					</PDFDownloadLink>
				</>
			) : (
				<TitleLarge weight='normal' className='mt-8'>
					No File
				</TitleLarge>
			)}
		</>
	)
}

export default Passport
