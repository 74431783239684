import { call, put, takeLatest } from '@redux-saga/core/effects'
import Api from 'utils/api'
import { actions } from './slices'

function* getBranches({ payload }) {
	const body = {
		filter: {
			name: '',
			limit: 10,
			skip: 0,
		},
	}
	try {
		const { data } = yield call(Api.post, '/api/industries/get', body)
		yield put({
			...actions.getSuccess({
				data: data.industries,
			}),
		})
	} catch (e) {
		yield put({ ...actions.getError() })
	}
}

export function* barnchesSaga() {
	yield takeLatest(actions.getBranchesStart, getBranches)
}
