import environment from 'environment'
import moment from 'moment'

export class NewsModel {
	constructor(data = {}) {
		this.author = data?.author || ''
		this._id = data?._id || ''
		this.name = data?.name || ''
		this.image = `${environment.staticUrl}images/${data?.mainphoto}`
		this.date = moment(data?.createdAt).format('DD.MM.YYYY') || ''
		this.hashtag =
			data?.hashtag
				?.split('   ')
				?.map((item) => ({ label: '#' + item, value: item })) || []
		this.content = data?.content || ''
		this.description = data?.description || ''
	}
}
