import React from 'react'
import { TitleLarge } from 'components/Styled'
import styled from 'styled-components'
import { DocumentIcon } from 'components/Icons'
import { colors, device } from 'components/variables'
import document from 'static/docs/document.docx'

const documents = [
	{
		name: 'Lorem ipsum',
		description: 'Lorem ipsum dolor sit amet, consectetu.',
	},
	{
		name: 'Lorem ipsum',
		description: 'Lorem ipsum dolor sit amet, consectetu.',
	},
	{
		name: 'Lorem ipsum',
		description: 'Lorem ipsum dolor sit amet, consectetu.',
	},
	{
		name: 'Lorem ipsum',
		description: 'Lorem ipsum dolor sit amet, consectetu.',
	},
	{
		name: 'Lorem ipsum',
		description: 'Lorem ipsum dolor sit amet, consectetu.',
	},
	{
		name: 'Lorem ipsum',
		description: 'Lorem ipsum dolor sit amet, consectetu.',
	},
	{
		name: 'Lorem ipsum',
		description: 'Lorem ipsum dolor sit amet, consectetu.',
	},
	{
		name: 'Lorem ipsum',
		description: 'Lorem ipsum dolor sit amet, consectetu.',
	},
	{
		name: 'Lorem ipsum',
		description: 'Lorem ipsum dolor sit amet, consectetu.',
	},
	{
		name: 'Lorem ipsum',
		description: 'Lorem ipsum dolor sit amet, consectetu.',
	},
	{
		name: 'Lorem ipsum',
		description: 'Lorem ipsum dolor sit amet, consectetu.',
	},
	{
		name: 'Lorem ipsum',
		description: 'Lorem ipsum dolor sit amet, consectetu.',
	},
	{
		name: 'Lorem ipsum',
		description: 'Lorem ipsum dolor sit amet, consectetu.',
	},
	{
		name: 'Lorem ipsum',
		description: 'Lorem ipsum dolor sit amet, consectetu.',
	},
	{
		name: 'Lorem ipsum',
		description: 'Lorem ipsum dolor sit amet, consectetu.',
	},
	{
		name: 'Lorem ipsum',
		description: 'Lorem ipsum dolor sit amet, consectetu.',
	},
]

const TextBlock = styled.div`
	margin-left: 15px;
`

const Title = styled.h4`
	font-size: 18px;
	line-height: 22px;
	@media ${device.laptopXS} {
		font-size: 21px;
		line-height: 26px;
	}
`

const Description = styled.span`
	font-size: 15px;
	line-height: 130%;
	font-family: 'PT Sans', 'sans-serif';
	@media ${device.laptopXS} {
		font-size: 18px;
		line-height: 23px;
	}
`

const WrapperItem = styled.a`
	display: flex;
	align-items: center;
	cursor: pointer;
	&:hover {
		${TextBlock} {
			text-decoration: underline;
			color: ${colors.blue};
		}
		.icon path {
			fill: ${colors.blue};
		}
	}
	@media ${device.laptopXS} {
		&:nth-child(even) {
			margin-left: 14px;
		}
	}
`

function Documents(props) {
	return (
		<>
			<TitleLarge>Шаблони документів</TitleLarge>
			<div className='row'>
				{documents.map((item, _index) => (
					<WrapperItem
						href={document}
						download
						className='mt-6 px-0'
						key={_index}
					>
						<DocumentIcon className='icon' />
						<TextBlock>
							<Title>{item.name}</Title>
							<Description>{item.description}</Description>
						</TextBlock>
					</WrapperItem>
				))}
			</div>
		</>
	)
}

export default Documents
