import { call, put, takeLatest } from 'redux-saga/effects';
import Api from 'utils/api';
import { actions } from './slices';
import history from 'utils/history';
import { uploadImage } from 'api/api';
import i18n from 'i18n';

function* getNews({ payload }) {
  const body = {
    filter: {
      name: payload?.name || '',
      type: 'news',
      hashtag: payload?.hashtag || '',
      limit: payload.limit || 10,
      skip: payload.skip || 0,
      language: i18n.language,
    },
  };
  try {
    const data = yield call(Api.post, '/api/articles/get', body);
    yield put({
      ...actions.getSuccess({ data: data.data }),
    });
  } catch (error) {
    yield put({ ...actions.getError() });
  }
}

function* createNewsWorker({ payload }) {
  try {
    const image_data = yield uploadImage({ file: payload.mainphoto });
    yield call(Api.post, '/api/articles/create', {
      data: { ...payload, mainphoto: image_data.location },
    });
    yield put({
      ...actions.createNewsEnd(),
    });
  } catch (error) {
    console.error('Failed to create news', error);
    yield put({ ...actions.createNewsEnd() });
  }
}

export function* newsSaga() {
  yield takeLatest(actions.getNews, getNews);
  yield takeLatest(actions.createNews, createNewsWorker);
}
