import React from 'react'
import {
	BigText,
	FillImage,
	ResponseImage,
	ResponsiveVideo,
	ReverseItem,
	ReverseItemImage,
	SubtitleLarge,
	TextSmallPt,
	TitleLarge,
	VideoSource,
} from 'components/Styled'
import { AboutItem, AboutItemText } from 'containers/Home/style'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'
import { BulbIcon, MapMarkIcon, OilIcon, Smile } from 'components/Icons'
import styled from 'styled-components'
import { device } from 'components/variables'
import video from 'static/video/video.mp4'
import branch_2 from 'static/images/branch_2.png'
import { lorem_ipsum, lorem_ipsum_small } from '_config'

const about = [
	{
		icon: <MapMarkIcon width='38' height='57' color='blue' />,
		title: 'about.title_1',
		text: 'about.text_1',
	},
	{
		icon: <BulbIcon />,
		title: 'about.title_2',
		text: 'about.text_1',
	},
	{
		icon: <BulbIcon color='blue' />,
		title: 'about.title_3',
		text: 'about.text_1',
	},
	{
		icon: <OilIcon />,
		title: 'about.title_4',
		text: 'about.text_1',
	},
	{
		icon: <Smile color='blue' />,
		title: 'about.title_5',
		text: 'about.text_1',
	},
	{
		icon: <Smile color='orange' />,
		title: 'about.title_3',
		text: 'about.text_1',
	},
]

export const AboutBlock = styled.div`
	max-width: 1350px;
	margin: 0 auto;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	position: relative;
	flex-direction: column;
	@media ${device.tablet} {
		flex-direction: row;
	}
	@media ${device.laptopS} {
		justify-content: space-between;
	}
`

function Benefits({ t, ...props }) {
	return (
		<>
			<TitleLarge>Переваги території</TitleLarge>
			<AboutBlock>
				{about.map((item, _index) => (
					<AboutItem className='mt-7 small' key={_index}>
						{item.icon}
						<SubtitleLarge className='mt-4' weight='bold'>
							{t(item.title)}
						</SubtitleLarge>
						<AboutItemText className='mt-3'>{t(item.text)}</AboutItemText>
					</AboutItem>
				))}
			</AboutBlock>
			<ResponsiveVideo className='mt-3' controls loop>
				<VideoSource
					src={video}
					type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'
				/>
			</ResponsiveVideo>
			<TextSmallPt className='mt-3'>{lorem_ipsum}</TextSmallPt>
			<ReverseItem className='mt-4'>
				<ReverseItemImage>
					<FillImage src={branch_2} alt='branch image' />
				</ReverseItemImage>
				<TextSmallPt className='mt-3'>{lorem_ipsum_small}</TextSmallPt>
			</ReverseItem>
			<div className='d-block d-lg-flex text-center justify-content-around mt-6 mb-6'>
				<BigText>
					<span>123 Га </span>
					площа
				</BigText>
				<BigText>
					<span>43 тис. </span>
					площа
				</BigText>
			</div>
			<ResponseImage src={branch_2} alt='IMAGE' className='mt-6' />
			<TextSmallPt className='mt-3'>{lorem_ipsum}</TextSmallPt>
		</>
	)
}

export default compose(withTranslation())(Benefits)
