import { createSlice } from '@reduxjs/toolkit'
import { PropositionModel } from 'models/proposition'

export * from './selectors'

const propositionSlice = createSlice({
	name: 'proposition',
	initialState: {
		loading: false,
		proposition: new PropositionModel(),
	},
	reducers: {
		getProposition: (state) => {
			state.loading = true
		},
		getSuccess: (state, { payload }) => {
			state.proposition = new PropositionModel(payload.data)
			state.loading = false
		},
		getError: (state) => {
			state.loading = false
		},
	},
})

export const { actions, reducer } = propositionSlice
