import React, { useState } from 'react'
import styled from 'styled-components'
import AcardionItem from './item'

const Wrapper = styled.div`
	display: flex;
	align-content: center;
	justify-content: center;
	flex-wrap: wrap;
	margin: 0 auto;
	max-width: 1000px;
	min-height: 300px;
`

function AcardionItems({ data,t, ...props }) {
	const [active, setActive] = useState(null)

	const handleSetActive = (item) => () => {
		item === active ? setActive(null) : setActive(item)
	}
	return (
		<Wrapper>
			{data.map((item, _index) => {
				const open = item === active

				return (
					<AcardionItem
						key={_index}
						open={open}
						onClick={handleSetActive(item)}
						item={item}
					/>
				)
			})}
		</Wrapper>
	)
}

export default AcardionItems
