import React, { useEffect } from 'react';
import {
  Content,
  ProjectPageWrapp,
  ProjectWrapper,
  Property,
  PropertyValue,
  SidebarContent,
  SidebarProperty,
  SidebarWrapper,
  HeaderPathWrapper,
} from './style';
import { Line, TitleLarge } from 'components/Styled';
import { useLocation, useParams } from 'react-router';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { actions as projectActions, select as projectSelect } from './slices';
import { compose } from 'redux';
import { memo } from 'react';
import { withTranslation } from 'react-i18next';
import { useTranslation } from 'react-i18next';
import { HashText, DataText } from 'components/Project/style';
import { ProjectsSlider } from 'components/Slider/projects';
import {
  select as projectsSelect,
  actions as actionNewProjectInFooter,
} from 'containers/Projects/slices';
import MarkerMap from 'components/Map/markerMap';
import { Link } from 'react-router-dom';
import Button from 'components/Buttons';
import CTA from 'components/CTA';
import Loader from 'components/Loader';
import HeaderPath from 'components/HeaderPath/headerPath';
import GenerBG from 'static/images/new_projects.png';
import WithImageHead from 'components/HeaderPhoto/with-image';
import moment from 'moment';
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView';
import history from 'utils/history';
import PdfComponent from 'components/ReaderPDF/test';
import { PDFDownloadLink } from 'containers/Community/style';
import i18n from 'i18n';

function ContentBlock({
  content,
  properties,
  date,
  hashtag,
  name,
  property,
  hintText,
  cadastralNumber,
  squares,
  typeOfOwnership,
  purpose,
  category,
  coordinates,
  outlet,
  territoryCreation,
  financeDetail,
  contacts,
  financeType,
  hendleCallHashteg,
  info,
  floor,
  communication,
  infostruct,
  buildingArea,
  ...props
}) {
  const { t } = useTranslation();

  const links = [
    { generalLink: '/create/project' },
    { label: t('headerPath.home'), link: '/home' },
    { label: t('headerPath.projects'), link: '/projects' },
    { label: name, link: '#' },
  ];

  return (
    <div className='container-xl container-fluid mt-5'>
      <HeaderPathWrapper>
        <HeaderPath plusLabel={'проект'} links={links} className='mb-6' />
      </HeaderPathWrapper>
      <ProjectWrapper className='row'>
        <SidebarWrapper className='col-12 col-sm-9 col-md-4'>
          <SidebarContent>
            <div className='d-flex justify-content-between aligin-items-center w-100'>
              <div className='d-flex justify-content-start aligin-items-center flex-wrap'>
                {hashtag.map((item, _index) => (
                  <HashText
                    onClick={() => hendleCallHashteg(item.value)}
                    key={_index}
                  >
                    {item.label}
                  </HashText>
                ))}
              </div>
              <DataText color='grey'>{date}</DataText>
            </div>
            <SidebarProperty>
              {cadastralNumber && (
                <>
                  <Property>{t('projectSidebar.cadastralNumber')}</Property>
                  <PropertyValue>{cadastralNumber}</PropertyValue>
                </>
              )}
              {squares && (
                <>
                  <Property>{t('projectSidebar.square')}</Property>
                  <PropertyValue>{squares}</PropertyValue>
                </>
              )}
              {typeOfOwnership && (
                <>
                  <Property>{t('projectSidebar.ownership')}</Property>
                  <PropertyValue>{typeOfOwnership}</PropertyValue>
                </>
              )}
              {purpose && (
                <>
                  <Property>{t('projectSidebar.goalPurpose')}</Property>
                  <PropertyValue>{purpose}</PropertyValue>
                </>
              )}
              {category && (
                <>
                  <Property>{t('projectSidebar.category')}</Property>
                  <PropertyValue>{category}</PropertyValue>
                </>
              )}
              {outlet && (
                <>
                  <Property>{t('projectSidebar.potentialMarket')}</Property>
                  <PropertyValue>{outlet}</PropertyValue>
                </>
              )}
              {territoryCreation && (
                <>
                  <Property>{t('projectSidebar.implementationArea')}</Property>
                  <PropertyValue>{territoryCreation}</PropertyValue>
                </>
              )}
              {financeDetail && (
                <>
                  <Property>{t('projectSidebar.financialDetails')}</Property>
                  <PropertyValue>{financeDetail}</PropertyValue>
                </>
              )}
              {financeType && (
                <>
                  <Property>{t('projectSidebar.typeFinancing')}</Property>
                  <PropertyValue>{financeType}</PropertyValue>
                </>
              )}
              {contacts && (
                <>
                  <Property>{t('projectSidebar.contacts')}</Property>
                  <PropertyValue>{contacts}</PropertyValue>
                </>
              )}
              {floor && (
                <>
                  <Property>{t('projectSidebar.floor')}</Property>
                  <PropertyValue>{floor}</PropertyValue>
                </>
              )}
              {communication && (
                <>
                  <Property>{t('projectSidebar.communication')}</Property>
                  <PropertyValue>{communication}</PropertyValue>
                </>
              )}
              {infostruct && (
                <>
                  <Property>{t('projectSidebar.infostruct')}</Property>
                  <PropertyValue>{infostruct}</PropertyValue>
                </>
              )}
              {buildingArea && (
                <>
                  <Property>{t('projectSidebar.buildingArea')}</Property>
                  <PropertyValue>{buildingArea}</PropertyValue>
                </>
              )}
            </SidebarProperty>
          </SidebarContent>
        </SidebarWrapper>
        <Line className='col-12 col-sm-9 d-md-none mt-6 mb-0 mx-auto pl-4' />
        <Content className='col-12 col-sm-9 col-md-8'>
          <FroalaEditorView model={content} />
          <div className='mb-3'>
            {!!info &&
              info.map((item, _index) => (
                <div key={_index}>
                  <PdfComponent file={item} className='text-center mt-5' />
                  <PDFDownloadLink href={item} download className='mt-3'>
                    {t('downloadDocument.title')}
                  </PDFDownloadLink>
                </div>
              ))}
          </div>
          <MarkerMap coordinates={coordinates} />
        </Content>
      </ProjectWrapper>
    </div>
  );
}

function Project(props) {
  let { id } = useParams();
  const local = useLocation();
  const prod = local.state;
  const {
    getProject,
    project,
    projects,
    t,
    loading_pr,
    loading_prs,
    footerProjects,
  } = props;

  const {
    name,
    image,
    date,
    hashtag,
    content,
    property,
    location,
    financeType,
    info,
  } = project;

  const {
    projectGoal,
    cadastralNumber,
    square,
    ownership,
    goalPurpose,
    category,
    outlet,
    territoryCreation,
    financeDetail,
    contacts,
    floor,
    communication,
    infostruct,
    buildingArea,
  } = property;
  // const currentLang = i18n.language;

  useEffect(() => {
    getProject({ prod });
    footerProjects();
  }, [id]);

  const hendleCallHashteg = (hashtag) => {
    history.push('/projects', { detail: hashtag });
  };

  const dat = moment(date).format('DD.MM.YYYY');

  return (
    <>
      {loading_pr || loading_prs ? (
        <Loader />
      ) : (
        <ProjectPageWrapp>
          <WithImageHead label={name} text='' image={image} bg={GenerBG} />
          <ContentBlock
            hendleCallHashteg={hendleCallHashteg}
            info={info}
            name={name}
            date={dat}
            hashtag={hashtag}
            properties={property}
            content={content}
            property={property}
            hintText={projectGoal}
            cadastralNumber={cadastralNumber}
            squares={square}
            typeOfOwnership={ownership}
            purpose={goalPurpose}
            category={category}
            coordinates={location.coordinates}
            outlet={outlet}
            territoryCreation={territoryCreation}
            financeDetail={financeDetail}
            contacts={contacts}
            financeType={financeType}
            floor={floor}
            communication={communication}
            infostruct={infostruct}
            buildingArea={buildingArea}
          />
          <TitleLarge className='mt-12 mb-8'>{t('project.title')}</TitleLarge>
          <ProjectsSlider
            projects={projects}
            slidesToShow={projects.length < 3 ? 1 : 3}
          />
          <div className='w-100 text-center mt-9'>
            <Link to='/projects'>
              <Button theme='blue'>{t('button.allProjects')}</Button>
            </Link>
          </div>
          <CTA
            text={t('projectInfo.InterestedProjects')}
            buttonText={t('projectInfo.Invest')}
            link='contacts_form'
            className='mt-12'
          />
        </ProjectPageWrapp>
      )}
    </>
  );
}

const mapStateToProps = createStructuredSelector({
  projects: projectsSelect.projects,
  project: projectSelect.project,
  loading_pr: projectSelect.loading,
  loading_prs: projectsSelect.loading,
});

const withConnect = connect(mapStateToProps, {
  getProject: projectActions.getProject,
  footerProjects: actionNewProjectInFooter.getProjectsStart,
});

export default compose(withConnect, memo, withTranslation())(Project);
