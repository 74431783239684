import styled from 'styled-components'
import bgN from 'static/images/newsBG.png'

export const NewsBg = styled.div`
	background-image: url(${bgN});
	background-repeat: no-repeat;
	background-size: cover;
	position: relative;
	padding: 169px 0;
	text-align: center;
`

export const NewsWrapper = styled.div`
	width: 100%;
`

export const NewsContentWrapper = styled.div`
	margin: 0 auto;
	padding: 61px 15px 121px 15px;
	max-width: 1170px;
`

export const NewsPaginationContent = styled.div`
	margin-top: 60px;
`

export const NewItemContainer = styled.div`
	width: 100%;
`
