import moment from 'moment'

export class HistoryItem {
	constructor(data = {}) {
		this._id = data.id
		this.date = moment(data?.createdAt).format('DD.MM.YYYY') || ''
		this.name = data.name
		this.image = data.mainphoto
		this.content = data.content
		this.hashtag = data.hashtag
	}
}
