import { useField } from 'formik'
import { withTranslation } from 'react-i18next'
import { ErrorText, Label } from '../input/style'
import { Textarea, TextareaWrapper, Wrapper } from './style'

const BasicInput = (props) => {
	const [field, meta] = useField({ ...props })

	const { className, id, t, type, placeholder, label, view } = props

	const { value } = field

	const errors = meta.touched && meta.error

	return (
		<Wrapper className={className}>
			{label && <Label className='mb-3 pl-0'>{label}</Label>}
			<TextareaWrapper error={errors ? 1 : 0} view={view}>
				{errors && <ErrorText>{t(`error.${meta.error}`)}</ErrorText>}
				<Textarea
					error={errors ? 1 : 0}
					placeholder={placeholder}
					type={type}
					id={id}
					{...field}
					{...meta}
					value={value}
					view={view}
				/>
			</TextareaWrapper>
		</Wrapper>
	)
}

export default withTranslation()(BasicInput)
