import React, { memo } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { createStructuredSelector } from 'reselect'
import { select } from './slices'
import PaternBuild from 'components/ProjectPatern/patern'
import ProjectItem from 'components/Project'
import Loader from 'components/Loader'

function PageProjects(props) {
	const {
		loading,
		projects,
		selected,
		setSelected,
		count,
		hendleCallBack,
		hashtagCallBack,
		def_count,
		t,
	} = props
	return loading ? (
		<Loader />
	) : (
		<PaternBuild
			plusLabel={'проект'}
			paternItem={projects}
			hendleCallBack={hendleCallBack}
			hashtagCallBack={hashtagCallBack}
			ViewItem={ProjectItem}
			t={t}
			sel={selected}
			setSel={setSelected}
			def_count={def_count}
			countItems={count}
		/>
	)
}

const mapStateToProps = createStructuredSelector({
	loading: select.loading,
})

const withConnect = connect(mapStateToProps, {})

export default compose(withConnect, memo, withTranslation())(PageProjects)
