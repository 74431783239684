import { PieChart, Pie, Cell, Tooltip, Legend, Label, LabelList } from 'recharts'

const wrapperStyle = {
	ul: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'baseline',
	},
}

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

export const PieGraph = ({ pause = false, data, colors, ...props }) => {
	const COLORS = colors
	return (
		
		<PieChart width={400} height={500}>
			
			<Pie
				data={data}
				cx="50%"
				cy="50%"
				innerRadius={90}
				outerRadius={120}
				fill='#8884d8'
				paddingAngle={3}
				dataKey='value'
				isAnimationActive={!pause}
				label={props.datakey.indexOf('percent') !== -1 ? ({percent}) => ((percent * 100).toFixed(2) + '%') : {}}
			>
				
				
				{data.map((entry, index) => (
					<>
					<Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
					</>
				))}
			</Pie>
			<Legend
				verticalAlign='bottom'
				align='center'
				wrapperStyle={wrapperStyle}
			/>
			<Tooltip />
		</PieChart>
	)
}
