import {
	Container,
	FillImage,
	ResponsiveVideo,
	ReverseItem,
	ReverseItemImage,
	TitleLarge,
	VideoSource,
} from 'components/Styled'
import video from 'static/video/video.mp4'
import React from 'react'
import styled from 'styled-components'
import { TextSmallPt } from 'components/Styled'
import { useParams } from 'react-router'
import { useEffect } from 'react'
import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { memo } from 'react'
import { ProjectsSlider } from 'components/Slider/projects'
import {
	actions as projectsActions,
	select as projectsSelect,
} from 'containers/Projects/slices'
import Loader from 'components/Loader'
import { withTranslation } from 'react-i18next'
import { actions as branchActions, select as branchSelect } from './slices'
import { select } from 'containers/Branches/slices'
import { Link } from 'react-router-dom'
import Button from 'components/Buttons'
import { ProjectsDesktop, ProjectsMobile } from 'containers/Home/style'
import ProjectItem from 'components/Project'
import branch_1 from 'static/images/branch_1.png'
import branch_2 from 'static/images/branch_2.png'
import BranchesSlider from 'components/Slider/branches'
import CTA from 'components/CTA'
import bg from 'static/images/new_industries.png'
import WithImageHead from 'components/HeaderPhoto/with-image'
import { lorem_ipsum, lorem_ipsum_small } from '_config'
import HeeaderPath from 'components/HeaderPath/headerPath'

const Wrapper = styled.div`
	width: 100%;
	position: relative;
`

function Branch(props) {
	const {
		branch,
		branches,
		projects,
		getBranch,
		getProjects,
		loading_bch,
		loading_bchs,
		loading_pr,
		t,
	} = props
	const { name, image } = branch

	const id = useParams()
	const links = [
		{ generalLink: '#' },
		{ label: t('headerPath.home'), link: '/home' },
		{ label: t('headerPath.branches'), link: '/branches' },
		{ label: name, link: '#' },
	]
	useEffect(() => {
		getBranch(id)
		getProjects(id)
	}, [id])
	return (
		<>
			{loading_bch || loading_pr || loading_bchs ? (
				<Loader />
			) : (
				<Wrapper>
					<WithImageHead
						label={name}
						text='Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
						image={image}
						bg={bg}
					/>
					<Container className='container mt-6'>
						<HeeaderPath className='mb-6' links={links} />
						<ResponsiveVideo controls loop>
							<VideoSource
								src={video}
								type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'
							/>
						</ResponsiveVideo>
						<TextSmallPt className='mt-3' lh={true}>
							{lorem_ipsum}
						</TextSmallPt>
						<TitleLarge className='mt-8'>
							Інвестиційні проекти в {name}
						</TitleLarge>
					</Container>
					<ProjectsDesktop className='mt-8'>
						<ProjectsSlider projects={projects} slidesToShow={3} />
					</ProjectsDesktop>
					<ProjectsMobile>
						{projects.slice(0, 3).map((item, _index) => (
							<ProjectItem key={_index} className='mt-7' item={item} />
						))}
					</ProjectsMobile>
					<div className='w-100 text-center mt-9'>
						<Link to='/projects'>
							<Button theme='blue'>{t('button.allProjects')}</Button>
						</Link>
					</div>
					<Container className='container mt-9'>
						<ResponsiveVideo controls loop>
							<VideoSource
								src={video}
								type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'
							/>
						</ResponsiveVideo>
						<TextSmallPt className='mt-1'>{lorem_ipsum}</TextSmallPt>
						<ReverseItem className='mt-8'>
							<ReverseItemImage>
								<FillImage src={branch_1} alt='branch image' />
							</ReverseItemImage>
							<TextSmallPt className='mt-3'>{lorem_ipsum_small}</TextSmallPt>
						</ReverseItem>
						<ReverseItem className='mt-8' reverse>
							<ReverseItemImage reverse>
								<FillImage src={branch_2} alt='branch image' />
							</ReverseItemImage>
							<TextSmallPt className='mt-3'>{lorem_ipsum_small}</TextSmallPt>
						</ReverseItem>
						<TitleLarge className='mt-12'>Інші галузі</TitleLarge>
					</Container>
					<BranchesSlider branches={branches} className='mt-8' />
					<CTA
						text='Зацікавили проекти?'
						buttonText='Хочу інвестувати'
						link='contacts_form'
						className='mt-15'
					/>
				</Wrapper>
			)}
		</>
	)
}

const mapStateToProps = createStructuredSelector({
	branch: branchSelect.branch,
	branches: select.branches,
	projects: projectsSelect.projects,
	loading_bch: branchSelect.loading,
	loading_bchs: select.loading,
	loading_pr: projectsSelect.loading,
})

const withConnect = connect(mapStateToProps, {
	getBranch: branchActions.getSingleStart,
	getProjects: projectsActions.getProjectsStart,
})

export default compose(withConnect, memo, withTranslation())(Branch)
