import {
	ArrowDown,
	EmailIconLink,
	FacebookIcon,
	LogoIcon,
} from 'components/Icons'
import { select as branchesSelect } from 'containers/Branches/slices'
import { select as communitySelect } from 'containers/Communities/slices'
import React, { useState } from 'react'
import { memo } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { compose } from 'redux'
import { createStructuredSelector } from 'reselect'
import {
	BlockItem,
	FooterBlock,
	FooterWrapper,
	FootText,
	ItemWrapp,
	MobileText,
	SubItem,
	SubItems,
} from './styled'
import { FooterCompany } from 'containers/Footer/styled'
import ScrollToTop from 'components/ScrollTop'
import ReactGA from 'react-ga';

function Footer({ communities, branches, t, loading, ...props }) {
	const [openSub, setOpenSub] = useState(null)

	const handleOpenSab = (item) => () => {
		item.label === openSub ? setOpenSub(null) : setOpenSub(item.label)
	}

	const branchesFormatMenu = branches.map(({ name, _id }) => {
		return { label: name, value: _id, _id: _id }
	})
	const communitiesFormatMenu = communities.map(({ name, _id }) => {
		return { label: name, value: _id, _id: _id }
	})

	const footer_menu = [
		{
			label: 'communities',
			link: '/communities',
			subLink: '/community',
			subMenu: communitiesFormatMenu,
		},
		{
			label: 'subInvest3',
			link: '/histories',
		},
		{
			label: 'investors',
			link: '/invest',
			mode: 'large',
			subMenu: [
				{ label:  'header.subInvest1', link: '/projects' },
				{ label:  'header.programs', link: '/programs' },
			],
		},
		{
			label: 'contacts',
			link: '/contacts',
			subLink: '',
			subMenu: [
				{
					label: 'investcoalregions@gmail.com',
					link: 'mailto:investcoalregions@gmail.com',
					icon: EmailIconLink,
				},
				// {
				// 	label: 'www.facebook/loremipsum.com',
				// 	link: 'https://www.facebook.com/',
				// 	icon: FacebookIcon,
				// },
			],
		},
	]
	return (
		<>
			<FooterWrapper className='mt-12'>
				<Link to='/home'>
					<LogoIcon className='mr-lg-10 mr-5 d-none d-md-block ' />
				</Link>
				<FooterBlock>
					{footer_menu.map((item, _index) => {
						const isOpen = item.label === openSub
						return (
							<ItemWrapp key={_index}>
								<BlockItem open={isOpen} onClick={handleOpenSab(item)}>
									<Link to={item.link}>{t(`header.${item.label}`)}</Link>
									{item.subMenu && (
										<ArrowDown
											className='d-block d-md-none arrow'
											width='14px'
											height='14px'
											color='white'
										/>
									)}
								</BlockItem>
								<SubItems className='subMenu' open={isOpen}>
									{item.subMenu?.map((element, _index) =>
										item.label === 'contacts' ? (
											<a href={element.link} key={_index}>
												<SubItem 		onClick={()=>ReactGA.ga('send', 'event', 'Email', 'Click')}>
													{element.icon && <element.icon />} {t(element.label)}
												</SubItem>
											</a>
										) : (
											<Link
									
												to={
													item.subLink
														? `${item.subLink}/${element._id || element.link}`
														: element.link
												}
												key={_index}
											>
												<SubItem>
													{element.icon && <element.icon />} {t(element.label)}
												</SubItem>
											</Link>
										)
									)}
								</SubItems>
							</ItemWrapp>
						)
					})}
				</FooterBlock>
			</FooterWrapper>
			<FooterCompany color='black' className='text-center mt-7 pb-4'>
				{/* <span>{t('footer.span')}</span>
				<FootText>{t('footer.text')}</FootText>
				<MobileText>{t('footer.smallText')}</MobileText> */}
			</FooterCompany>
			<ScrollToTop />
		</>
	)
}

const mapStateToProps = createStructuredSelector({
	communities: communitySelect.communities,
	branches: branchesSelect.branches,
})

const withConnect = connect(mapStateToProps, {})

export default compose(withConnect, memo, withTranslation())(Footer)
