import React from 'react'
import AuthLoyout from 'components/Loyout/auth'
import { Link } from 'react-router-dom'
import { Message, ToHomeBtn } from './style'
import { withTranslation } from 'react-i18next'

function MailSuccess({ t, ...props }) {
	return (
		<AuthLoyout>
			<Message>{t('mailSuccess.title')}</Message>
			<Link to='/home'>
				<ToHomeBtn>{t('mailSuccess.btnLabel')}</ToHomeBtn>
			</Link>
		</AuthLoyout>
	)
}

export default withTranslation()(MailSuccess)
