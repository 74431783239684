import Error from 'components/Error';
import Auth from 'containers/Auth';
import Home from 'containers/Home';
import Project from 'containers/Project';
import Projects from 'containers/Projects';
import HistoriesSec from 'containers/HistoriesSuccess';
import HistoryItem from 'containers/History';
// import News from 'containers/News'
import React, { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { AppWrapper } from './style';
import GlobalLoadData from 'components/Loyout/loadLoyout';
import Invest from 'containers/Investors';
import Branches from 'containers/Branches';
import Branch from 'containers/Branch';
import New from 'containers/New';
// import Propisitions from 'containers/Propisitions' Release v.0.0.1
// import Proposition from 'containers/Proposition' Release v.0.0.1
import Communities from 'containers/Communities';
import Community from 'containers/Community';
import Contacts from 'containers/Contacts';
import Contact from 'containers/Contact';
import NotFoundPage from 'containers/404';
import Create from 'containers/Create';
import { connect } from 'react-redux';
import { actions } from 'containers/slices/user';
import { createStructuredSelector } from 'reselect';
import { select } from 'containers/Auth/slices';
import { NewsPage } from 'lazy-pages';
import i18n from 'i18n';
import { BrowserRouter } from 'react-router-dom';
import Programs from 'containers/Programs';
import DocScreen from 'containers/DocumentScreen';

function App({ isAuth, getUser, ...props }) {
  useEffect(() => {
    if (isAuth) {
      getUser();
    }
  }, [isAuth, getUser]);
  const lang = i18n.language;
  return (
    <AppWrapper>
      <Switch>
        <Route exact path='/'>
          <Redirect to='/home' />
        </Route>
        <Route path='/auth'>
          <GlobalLoadData isFooter={false}>
            <Auth />
          </GlobalLoadData>
        </Route>
        <Route path={'/home'}>
          <GlobalLoadData>
            <Home />
          </GlobalLoadData>
        </Route>
        <Route path='/invest'>
          <GlobalLoadData>
            <Invest />
          </GlobalLoadData>
        </Route>
        <Route path='/news/:id'>
          <GlobalLoadData>
            <New />
          </GlobalLoadData>
        </Route>
        <Route path={`/news`}>
          <GlobalLoadData>
            <NewsPage />
          </GlobalLoadData>
        </Route>
        {/* Release v.0.0.1 */}
        {/* <Route path='/propositions'>
					<GlobalLoadData>
						<Propisitions />
					</GlobalLoadData>
				</Route>
				<Route path='/proposition/:id'>
					<GlobalLoadData>
						<Proposition />
					</GlobalLoadData>
				</Route> */}
        {/* Release v.0.0.1 */}
        <Route path='/histories'>
          <GlobalLoadData>
            <HistoriesSec />
          </GlobalLoadData>
        </Route>
        <Route path='/history/:id'>
          <GlobalLoadData>
            <HistoryItem />
          </GlobalLoadData>
        </Route>
        <Route path='/project/:id'>
          <GlobalLoadData>
            <Project />
          </GlobalLoadData>
        </Route>
        <Route path='/projects'>
          <GlobalLoadData>
            <Projects />
          </GlobalLoadData>
        </Route>
				<Route path='/programs'>
          <GlobalLoadData>
            <Programs />
          </GlobalLoadData>
        </Route>
				<Route path='/program/:id'>
          <GlobalLoadData>
            <DocScreen />
          </GlobalLoadData>
        </Route>
        <Route path='/branches'>
          <GlobalLoadData>
            <Branches />
          </GlobalLoadData>
        </Route>
        <Route path='/branch/:id'>
          <GlobalLoadData>
            <Branch />
          </GlobalLoadData>
        </Route>
        <Route path='/communities'>
          <GlobalLoadData>
            <Communities />
          </GlobalLoadData>
        </Route>
        <Route path='/community/:id'>
          <GlobalLoadData>
            <Community />
          </GlobalLoadData>
        </Route>
        <Route path='/contacts/:id'>
          <GlobalLoadData>
            <Contact />
          </GlobalLoadData>
        </Route>
        <Route path='/contacts'>
          <GlobalLoadData>
            <Contacts />
          </GlobalLoadData>
        </Route>
        <Route path='/create'>
          <Create />
        </Route>
        <Route path='/error'>
          <Error />
        </Route>
        <Route>
          <NotFoundPage />
        </Route>
      </Switch>
    </AppWrapper>
  );
}

const mapStateToProps = createStructuredSelector({
  isAuth: select.isAuth,
});

export default connect(mapStateToProps, {
  getUser: actions.getUserStart,
})(App);
