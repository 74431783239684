import styled from 'styled-components'
import { colors, device } from 'components/variables'

export const ProjectsFiltersWrapper = styled.div`
	padding: 60px 0px;
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	@media ${device.laptopXS} {
		justify-content: space-between;
	}
`

export const ProjectsFiltersDrop = styled.div`
	width: 100%;
	padding-left: 15px;
`

export const FilterContainer = styled.div`
	position: relative;
	transition: all 0.3s;
	width: 200px;
	min-height: 40px;
	display: none;
	@media ${device.laptopXS} {
		display: block;
		margin: 0 2px;
	}
	@media ${device.mobileXL} {
		display: block;
		margin: 0px 2px 10px 2px;
	}
	&.smolOpen {
		display: block;
		margin-bottom: 20px;
	}
	&.smolOpen.hei {
		height: 160px;
		transition: all 0.3s;
	}
`

export const FiltersAll = styled.div`
	cursor: pointer;
	width: 165px;
	padding: 10px 0;
	margin-right: 5px;
	font-weight: 600;
	font-size: 16px;
	line-height: 20px;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	color: ${colors.white};
	background-color: ${colors.blue};
	@media ${device.mobileM} {
		margin-right: 14px;
		width: 200px;
	}
	@media ${device.mobileXL} {
		display: none;
	}
	.filterDrop {
		margin-left: 7px;
	}
`

export const FiltersOpened = styled.div`
	position: fixed;
	width: 232px;
	height: 100%;
	background-color: ${colors.white};
	top: 0;
	left: -233px;
	transition: all 0.5s;
	z-index: 100;
	&.FiltersOpen {
		left: 0;
		transition: all 0.5s;
	}
`

export const FiltersOpenedClose = styled.div`
	padding: 25px 44px 25px 15px;
	width: 100%;
	font-weight: 600;
	font-size: 16px;
	line-height: 20px;
	cursor: pointer;
	text-align: center;
	display: flex;
	justify-content: space-between;
	align-items: center;
	&:hover {
		.filterDropArrow {
			path {
				fill: ${colors.orange};
			}
		}
	}
`

export const FilterSmolContainer = styled.div`
	${FilterContainer}
	width: 70px;
	margin: 0px 2px;
	@media ${device.laptopXS} {
		margin: 0 2px;
	}
	@media ${device.mobileXL} {
		margin: 0px 2px 10px 2px;
	}
`

export const customFilterStyle = {
	placeholder: (provided, state) => {
		let color = state.isFocused ? colors.blue : colors.white
		color = state.hasValue ? colors.white : color
		return {
			...provided,
			fontSize: '16px',
			color: `${color}`,
			fontWeight: '600',
		}
	},
	container: (provided, state) => {
		let color = state.isFocused ? colors.white : colors.blue
		color = state.hasValue ? colors.blue : color
		return {
			...provided,
			width: '200px',
			height: `40px`,
			background: `${color}`,
			border: `1px solid ${colors.blue}`,
			position: 'relative',
		}
	},
	control: (provided, state) => {
		return {
			...provided,
			border: 0,
			boxShadow: 'none',
			padding: '0px',
			background: 'transparent',
		}
	},
	option: (provided, state) => {
		return {
			...provided,
			background: 'transparent',
			color: colors.black,
			fontSize: '16px',
			margin: 0,
			padding: '6px 0px 6px 7px',
			'&:hover': {
				color: colors.blue,
				cursor: 'pointer',
			},
		}
	},
	singleValue: (provided, state) => {
		return {
			...provided,
			fontSize: '16px',
			color: `${colors.white}`,
			fontWeight: '600',
		}
	},
	menu: (provided, state) => {
		return {
			...provided,
			width: '200px',
			boxShadow: 'none',
			border: `1px solid ${colors.blue}`,
			borderTop: 'none',
			borderRadius: '0px',
			padding: '0px 0px 6px 0px',
			margin: 0,
			top: '30px',
			left: '-1px',
		}
	},
	menuList: (provided, state) => {
		return {
			...provided,
			margin: 0,
			maxHeight: '125px',
			'&::-webkit-scrollbar': {
				width: '20px',
				height: '90%',
			},
			'&::-webkit-scrollbar-track': {
				background: `${colors.darkGrey}`,
				borderRight: `13.7px solid ${colors.white}`,
				borderLeft: `6.3px solid ${colors.white}`,
			},
			'&::-webkit-scrollbar-thumb': {
				background: `${colors.orange}`,
				borderRight: `11px solid ${colors.white}`,
				borderLeft: `3px solid ${colors.white}`,
			},
		}
	},
	input: (provided, state) => {
		const color = !state.isFocused ? colors.blue : colors.white
		return {
			...provided,
			fontSize: '16px',
			color: `${color}`,
			fontWeight: '600',
		}
	},
	valueContainer: (provided, state) => {
		return {
			...provided,
			margin: 0,
			paddingLeft: '5px',
		}
	},
	dropdownIndicator: (provided, state) => {
		let focus = state.isFocused ? 'rotate(180deg)' : 'rotate(0deg)'
		let color = state.isFocused ? colors.blue : colors.white

		focus = state.hasValue ? 'rotate(0deg)' : focus
		color = state.hasValue ? colors.blue : color
		return {
			...provided,
			margin: 0,
			'.filterArrow': {
				transform: focus,
				path: {
					fill: color,
				},
			},
		}
	},
}

export const customFilterOpenStyle = {
	placeholder: (provided, state) => {
		let color = state.isFocused ? colors.blue : colors.white
		color = state.hasValue ? colors.white : color
		return {
			...provided,
			fontSize: '16px',
			color: `${color}`,
			fontWeight: '600',
		}
	},
	container: (provided, state) => {
		let color = state.isFocused ? colors.white : colors.blue
		color = state.hasValue ? colors.blue : color
		return {
			...provided,
			position: 'relative',
			width: '200px',
			height: '40px',
			background: `${color}`,
			border: `1px solid ${colors.blue}`,
		}
	},
	control: (provided, state) => {
		return {
			...provided,
			border: 0,
			boxShadow: 'none',
			padding: '0px',
			background: 'transparent',
		}
	},
	option: (provided, state) => {
		return {
			...provided,
			background: 'transparent',
			color: colors.black,
			fontSize: '16px',
			margin: 0,
			padding: '6px 0px 6px 7px',
			'&:hover': {
				color: colors.blue,
				cursor: 'pointer',
			},
		}
	},
	singleValue: (provided, state) => {
		return {
			...provided,
			fontSize: '16px',
			color: `${colors.white}`,
			fontWeight: '600',
		}
	},
	menu: (provided, state) => {
		return {
			...provided,
			position: 'relative',
			width: '200px',
			boxShadow: 'none',
			borderTop: `1px solid ${colors.blue}`,
			borderRadius: '0px',
			padding: 0,
			margin: 0,
			top: '0',
			left: '-1px',
		}
	},
	menuList: (provided, state) => {
		return {
			...provided,
			margin: 0,
			maxHeight: '125px',
			'&::-webkit-scrollbar': {
				width: '20px',
				height: '90%',
			},
			'&::-webkit-scrollbar-track': {
				background: `${colors.darkGrey}`,
				borderRight: `13.7px solid ${colors.white}`,
				borderLeft: `6.3px solid ${colors.white}`,
			},
			'&::-webkit-scrollbar-thumb': {
				background: `${colors.orange}`,
				borderRight: `11px solid ${colors.white}`,
				borderLeft: `3px solid ${colors.white}`,
			},
		}
	},
	input: (provided, state) => {
		const color = !state.isFocused ? colors.blue : colors.white
		return {
			...provided,
			fontSize: '16px',
			color: `${color}`,
			fontWeight: '600',
		}
	},
	valueContainer: (provided, state) => {
		return {
			...provided,
			margin: 0,
			paddingLeft: '5px',
		}
	},
	dropdownIndicator: (provided, state) => {
		let focus = state.isFocused ? 'rotate(180deg)' : 'rotate(0deg)'
		let color = state.isFocused ? colors.blue : colors.white

		focus = state.hasValue ? 'rotate(0deg)' : focus
		color = state.hasValue ? colors.blue : color
		return {
			...provided,
			margin: 0,
			'.filterArrow': {
				transform: focus,
				path: {
					fill: color,
				},
			},
		}
	},
}

export const ProjectsFiltersClose = styled.div`
	z-index: 10;
	position: absolute;
	padding: 13px 9px 11px 178px;
`

export const MenuScrollCastom = styled.div`
	float: right;
`

export const ProjectsFiltersSmolClose = styled.div`
	z-index: 10;
	position: absolute;
	padding: 13px 9px 11px 48px;
`

export const customFilterSmolStyle = {
	placeholder: (provided, state) => {
		let color = state.isFocused ? colors.blue : colors.white
		color = state.hasValue ? colors.white : color
		return {
			...provided,
			margin: 0,
			fontSize: '16px',
			color: `${color}`,
			fontWeight: '600',
		}
	},
	container: (provided, state) => {
		let color = state.isFocused ? colors.white : colors.blue
		color = state.hasValue ? colors.blue : color
		let visColor = state.isFocused ? colors.blue : color.white
		return {
			...provided,
			width: '70px',
			height: `40px`,
			background: `${color}`,
			border: `1px solid ${colors.blue}`,
			position: 'relative',
			'.sortArrowSmol': {
				path: {
					fill: visColor,
				},
			},
		}
	},
	control: (provided, state) => {
		return {
			...provided,
			border: 0,
			boxShadow: 'none',
			padding: '0px',
			background: 'transparent',
		}
	},
	option: (provided, state) => {
		return {
			...provided,
			background: 'transparent',
			color: colors.black,
			fontSize: '16px',
			margin: 0,
			padding: '6px 0px 6px 15px',
			'&:hover': {
				color: colors.blue,
				cursor: 'pointer',
			},
		}
	},
	singleValue: (provided, state) => {
		return {
			...provided,
			fontSize: '16px',
			color: `${colors.white}`,
			fontWeight: '600',
		}
	},
	menu: (provided, state) => {
		return {
			...provided,
			width: '70px',
			boxShadow: 'none',
			border: `1px solid ${colors.blue}`,
			borderTop: 'none',
			borderRadius: '0px',
			padding: '0',
			margin: 0,
			top: '30px',
			left: '-1px',
		}
	},
	menuList: (provided, state) => {
		return {
			...provided,
			margin: 0,
			maxHeight: '125px',
			padding: '0px 0px 6px 0px',
		}
	},
	input: (provided, state) => {
		const color = !state.isFocused ? colors.blue : colors.white
		return {
			...provided,
			fontSize: '16px',
			color: `${color}`,
			fontWeight: '600',
		}
	},
	valueContainer: (provided, state) => {
		let vis = state.hasValue ? '5px' : '15px'
		return {
			...provided,
			margin: 0,
			paddingLeft: vis,
		}
	},
	dropdownIndicator: (provided, state) => {
		let focus = state.isFocused ? 'rotate(180deg)' : 'rotate(0deg)'
		let color = state.isFocused ? colors.blue : colors.white

		focus = state.hasValue ? 'rotate(0deg)' : focus
		color = state.hasValue ? colors.blue : color
		let vis = state.hasValue ? '0' : '0px 7px 0px 0px'
		return {
			...provided,
			margin: vis,
			paddingLeft: 0,
			'.filterArrow': {
				transform: focus,
				path: {
					fill: color,
				},
			},
		}
	},
}

export const SpaN = styled.span`
	.arrows {
		path {
			fill: ${colors.black};
		}
	}
	.rotate {
		transform: rotate(-180deg);
	}
`
