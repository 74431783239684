import moment from 'moment'
import environment from 'environment'

export class BranchModel {
	constructor(data = {}) {
		const { industry } = data
		this._id = industry?._id
		this.name = industry?.name
		this.image = `${environment.staticUrl}images/${industry?.mainphoto}`
		this.date = moment(industry?.createdAt).format('DD.MM.YYYY')
		this.content = industry?.content
	}
}
