import styled from 'styled-components'
import React from 'react'
import { TitleLarge } from 'components/Styled'
import { Link } from 'react-router-dom'
import Button from 'components/Buttons'
import { withTranslation } from 'react-i18next'
import { FooterCompany, FootText, MobileText } from 'containers/Footer/styled'
import { AuthFooter } from 'components/Loyout/auth/style'

const ContentError = styled.div`
	flex: 1 0 auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`

function Error({ t, ...props }) {
	return (
		<>
			<ContentError>
				<TitleLarge className='mb-5'>{t('check.error')}</TitleLarge>
				<Link to='/home'>
					<Button className='mt-3' theme='blue'>
						{t('check.button')}
					</Button>
				</Link>
			</ContentError>
			<AuthFooter>
				<FooterCompany color='black'>
					<span>{t('footer.span')}</span>
					<FootText>{t('footer.text')}</FootText>
					<MobileText>{t('footer.smallText')}</MobileText>
				</FooterCompany>
			</AuthFooter>
		</>
	)
}

export default withTranslation()(Error)
