import {
	Col,
	Container,
	FillImage,
	Row,
	SubtitleLarge,
	SupperImg,
	NoImage,
	NoImg,
} from 'components/Styled'
import React from 'react'
import { memo } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { compose } from 'redux'
import { createStructuredSelector } from 'reselect'
import { select } from './slices'
import { BranchImage, BranchWrapp } from './style'
import bg from 'static/images/new_industries.png'
import HeaderPhoto from 'components/HeaderPhoto'
import HeeaderPath from 'components/HeaderPath/headerPath'

import { NoData } from 'components/Styled'
import img1 from 'static/images/NonImgColor.png'
import enviroment from 'environment'

function Branches({ branches, t, ...props }) {
	const links = [
		{ generalLink: '#' },
		{ label: t('headerPath.home'), link: '/home' },
		{ label: t('headerPath.branches'), link: '#' },
	]
	const dataDone = branches.length ? false : true
	return (
		<>
			<HeaderPhoto
				text='Lorem ipsum 
dolor sit amet, consec adipiscing.'
				label='Пріоритетні галузі'
				bg={bg}
			/>
			{dataDone ? (
				<NoData>галузі не найдено</NoData>
			) : (
				<Container className='container'>
					<HeeaderPath className='mt-6' searchBox links={links} />
					<Row className='row'>
						{branches.map((item, _index) => {
							const regularExp = new RegExp('img_')
							const imge = regularExp.exec(item.mainphoto)
							return (
								<Col key={_index} className='col-12 col-sm-6 col-lg-4 mt-6'>
									<Link to={`/branch/${item._id}`}>
										<BranchWrapp>
											<BranchImage>
												<SupperImg>
													{imge ? (
														<FillImage
															className='img'
															src={`${enviroment.apiUrl}/images/${item.mainphoto}`}
															alt='Project Image'
														/>
													) : (
														<NoImage>
															<NoImg src={img1} alt='Project Image' />
														</NoImage>
													)}
												</SupperImg>
											</BranchImage>
											<SubtitleLarge
												weight='bold'
												className='text text-center mt-2'
											>
												{item.name}
											</SubtitleLarge>
										</BranchWrapp>
									</Link>
								</Col>
							)
						})}
					</Row>
				</Container>
			)}
		</>
	)
}

const mapStateToProps = createStructuredSelector({
	branches: select.branches,
})

const withConnect = connect(mapStateToProps, {})

export default compose(withConnect, memo, withTranslation())(Branches)
