import { FooterCompany, FootText, MobileText } from 'containers/Footer/styled'
import React from 'react'
import { withTranslation } from 'react-i18next'
import { AuthContent, AuthFooter } from './style'

function AuthLoyout({ children, t, ...props }) {
	return (
		<>
			<AuthContent>{children}</AuthContent>
			<AuthFooter className='mt-5'>
				<FooterCompany color='black'>
					<span>{t('footer.span')}</span>
					<FootText>{t('footer.text')}</FootText>
					<MobileText>{t('footer.smallText')}</MobileText>
				</FooterCompany>
			</AuthFooter>
		</>
	)
}

AuthLoyout.defaultProps = {
	children: <></>,
}

export default withTranslation()(AuthLoyout)
