import { colors } from 'components/variables'
import { useField } from 'formik'
import { useRef } from 'react'
import { withTranslation } from 'react-i18next'
import styled from 'styled-components'
import {
	ErrorText,
	IconWrapp,
	Input,
	InputWrapper,
	Label,
	Wrapper,
} from './style'

const Placeholder = styled.span`
	font-family: 'PT Sans', 'sans-sefif';
	font-size: 15px;
	color: ${(props) => (props.value ? colors.black : colors.grey)};
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: pre-wrap;
	overflow-wrap: break-word;
	line-height: 130%;
`

const FileInput = (props) => {
	const [field, meta] = useField({ ...props })

	const inputRef = useRef()

	const {
		label,
		className,
		id,
		t,
		icon,
		type,
		file,
		onChange,
		noBold,
		largeLabel,
		accept,
	} = props

	const Icon = icon

	const { value } = field

	const errors = meta.touched && meta.error && !file

	const handleFocus = () => {
		inputRef.current.click()
	}

	return (
		<Wrapper className={className}>
			{label && (
				<Label noBold={noBold} largeLabel={largeLabel} htmlFor={field.name}>
					{label}
				</Label>
			)}
			<InputWrapper error={errors ? 1 : 0} onClick={handleFocus}>
				{errors && <ErrorText>{t(`error.${meta.error}`)}</ErrorText>}
				{icon && (
					<IconWrapp>
						<Icon active={value} error={errors ? 1 : 0} />
					</IconWrapp>
				)}
				<Placeholder>{file?.name || '1234567890.jpg'}</Placeholder>
				<Input
					ref={inputRef}
					is_icon={icon ? 0 : 1}
					type={type}
					id={id}
					accept={accept}
					onChange={(event) => onChange(event)}
				/>
			</InputWrapper>
		</Wrapper>
	)
}

export default withTranslation()(FileInput)
