// Oreder List     ----------------------

import { colors, device } from 'components/variables'
import styled, { css } from 'styled-components'
import { textColor } from 'utils/help-func'

export const ListWrapp = styled.ul`
	display: flex;
	align-items: center;
	justify-content: space-between;
	max-width: 1000px;
	padding: 0 20px;
	margin-left: auto;
	margin-right: auto;
	flex-direction: column;
	${(props) =>
		props.mode === 'small' &&
		css`
			padding-right: 0;
			padding-left: 0;
		`}
	.arrow {
		width: 24px;
		height: 42px;
		transform: rotate(90deg);
	}
	@media ${device.tablet} {
		flex-direction: row;
		.arrow {
			width: initial;
			height: initial;
			transform: rotate(0deg);
		}
	}
`

export const ListItem = styled.li`
	text-align: center;
	font-size: 15px;
	margin: 70px 0;
	${(props) =>
		textColor({ defaultColor: 'blue', colorSpan: 'black', ...props })}
	.num {
		color: ${colors.blue};
		font-size: 80px;
		line-height: 100px;
	}
	@media ${device.tablet} {
		margin: 0;
		.num {
			font-size: ${(props) => (props.mode === 'large' ? '80px' : '50px')};
		}
	}
`

export const WrapperOrder = styled.div`
	display: block;
	justify-content: space-between;
	width: 100%;
	text-align: center;
	@media ${device.tablet} {
		display: flex;
	}
`
