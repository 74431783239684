import React, { memo, useEffect, useState } from 'react';
import { HomeSlider } from 'components/Slider';
import i18n from 'i18n';
import {
  AboutBlock,
  AboutItem,
  AboutItemText,
  HomeBg,
  HomeWrapper,
  ProjectsDesktop,
  ProjectsMobile,
} from './style';
import { SubtitleLarge, TitleLarge } from 'components/Styled';
import { ProjectsSlider } from 'components/Slider/projects';
import {
  actions as projectActions,
  select as projectSelect,
} from 'containers/Projects/slices';
import {
  actions as newsActions,
  select as newsSelect,
} from 'containers/News/slices';
import {
  actions as propositionsActions,
  select as propositionsSelect,
} from 'containers/Propisitions/slices';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import Button from 'components/Buttons';
import { Link } from 'react-router-dom';
import ProjectItem from 'components/Project';
import {
  ArrowDown,
  BulbIcon,
  MapMarkIcon,
  OilIcon,
  Smile,
} from 'components/Icons';
import { animateScroll as scroll } from 'react-scroll';
import CTA from 'components/CTA';
import AcardionItems from 'components/Acardion';
// import { PropositionSlider } from 'components/Slider/proposition' /* Release v.0.0.1 */
import NewsSlider from 'components/Slider/news';
// import Map from 'components/Map/multyMap'
import FooterForm from 'components/Form/footer-form';
import { OrederedList } from 'components/OrderedList';
import Loader from 'components/Loader';
import LeafMap from 'components/Map/leafletMap';
import { select } from 'containers/Communities/slices';

const about = [
  {
    icon: <MapMarkIcon width='38' height='57' color='blue' />,
    title: 'about.title_1',
    text: 'about.text_1',
  },
  {
    icon: <BulbIcon />,
    title: 'about.title_2',
    text: 'about.text_2',
  },
  {
    icon: <BulbIcon color='blue' />,
    title: 'about.title_3',
    text: 'about.text_3',
  },
  {
    icon: <OilIcon />,
    title: 'about.title_4',
    text: 'about.text_4',
  },
  {
    icon: <Smile color='blue' />,
    title: 'about.title_5',
    text: 'about.text_5',
  },
  {
    icon: <Smile color='orange' />,
    title: 'about.title_6',
    text: 'about.text_6',
  },
];

function Home(props) {
  const {
    getProjectsWorker,
    getNewsWorker,
    sendQuestion,
    t,
    projects,
    news,
    communities,
    proj_loading,
    new_loading,
    prop_loading,
  } = props;
  const region = [
    {
      title: t('aboutRegion.title_1'),
      text: t('aboutRegion.text_1'),
    },
    {
      title: t('aboutRegion.title_2'),
      text: t('aboutRegion.text_2'),
    },
    {
      title: t('aboutRegion.title_3'),
      text: t('aboutRegion.text_3'),
    },
    {
      title: t('aboutRegion.title_4'),
      text: t('aboutRegion.text_4'),
    },
  ];

  const currentLang = i18n.language;
  const globLoad = proj_loading || new_loading || prop_loading;

  const [openAbout, setOpenAbout] = useState(false);

  const scrollToBottom = () => {
    scroll.scrollMore(750);
  };

  const handleSetOpenAbout = () => {
    !openAbout && scrollToBottom();
    setOpenAbout(!openAbout);
  };

  useEffect(() => {
    getProjectsWorker();
    getNewsWorker({ skip: 0, limit: 2 });
  }, [currentLang]);

  return globLoad ? (
    <Loader />
  ) : (
    <HomeWrapper>
      <HomeBg>
        <HomeSlider
          region={t('home.coalRegions')}
          invest={t('home.transition')}
        />
      </HomeBg>
      <TitleLarge className='mt-12'>{t('home.title_1')}</TitleLarge>
      <SubtitleLarge className='mt-4 mb-8 text-center'>
        {t('home.sub_title_1')}
      </SubtitleLarge>
      <ProjectsDesktop>
        <ProjectsSlider projects={projects} slidesToShow={3} />
      </ProjectsDesktop>
      <ProjectsMobile>
        {projects.slice(0, 3).map((item, _index) => (
          <ProjectItem key={_index} className='mt-7' item={item} />
        ))}
      </ProjectsMobile>
      <div className='w-100 text-center mt-9'>
        <Link to='/projects'>
          <Button theme='blue'>{t('button.allProjects')}</Button>
        </Link>
      </div>
      <TitleLarge className='mt-13 mb-9'>{t('home.title_2')}</TitleLarge>
      <AboutBlock className='text-center' open={openAbout}>
        {about.map((item, _index) => (
          <AboutItem className='mt-7' key={_index}>
            {item.icon}
            <SubtitleLarge className='mt-4' weight='bold'>
              {t(item.title)}
            </SubtitleLarge>
            <AboutItemText className='mt-3'>{t(item.text)}</AboutItemText>
          </AboutItem>
        ))}
        <ArrowDown
          id='arrow_more'
          className='arrow_more'
          onClick={handleSetOpenAbout}
        />
      </AboutBlock>
      <CTA
        text={t('projectInfo.InterestedProjects')}
        buttonText={t('projectInfo.Invest')}
        link='contacts_form'
        className='mt-12'
        handleCallBack={sendQuestion}
      />
      <TitleLarge className='mt-12 mb-7'>{t('home.title_3')}</TitleLarge>
      <AcardionItems data={region} />
      <TitleLarge className='mt-12'>{t('home.title_4')}</TitleLarge>
      <OrederedList t={t} className='mt-8' />
      {/* Release v.0.0.1 */}
      {/* <TitleLarge className='mt-12'>{t('home.title_5')}</TitleLarge>
			<SubtitleLarge className='mt-3 mb-8 text-center'>
				{t('home.subtitle_5')}
			</SubtitleLarge>
			<ProjectsDesktop>
				<PropositionSlider projects={propositions} slidesToShow={4} />
			</ProjectsDesktop>
			<ProjectsMobile>
				{projects.slice(0, 3).map((item, _index) => (
					<ProjectItem key={_index} className='mt-7' item={item} />
				))}
			</ProjectsMobile>
			<div className='w-100 text-center mt-9'>
				<Link to='/propositions'>
					<Button theme='blue'>{t('button.allPropositions')}</Button>
				</Link>
			</div> */}
      <TitleLarge className='mt-13'>{t('home.title_6')}</TitleLarge>
      <SubtitleLarge className='mt-3 mb-8 text-center'>
        {t('home.subtitle_6')} <br /> {t('home.subtitle_6-2')}
      </SubtitleLarge>
      <NewsSlider news={news} />
      <div className='w-100 text-center mt-13'>
        <Link to='/news'>
          <Button theme='blue'>{t('button.allNews')}</Button>
        </Link>
      </div>
      <TitleLarge id='google_map' className='mt-13 mb-6'>
        {t('home.title_7')}
      </TitleLarge>
      {/* <Map isMarkerShown /> */}
      <LeafMap communities={communities} />
      <TitleLarge className='mt-12'>{t('home.title_8')}</TitleLarge>
      <FooterForm />
    </HomeWrapper>
  );
}

const mapStateToProps = createStructuredSelector({
  proj_loading: projectSelect.loading,
  new_loading: newsSelect.loading,
  prop_loading: propositionsSelect.loading,
  projects: projectSelect.projects,
  propositions: propositionsSelect.propositions,
  news: newsSelect.news,
  communities: select.communities,
});

const withConnect = connect(mapStateToProps, {
  getProjectsWorker: projectActions.getProjectsStart,
  sendQuestion: projectActions.sendQuestionStart,
  getPropositionsWorker: propositionsActions.getPropositionsStart,
  getNewsWorker: newsActions.getNews,
});

export default compose(withConnect, memo, withTranslation())(Home);
