import { createSlice } from '@reduxjs/toolkit'
import { ProjectModel } from 'models/project'

export * from './selectors'

const projectSlice = createSlice({
	name: 'project',
	initialState: {
		loading: false,
		project: new ProjectModel(),
	},
	reducers: {
		getProject: (state) => {
			state.loading = true
		},
		getSuccess: (state, { payload }) => {
			state.project = new ProjectModel(payload.data)
			state.loading = false
		},
		getError: (state) => {
			state.loading = false
		},
		postProject: (state) => {
			state.loading = true
		},
		postProjectSuccess: (state) => {
			state.loading = false
		},
		postProjectError: (state) => {
			state.loading = false
		},
	},
})

export const { actions, reducer } = projectSlice
