import { OrederedList } from 'components/OrderedList'
import {
	ResponsiveVideo,
	TitleLarge,
	VideoSource,
	TextSmallPt,
	ResponseImage,
	ReverseItem,
	ReverseItemImage,
	FillImage,
} from 'components/Styled'
import React from 'react'
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'
import video from 'static/video/video.mp4'
import branch_1 from 'static/images/branch_1.png'
import branch_2 from 'static/images/branch_2.png'
import { lorem_ipsum, lorem_ipsum_large, lorem_ipsum_small } from '_config'

function InvestPath({ t, ...props }) {
	return (
		<>
			<TitleLarge>Шлях інвестора</TitleLarge>
			<OrederedList mode='small' t={t} className='mt-3' />
			<ResponsiveVideo className='mt-3' controls loop>
				<VideoSource
					src={video}
					type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'
				/>
			</ResponsiveVideo>
			<TextSmallPt className='mt-3'>{lorem_ipsum}</TextSmallPt>
			<ResponseImage src={branch_1} alt='Image' className='mt-6' />
			<TextSmallPt className='mt-3'>{lorem_ipsum_large}</TextSmallPt>
			<ReverseItem className='mt-4'>
				<ReverseItemImage>
					<FillImage src={branch_2} alt='branch image' />
				</ReverseItemImage>
				<TextSmallPt className='mt-3'>{lorem_ipsum_small}</TextSmallPt>
			</ReverseItem>
			<ReverseItem reverse className='mt-4'>
				<ReverseItemImage reverse>
					<FillImage src={branch_2} alt='branch image' />
				</ReverseItemImage>
				<TextSmallPt className='mt-3'>{lorem_ipsum_small}</TextSmallPt>
			</ReverseItem>
		</>
	)
}

export default compose(withTranslation())(InvestPath)
