import { colors, device } from 'components/variables'
import styled from 'styled-components'

export const ScrollWrapper = styled.div`
	display: ${(props) => (props.showScroll ? 'block' : 'none')};
	position: fixed;
	right: 5%;
	bottom: 10%;
	cursor: pointer;
	padding: 10px;
	background: ${colors.grey};
	border-radius: 15px;
	svg path {
		fill: ${colors.lightGrey};
	}
	opacity: 0.4;
	&:hover {
		opacity: 1;
	}
	@media ${device.tablet} {
		background: transparent;
		svg path {
			fill: ${colors.grey};
		}
		opacity: 1;
		padding: 0;
	}
`
