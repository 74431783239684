import styled from 'styled-components'
import { colors, device } from 'components/variables'
import bg from 'static/images/historiesBG.png'

export const HistoryWrapper = styled.div`
	width: 100%;
`

export const ProjectBg = styled.div`
	width: 100%;
	background-repeat: no-repeat;
	background-size: cover;
	background-image: url(${bg});
	margin-bottom: 60px;
`

export const ProgectBgWrapp = styled.div`
	max-width: 1136px;
	margin: 0 auto;
	display: flex;
	align-items: center;
	flex-direction: column-reverse;
	padding: 60px 15px;
	@media ${device.tablet} {
		flex-direction: row;
	}
`

export const ProjectText = styled.div`
	text-align: center;
	margin-right: 0;
	margin-top: 30px;
	@media ${device.tablet} {
		text-align: start;
		margin-right: 60px;
		margin-top: 0;
	}
`

export const ProgectsTitle = styled.span`
	display: block;
	color: ${colors.white};
	font-weight: 800;
	font-size: 40px;
	line-height: 49px;
	margin-bottom: 20px;
	@media ${device.tablet} {
		font-size: 50px;
	}
	@media ${device.laptopS} {
		font-size: 60px;
		line-height: 73px;
	}
`

export const ProgectsUnderTitle = styled.span`
	display: block;
	color: ${colors.white};
	font-weight: normal;
	font-size: 25px;
	line-height: 30px;
	@media ${device.tablet} {
		font-size: 32px;
	}
	@media ${device.laptopS} {
		font-size: 40px;
		line-height: 49px;
	}
`

export const ProjectImageBlock = styled.div`
	max-width: 290px;
	width: 100%;
	height: 200px;
	@media ${device.tablet} {
		height: 300px;
		max-width: 400px;
	}
	@media ${device.laptopS} {
		height: 400px;
		max-width: 500px;
	}
`

export const ProjectImage = styled.img`
	display: block;
	width: 100%;
	height: 100%;
	object-fit: fill;
`

export const Historywrapper = styled.div`
	max-width: 1170px;
	margin: 0 auto;
	padding: 0 15px;
	padding-top: 60px;
	@media ${device.laptopXS} {
		padding: 0;
		padding-top: 60px;
	}
`

export const HistoryContentWrapper = styled.div`
	padding: 60px 0 60px 0;
`

export const HistoryContentText = styled.div`
	margin: 30px 0 60px 0;
	font-family: PT Sans;
	font-weight: normal;
	font-size: 15px;
	color: ${colors.black};
`

export const HistoryElement = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column-reverse;
	margin-bottom: 30px;
	@media ${device.tablet} {
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-direction: ${(props) => (props.isRevers ? 'row-reverse' : 'row')};
		margin-bottom: 30px;
	}
`

export const HistoryElementTxt = styled.div`
	font-family: PT Sans;
	font-weight: normal;
	font-size: 15px;
	color: ${colors.black};
	width: 100%;
	@media ${device.tablet} {
		width: 49%;
	}
`

export const HistoryElementImg = styled.div`
	width: 100%;
	height: 100%;
	.img {
		width: 100%;
		height: 100%;
	}
	margin-bottom: 30px;
	@media ${device.tablet} {
		width: 49%;
		height: 100%;
		margin-bottom: 0;
	}
`

export const HistoryBigImg = styled.div`
	max-width: 100%;
	height: 450px;
	margin-bottom: 40px;
	overflow: hidden;
	position: relative;
	.img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
`
