import React from 'react'

import {
	ProjectsBg,
	ProgectsTitle,
	ProgectsUnderTitle,
	Containe,
} from './style'

function HeaderPhoto({ label, text, bg }) {
	return (
		<ProjectsBg>
			<img className='img' src={bg} alt='projectHeader' />
			<Containe className='container text-center'>
				<ProgectsTitle>{label}</ProgectsTitle>
				<ProgectsUnderTitle>{text}</ProgectsUnderTitle>
			</Containe>
		</ProjectsBg>
	)
}

export default HeaderPhoto
