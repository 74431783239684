import React, { memo, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import i18n from 'i18n';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { actions, select } from './slices';
import { select as communitiesSelect } from 'containers/Communities/slices';
import { select as branchesSelect } from 'containers/Branches/slices';
import HeaderPhoto from 'components/HeaderPhoto';
import GenerBG from 'static/images/new_projects.png';
import Filters from 'components/Filters/filters';
import HeeaderPath from 'components/HeaderPath/headerPath';
import PageProjects from './page';
import { useLocation } from 'react-router-dom';

import { ProjectsWrapper, HeadWrapper } from './style';
import CTA from 'components/CTA';

function Projects(props) {
  const { projects, count, communities, branches, getProjectsWorker, t } =
    props;

  const branchesFormatMenu = branches.map(({ name, _id }) => ({
    label: name,
    value: _id,
    _id: _id,
  }));
  const communitiesFormatMenu = communities.map(({ name, _id }) => ({
    label: name,
    value: _id,
    _id: _id,
  }));

  const filters = [
    {
      label: t('filters.branches'),
      subFilter: branchesFormatMenu,
    },
    {
      label: t('filters.communities'),
      subFilter: communitiesFormatMenu,
    },
    {
      label: t('filters.type'),
      subFilter: [
        { label: 'Грінфілд', value: 'greenfield' },
        { label: 'Брайнфілд', value: 'brownfield' },
      ],
    },
    {
      label: t('filters.financeType'),
      subFilter: [
        { label: 'Інвестиції', value: 'інвестиції' },
        { label: 'Оренда', value: 'оренда' },
      ],
    },
  ];
  const currentLang = i18n.language;
  const location = useLocation();

  const [filterObj, setFilterObj] = useState({});
  const [paginationObj, setPaginationObj] = useState({});
  const [nameObj, setNameObj] = useState({});
  const [hashtag, setHashtag] = useState(location.state?.detail || '');
  const [selected, setSelected] = useState(0);
  const [def_count, setDef_count] = useState(6);

  const links = [
    { generalLink: '/create/project' },
    { label: t('headerPath.home'), link: '/home' },
    { label: t('headerPath.projects'), link: '#' },
  ];

  const hendleCallBack = (obj) => {
    setFilterObj(obj);
  };

  const projectCallBack = (obj) => {
    setPaginationObj(obj);
  };

  const nameCallBack = (name) => {
    setHashtag('');
    setNameObj({ name });
  };

  const hashtagCallBack = (hashtag) => {
    setNameObj({});
    setHashtag(hashtag);
  };

  useEffect(() => {
    let def = window.screen.width < 768 ? 6 : 18;
    setDef_count(def);
    getProjectsWorker({ skip: 0, limit: def });
  }, [currentLang]);

  useEffect(() => {
    getProjectsWorker({ ...filterObj, ...nameObj, ...paginationObj });
  }, [paginationObj, currentLang]);

  useEffect(() => {
    setSelected(0);
    getProjectsWorker({
      ...filterObj,
      ...nameObj,
      hashtag,
      skip: 0,
      limit: def_count,
    });
  }, [filterObj, nameObj, hashtag, currentLang]);

  return (
    <ProjectsWrapper>
      <HeaderPhoto bg={GenerBG} label={t('headerPath.projects')} />
      <HeadWrapper className='mt-6'>
        <HeeaderPath
          nameHash={hashtag}
          nameName={nameObj.name}
          plusLabel={'проект'}
          searchBox
          links={links}
          hendleCallBack={nameCallBack}
        />
        <Filters filters={filters} changeFilterCallBack={hendleCallBack} />
      </HeadWrapper>
      <PageProjects
        projects={projects}
        def_count={def_count}
        selected={selected}
        count={count}
        setSelected={setSelected}
        hendleCallBack={projectCallBack}
        hashtagCallBack={hashtagCallBack}
      />
      <CTA
        text={t('projectInfo.InterestedProjects')}
        buttonText={t('projectInfo.Invest')}
        link='contacts_form'
        className='mt-12'
      />
    </ProjectsWrapper>
  );
}

const mapStateToProps = createStructuredSelector({
  loading: select.loading,
  projects: select.projects,
  count: select.count,
  communities: communitiesSelect.communities,
  branches: branchesSelect.branches,
});

const withConnect = connect(mapStateToProps, {
  getProjectsWorker: actions.getProjectsStart,
});

export default compose(withConnect, memo, withTranslation())(Projects);
