import { colors, device } from 'components/variables'
import styled from 'styled-components'

export const Wrapper = styled.div`
	padding-left: 15px;
	padding-right: 15px;
	margin-top: 60px;
	@media ${device.tablet} {
		margin-top: 0;
	}
`

export const PDFDownloadLink = styled.a`
	display: inline-block;
	font-weight: 500;
	font-size: 20px;
	line-height: 24px;
	width: 100%;
	text-align: center;
	cursor: pointer;
	color: ${colors.blue};
	text-decoration-line: underline;
	@media ${device.tablet} {
		font-size: 25px;
		line-height: 30px;
	}
`
