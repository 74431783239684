import { colors } from 'components/variables'
import styled from 'styled-components'

export const AddProperty = styled.div`
	padding-left: 15px;
	color: ${colors.darkGrey};
	font-family: 'PT Sans', 'sans-serif';
	font-size: 18px;
	line-height: 23px;
	cursor: pointer;
`

export const EditorWrapper = styled.div`
	padding-left: 15px;
	width: 100%;
`
