import { createSlice } from '@reduxjs/toolkit'
import { NewsModel } from 'models/newsItem'

export * from './selectors'

const programsReducer = createSlice({
	name: 'programs',
	initialState: {
		programs: [],
		count: 0,
		loading: false,
	},
	reducers: {
		getPrograms: (state) => {
			state.loading = true
		},
		getSuccess: (state, { payload }) => {
			state.programs = payload.data.articles
			state.count = payload.data.count
			state.loading = false
		},
		getError: (state) => {
			state.loading = false
		},

	},
})

export const { actions, reducer } = programsReducer
