import * as React from 'react'
import { useRef } from 'react'
import environment from 'environment'

// Require Editor JS files.
import 'froala-editor/js/froala_editor.pkgd.min.js'
import 'froala-editor/js/plugins.pkgd.min.js'
import 'froala-editor/js/third_party/embedly.min.js'

// Require Editor CSS files.
import 'froala-editor/css/froala_style.min.css'
import 'froala-editor/css/froala_editor.pkgd.min.css'
import 'froala-editor/css/third_party/embedly.min.css'

import Froala from 'react-froala-wysiwyg'
import { UploadPhotoFloara } from 'api/api'

export const FroalaEditor = ({ config, model, onModelChange, ...props }) => {
	const ref = useRef({ editor: null })

	return (
		<div className='App'>
			<Froala
				ref={ref}
				model={model}
				onModelChange={onModelChange}
				tag='textarea'
				config={{
					attribution: false,
					placeholder: 'Start typing...',
					toolbarButtons: config.toolbarButtons,
					pluginsEnabled: config.pluginsEnabled,
					events: {
						'image.beforeUpload': function (images) {
							for (let i = 0; i < images.length; i++) {
								UploadPhotoFloara(images[i]).then(({ data }) => {
									this.html.insert(
										`<img id='${data?.upload?.id}' class='r-fic fr-dib fr-draggable' src='${environment.staticUrl}images/${data?.upload?.name}' width='300px'></img>`,
										true
									)
								})
							}
							return false
						},
						'image.uploaded': function (img) {},
						'image.beforeRemove': function (img) {},
					},
				}}
			/>
		</div>
	)
}
