import { useField } from 'formik'
import { withTranslation } from 'react-i18next'
import PhoneInput from 'react-phone-number-input/input'
import {
	ErrorText,
	IconWrapp,
	Input,
	InputWrapper,
	Label,
	Wrapper,
} from './style'

const BasicInput = (props) => {
	const [field, meta] = useField({ ...props })

	const {
		label,
		className,
		id,
		t,
		icon,
		type,
		placeholder,
		pattern,
		phone,
		largeLabel,
		noBold,
	} = props

	const Icon = icon

	const { value } = field

	const errors = meta.touched && meta.error

	return (
		<Wrapper className={className}>
			{label && (
				<Label noBold={noBold} largeLabel={largeLabel} htmlFor={field.name}>
					{label}
				</Label>
			)}
			<InputWrapper error={errors ? 1 : 0}>
				{errors && <ErrorText>{t(`error.${meta.error}`)}</ErrorText>}
				{icon && (
					<IconWrapp>
						<Icon active={value} error={errors ? 1 : 0} />
					</IconWrapp>
				)}
				{phone ? (
					<PhoneInput
						is_icon={icon ? 1 : 0}
						className='phoneInput'
						placeholder={placeholder}
						id={id}
						country={props.country}
						value={value}
						onChange={(value) => props.onChange(field.name, value)}
					/>
				) : (
					<Input
						is_icon={icon ? 1 : 0}
						placeholder={placeholder}
						type={type}
						id={id}
						pattern={pattern}
						{...field}
						{...meta}
						value={value}
					/>
				)}
			</InputWrapper>
		</Wrapper>
	)
}

export default withTranslation()(BasicInput)
