import { colors, device } from 'components/variables'
import React from 'react'
import styled from 'styled-components'
import desk_404 from 'static/images/404_desktop.png'
import mob_404 from 'static/images/404_mobile.png'
import Button from 'components/Buttons'
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next'

const Container = styled.div`
	border-top: 1px solid ${colors.orange};
	border-bottom: 1px solid ${colors.orange};
	padding: 100px 0 110px 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	width: 100%;
`

const Title = styled.h1`
	font-weight: 800;
	font-size: 30px;
	line-height: 37px;
	color: ${colors.orange};
	@media ${device.tablet} {
		font-size: 50px;
		line-height: 61px;
	}
`

function NotFoundPage({ t, ...props }) {
	return (
		<div className='container m-auto'>
			<Container>
				<img src={desk_404} alt='404' className='d-none d-md-block' />
				<img src={mob_404} alt='404' className='d-block d-md-none' />
				<Title className='mt-6'>Сторінка не знайдена</Title>
				<Link className='mt-6' to='/home'>
					<Button theme='blue'>{t('check.button')}</Button>
				</Link>
			</Container>
		</div>
	)
}

export default withTranslation()(NotFoundPage)
