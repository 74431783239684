import HeaderPhoto from 'components/HeaderPhoto'
import {
	Col,
	Container,
	FillImage,
	Row,
	SubtitleLarge,
	NoData,
	NoImage,
	NoImg,
	SupperImg,
} from 'components/Styled'
import { BranchImage, BranchWrapp } from 'containers/Branches/style'
import { select } from 'containers/Communities/slices'
import React, { memo } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { compose } from 'redux'
import { createStructuredSelector } from 'reselect'
import head_bg from 'static/images/cont_head_bg.png'
import HeaderPath from 'components/HeaderPath/headerPath'
import img1 from 'static/images/NonImgColor.png'

function Contacts({ t, communities, ...props }) {
	const links = [
		{ generalLink: '#' },
		{ label: t('headerPath.home'), link: '/home' },
		{ label: t('headerPath.contacts'), link: '#' },
	]
	const dataDone = communities.length ? false : true
	return (
		<>
			<HeaderPhoto label='Контакти' text='' bg={head_bg} />
			<Container className='container mt-6'>
				<HeaderPath links={links} />
				{dataDone ? (
					<NoData>контактів не знайдено</NoData>
				) : (
					<Row className='row'>
						{communities.map((item, _index) => {
							const regularExp = new RegExp('https?://')
							const imge = regularExp.exec(item.mainphoto)
							return (
								<Col key={_index} className='col-12 col-sm-6 col-lg-4 mt-6'>
									<Link to={`/contacts/${item._id}`}>
										<BranchWrapp>
											<BranchImage>
												<SupperImg>
													{imge ? (
														<FillImage
															className='img'
															src={item.mainphoto}
															alt='Project Image'
														/>
													) : (
														<NoImage>
															<NoImg src={img1} alt='Project Image' />
														</NoImage>
													)}
												</SupperImg>
											</BranchImage>
											<SubtitleLarge
												weight='bold'
												className='text text-center mt-2'
											>
												{item.name}
											</SubtitleLarge>
										</BranchWrapp>
									</Link>
								</Col>
							)
						})}
					</Row>
				)}
			</Container>
		</>
	)
}

const mapStateToProps = createStructuredSelector({
	communities: select.communities,
})

const withConnect = connect(mapStateToProps, {})

export default compose(withConnect, memo, withTranslation())(Contacts)
