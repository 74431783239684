import { createSlice } from '@reduxjs/toolkit'
import { UserModel } from 'models/user'

const branchSlice = createSlice({
	name: 'user',
	initialState: {
		user: new UserModel(),
		loading: false,
	},
	reducers: {
		getUserStart: (state) => {
			state.loading = true
		},
		getUserError: (state) => {
			state.loading = false
		},
		getUserSuccess: (state, { payload }) => {
			state.user = new UserModel(payload.data)
			state.loading = false
		},
	},
})

export const { actions, reducer } = branchSlice
