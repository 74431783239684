import React, { memo, useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Info from './info';
import Passport from './passport';
import { Wrapper } from '../style';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';

function Routing({ tabs, match, info, selected, t, ...props }) {
  const path = {
    community: `${match.url}`,
    profile: `${match.url}/profile`,
    passport: `${match.url}/passport`,
    water: `${match.url}/water`,
    reorganize: `${match.url}/reorganize`,
    scenario: `${match.url}/scenario`,
  };
  // useEffect(() => {
  // 	return true
  // }, [selected])
  return (
    <Wrapper className='col-12 col-sm-9 col-md-8'>
      <Switch>
        {tabs.map((item, _index) => (
          <Route key={_index} exact path={`${match.url + '/' + item.name}`}>
            <Info
              pdf={item.pdf}
              match={match}
              title={t(`${item.name}`)}
              content={item.value}
            />
          </Route>
        ))}
        {info.profile && (
          <Route exact path={path.profile}>
            <Passport title={t('sideBar.Profile')} pdf={info.profile} />
          </Route>
        )}
        {info.main && (
          <Route exact path={path.passport}>
            <Passport title={t('sideBar.Passport')} pdf={info.main} />
          </Route>
        )}
        {info.water && (
          <Route exact path={path.water}>
            <Passport title={t('sideBar.Indicators')} pdf={info.water} />
          </Route>
        )}
        <Route
          exact
          render={() => (
            <Redirect
              to={
                selected.link
                  ? match.url + selected.link
                  : path.passport || path.water || path.profile
              }
            />
          )}
        />
      </Switch>
    </Wrapper>
  );
}

export default compose(memo, withTranslation())(Routing);
