import { colors } from 'components/variables'
import { css } from 'styled-components'
import _config from '_config'

export const textColor = (props) => {
	return css`
		color: ${props.color ? colors[props.color] : colors[props.defaultColor]};
		span {
			color: ${props.colorSpan && colors[props.colorSpan]};
		}
	`
}

export const delay = (time) => new Promise((resolve) => setTimeout(resolve, 0))

export const createMarkup = (content) => {
	return { __html: content }
}

export const findProject = (place, _id) => {
	return place.find((item) => (item._id || item.id) === _id)
}

export const toArrayFunc = (_object) => {
	let array = []
	for (const key in _object) {
		array.push({ type: key, value: _object[key] })
	}
	return array
}

export const findProposition = (_id) => {
	return _config.mockData.propositions.find((item) => item.id === _id)
}

export const findNewItem = (_id) => {
	return _config.mockData.news.find((item) => item._id === _id)
}

export const findHistoryItem = (_id) => {
	return _config.mockData.histories.find((item) => item.id === _id)
}

export const propToArray = (properties, object) => {
	return properties.map((item) => {
		if (object[item.type]) {
			return { name: item.type, value: object[item.type] }
		} else return { name: item.type, value: '' }
	})
}
