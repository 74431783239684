import { colors, device } from 'components/variables'
import styled, { css } from 'styled-components'

// Header style ----------------------------

export const HeaderWrapper = styled.div`
	width: 100%;
	max-width: 1170px;
	margin: 0 auto;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-top: 50px;
	padding: 50px 15px 0 15px;
	background-color: ${colors.white};
	z-index: 1;
	position: relative;
	padding-bottom: 30px;
	@media ${device.laptopS} {
		padding: 0;
	}
`

export const LogoWrapp = styled.div`
	display: block;
	@media ${device.laptopS} {
		display: none;
	}
`

export const HeadreContainer = styled.div`
	width: 100%;
	justify-content: space-between;
	padding: 65px 15px 45px 15px;
	margin: 0 auto;
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: ${colors.white};
	display: none;
	.arrow {
		transition: 0.25s;
	}
	${(props) =>
		props.open &&
		css`
			display: block;
		`}
	@media ${device.laptopS} {
		position: relative;
		display: flex;
	}
`

// Menu -----------------------------------

export const Menu = styled.ul`
	display: block;
	width: 100%;
	margin-top: 50px;
	@media ${device.laptopS} {
		position: relative;
		display: flex;
		justify-content: space-between;
		align-items: center;
		max-width: 685px;
		margin-top: 0;
	}
`

export const MenuItem = styled.li`
	position: relative;
	display: block;
	align-items: center;
	max-width: 195px;
	font-size: 12px;
	font-weight: 500;
	color: ${colors.black};
	cursor: pointer;
	padding: 16px 0;
	background-color: ${colors.white};
	min-width: ${(props) => (props.mode ? '185px' : 'auto')};
	.arrow {
		margin-left: 10px;
		float: right;
	}
	&:hover {
		color: ${colors.blue};
		.arrow {
			transform: rotate(180deg);
			path {
				fill: ${colors.blue};
			}
		}
		.subMenu {
			max-height: 120px;
			transition: max-height 0.25s ease-in;
		}
	}
	@media ${device.laptopS} {
		padding: 0 16px;
		display: flex;
	}
`

export const SubMenu = styled.ul`
	position: relative;
	padding-left: 16px;
	left: 0;
	top: 15px;
	background-color: ${colors.white};
	overflow: hidden;
	transition: max-height 0.25s ease-out;
	max-height: 0;
	/* width: 102%; */
	margin-top: 10px;
	a:first-child li {
		/* line-height: 16px; */
	}
	${(props) =>
		props.large &&
		css`
			overflow-y: auto;
			&::-webkit-scrollbar {
				width: 20px;
			}
			&::-webkit-scrollbar-track {
				background: ${colors.darkGrey};
				border-left: 13.7px solid ${colors.white};
				border-right: 6.3px solid ${colors.white};
			}
			&::-webkit-scrollbar-thumb {
				background: ${colors.orange};
				border-left: 11px solid ${colors.white};
				border-right: 3px solid ${colors.white};
			}
		`}
	@media ${device.laptopS} {
		position: absolute;
	}
`

export const SubItem = styled.li`
	font-size: 13px;
	font-weight: normal;
	color: ${colors.black};
	line-height: 16px;
	margin-bottom: 10px;
	white-space: nowrap;
	cursor: pointer;
	&:hover {
		color: ${colors.blue};
	}
`

// Third block ----------------------------

export const ThirdBlock = styled.div`
	width: 100%;
	@media ${device.laptopS} {
		display: flex;
		align-items: center;
		justify-content: space-between;
		max-width: 175px;
	}
`

export const HeaderLogin = styled.div`
	display: flex;
	align-items: center;
	font-weight: 500;
	color: ${colors.blue};
	cursor: pointer;
	margin-top: 16px;
	a {
		margin-left: 10px;
	}
	@media ${device.laptopS} {
		margin-top: 0;
	}
`

export const Language = styled.div`
	position: relative;
	display: flex;
	align-items: flex-start;
	font-weight: 500;
	color: ${colors.black};
	cursor: pointer;
	overflow: hidden;
	margin-top: 30px;
	&:hover {
		color: ${colors.blue};
		overflow: visible;
		transition: overflow 0.25s ease-in;
		.arrow {
			transform: rotate(180deg);
		}
	}
	@media ${device.laptopS} {
		padding: 0 16px;
		margin-top: 0;
	}
`

export const CurrentLang = styled.div`
	text-transform: uppercase;
	font-weight: 500;
	font-size: 14px;
	margin: 0 10px;
`

export const LangList = styled.ul`
	position: absolute;
	top: 10px;
	left: 27px;
	@media ${device.laptopS} {
		margin-left: 10px;
		top: 10px;
		left: 32px;
	}
`

export const ListItem = styled.li`
	color: ${colors.black};
	text-transform: uppercase;
	font-weight: 500;
	font-size: 14px;
	margin-top: 10px;
	&:hover {
		color: ${colors.blue};
	}
`

export const Burger = styled.span`
	top: 12px;
	position: absolute;
	height: 3px;
	width: 30px;
	background: #000;
	margin: 0 auto;
	transition: 0.5s;
	right: 5%;
	z-index: 999;
	display: block;
	&::before {
		position: absolute;
		height: 3px;
		width: 30px;
		background: #000;
		margin: 0 auto;
		top: 10px;
		content: '';
		transition: 0.5s;
	}
	&::after {
		position: absolute;
		height: 3px;
		width: 30px;
		background: #000;
		margin: 0 auto;
		bottom: 10px;
		content: '';
		transition: 0.5s;
	}
	${(p) =>
		p.open &&
		css`
			top: 4px;
			height: 0;
			transition: 0s;
			&::before {
				transform: rotate(45deg);
				bottom: 10px;
				transition: 0.5s;
			}
			&::after {
				transform: rotate(-45deg);
				top: 9px;
				transition: 0.5s;
			}
		`}
	@media ${device.laptopS} {
		display: none;
	}
`

export const DiV = styled.div`
	cursor: pointer;
	position: absolute;
	height: 30px;
	width: 30px;
	right: 5%;
	z-index: 1000;
	display: block;
	${(p) =>
		p.open &&
		css`
			position: fixed;
			top: 68px;
		`}
	@media ${device.laptopS} {
		display: none;
	}
`
