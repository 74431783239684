// /* eslint-disable prettier/prettier */
import { colors, device } from 'components/variables'
import styled from 'styled-components'
import { textColor } from 'utils/help-func'

// Home       ---------------------------

export const SliderWrapp = styled.div`
	position: relative;
	max-width: 1440px;
	margin: 0 auto;
	.slick-arrow {
		position: absolute;
		top: 50%;
		z-index: 2;
		width: 24px;
		height: 42px;
		transition: 0.25s;
		&:hover {
			path {
				fill: ${colors.orange};
			}
		}
	}
	.slick-next,
	.slick-prev {
		&::before {
			display: none;
		}
	}
	.slick-next {
		right: 33px;
	}
	.slick-prev {
		left: 33px;
	}
	.slick-dots {
		text-align: end;
		padding-right: 135px;
		bottom: 42px;
	}
	.slick-dots li {
		width: 50px;
		height: 10px;
		background: ${colors.orange};
	}
	.slick-dots li button {
		&::before {
			display: none;
		}
		background: ${colors.grey};
		width: 50px;
		height: 10px;
		margin-right: 10px;
	}
	.slick-dots .slick-active button {
		background: ${colors.orange};
	}
	.slick-slide > div {
		width: 100%;
		display: flex;
		align-items: center;
	}
	.slick-dots,
	.slick-arrow {
		display: none !important;
	}
	.slick-list {
		height: 100%;
	}
	@media ${device.laptopS} {
		.slick-dots,
		.slick-arrow {
			display: block !important;
		}
	}
`

// Projects, Proposition -----------------------

export const CaruselWrapp = styled.div`
	margin: 0 auto;
	position: relative;
	.slick-slide {
		padding: 0 15px;
		position: relative;
		opacity: 0.7;
	}
	.slick-slide .project-slide {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 3;
	}
	.slick-arrow {
		position: absolute;
		top: 50%;
		z-index: 2;
		width: 24px;
		height: 42px;
		transition: 0.25s;
		&:hover {
			path {
				fill: ${colors.orange};
			}
		}
	}
	.slick-next,
	.slick-prev {
		&::before {
			display: none !important;
		}
		display: none !important;
	}
	.slick-active {
		opacity: 1;
	}
	.slick-active .project-slide {
		display: none;
	}
	@media ${device.laptopS} {
		.slick-next,
		.slick-prev {
			display: block !important;
		}
		.slick-next {
			right: 12px;
		}
		.slick-prev {
			left: 12px;
		}
		.slick-slide {
			width: 342px !important;
			padding: 0;
		}
		.slick-slide {
			padding: none;
		}
	}
	@media ${device.laptopXS} {
		.slick-next {
			right: 5%;
		}
		.slick-prev {
			left: 5%;
		}
	}
	@media ${device.laptopL} {
		.slick-next {
			right: 10%;
		}
		.slick-prev {
			left: 10%;
		}
	}
	@media ${device.desktop} {
		.slick-next {
			right: 17%;
		}
		.slick-prev {
			left: 17%;
		}
	}
`

export const PropositionWrap = styled(CaruselWrapp)`
	.slick-slide {
		padding: 0 15px;
	}
	@media ${device.laptopS} {
		.slick-slide {
			width: 300px !important;
			padding: 0;
		}
		.slick-list {
			margin-left: -300px;
		}
		.slick-active {
			opacity: 0.7;
		}
		.slick-active .project-slide {
			display: block;
		}
		.slick-active + .slick-active {
			opacity: 1;
			.project-slide {
				display: none;
			}
		}
	}
`

// News slider      -----------------

export const NewsSliderWrapp = styled(SliderWrapp)`
	.slick-arrow {
		position: absolute;
		top: auto;
		bottom: -26%;
		z-index: 2;
		width: 24px;
		height: 42px;
		transition: 0.25s;
		display: block !important;
		&:hover {
			path {
				fill: ${colors.orange};
			}
		}
	}
	.slick-next {
		right: 33%;
	}
	.slick-prev {
		left: 33%;
	}
	@media ${device.mobileXL} {
		.slick-next {
			right: 43%;
		}
		.slick-prev {
			left: 43%;
		}
	}
	@media ${device.tablet} {
		.slick-arrow {
			bottom: -41%;
		}
	}
	@media ${device.laptopS} {
		.slick-arrow {
			top: 50%;
			bottom: auto;
		}
		.slick-next {
			right: 0px;
		}
		.slick-prev {
			left: 0px;
		}
	}
	@media ${device.laptopM} {
		.slick-next {
			right: -50px;
		}
		.slick-prev {
			left: -50px;
		}
	}
`

// Home top slider  -----------------

export const Slide = styled.div`
	width: 100%;
	display: flex !important;
	justify-content: space-between;
	align-items: center;
	flex-wrap: nowrap;
	padding: 100px 0;
	flex-direction: column-reverse;
	@media ${device.laptopS} {
		flex-direction: row;
		padding: 105px 100px 170px 100px;
	}
`

export const SlideTextBlock = styled.div`
	width: 100%;
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	${(props) => textColor({ defaultColor: 'lightGrey' })};
	margin-top: 45px;
	@media ${device.laptopS} {
		width: auto;
		text-align: start;
		margin-top: 0;
	}
`

export const SlideTitle = styled.h1`
	font-size: 40px;
	font-weight: 800;
	line-height: 49px;
	@media ${device.laptopM} {
		font-size: 60px;
		line-height: 73px;
	}
`

export const SlideText = styled.p`
	font-size: 25px;
	font-weight: normal;
	line-height: 30px;
	@media ${device.laptopM} {
		font-size: 40px;
		line-height: 49px;
	}
`

export const SlideImgBlock = styled.div`
	cursor: pointer;
	padding: 0 10px;
	width: 100%;
	height: 243px;
	@media ${device.mobileXL} {
		width: 100%;
		height: 430px;
	}
	@media ${device.laptopM} {
		width: 600px;
		height: 400px;
	}
`

export const SlideImg = styled.img`
	width: 100%;
	height: 100%;
`

// Histories slider      -----------------

export const HistoriesSliderWrapp = styled(SliderWrapp)`
	margin-bottom: 100px;
	.slick-arrow {
		position: absolute;
		top: auto;
		bottom: -100px;
		z-index: 2;
		width: 24px;
		height: 42px;
		transition: 0.25s;
		display: block !important;
		&:hover {
			path {
				fill: ${colors.orange};
			}
		}
	}
	.slick-next {
			right: 30%;
	}
	.slick-prev {
		left: 30%;
	}
	@media ${device.tabletM} {
		.slick-next {
			right: 44%;
	}
	.slick-prev {
		left: 44%;
	}
	}
	@media ${device.laptopS} {
		.slick-arrow {
			bottom: -130px;
		}
	}
	@media ${device.laptopM} {
		.slick-arrow {
			top: 50%;
			bottom: auto;
		}
		.slick-next {
			right: -70px;
		}
		.slick-prev {
			left: -70px;
		}
		margin-bottom: 0;
	}
`
