import Api from 'utils/api';
import environment from 'environment';
import { getToken } from 'utils/auth';
import { UploadModelFolder } from 'models/upload';

export async function UploadPhotoFloara(image) {
  try {
    const formData = new FormData();
    formData.append('photo', image, image.name);
    const token = getToken();
    const res = await fetch(`${environment.apiUrl}api/uploads/create`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    });
    return await res.json();
  } catch (e) {
    console.error('Failed upload image', e);
  }
}

export async function uploadImage(payload) {
  const { file } = payload;
  const formData = new FormData();

  formData.append('photo', file, file.name);

  try {
    const { data } = await Api.post('/api/uploads/create', formData, {});
    return new UploadModelFolder(data.upload);
  } catch (err) {
    console.error('Failed to upload image');
  }
}

export const ImagesUpload = async (images, mode = 'notProfile') => {
  if (!images) {
    return;
  }

  const fn = function asyncMultiplyBy2(image) {
    return (
      image.id ||
      new Promise((resolve) => {
        resolve(uploadImage(image));
      }).then(({ image }) => image)
    );
  };

  return Promise.all(images.map(fn));
};

export async function sendFeedBack(payload) {
  const body = {
    data: {
      name: payload.name || '',
      phoneNumber: payload.phone || '',
      communityName: payload.community.label || '', // id
      text: payload.content || '',
      email: payload.email || '',
    },
  };
  try {
    const data = await Api.post(`api/feedbacks/create`, body);
    data.success && payload?.done();
  } catch (e) {
    console.error('Failed send', e);
  }
}

export async function getLocation() {
  try {
    const data = await Api.get('/api/projects/getLocations');
    const markers = data.data.projects.map((item) => {
      return {
        _id: item._id,
        name: item.name,
        lat: item.location.coordinates[0],
        lng: item.location.coordinates[1],
      };
    });
    return markers;
  } catch (err) {
    console.error('Failed to upload image');
  }
}

// export async function getLocationRegion() {
//     try {
//         const data = await Api.get('/api/projects/getLocations')
//         const markers = data.data.projects.map((item) => {
//             return {
//                 _id: item._id,
//                 name: item.name,
//                 coords: item.coords
//             }
//         })
//         return markers
//     } catch (err) {
//         console.error('Failed to upload image')
//     }
// }
