import { call, put, takeLatest } from '@redux-saga/core/effects';
import { uploadImage } from 'api/api';
import Api from 'utils/api';
import history from 'utils/history';
import { actions } from './slices';
import i18n from 'i18n';

function* getProjectWorker({ payload }) {
  const currentLang = i18n.language;
  const lang = {
    uk: payload.prod.projectUk,
    ru: payload.prod.projectRu,
    en: payload.prod.projectEn,
  };
  const body = {
    filter: {
      _id: lang[currentLang],
    },
  };

  try {
    const data = yield call(Api.post, '/api/projects/getById', body);
    yield put({
      ...actions.getSuccess(data),
    });
  } catch (e) {
    yield put({ ...actions.getError() });
    console.error(e);
    history.push('/error');
  }
}

function* postProjectWorker({ payload }) {
  try {
    const image_data = yield uploadImage(payload.mainphoto);
    const data = yield call(Api.post, `/api/projects/create`, {
      data: {
        ...payload,
        mainphoto: image_data.location,
      },
    });
    yield put({
      ...actions.postProjectSuccess(),
    });
    data.success && history.push('/projects');
  } catch (e) {
    console.error(e);
    yield put({ ...actions.postProjectError() });
    history.push('/error');
  }
}

export function* projectSaga() {
  yield takeLatest(actions.getProject, getProjectWorker);
  yield takeLatest(actions.postProject, postProjectWorker);
}
