import styled from 'styled-components'

export const ProjectsWrapper = styled.div`
	width: 100%;
`

export const HeadWrapper = styled.div`
	margin: 0 auto;
	max-width: 1170px;
	padding: 0 15px;
`
