import React from 'react'
import { CloseIcon } from 'components/Icons'
import Modal from 'react-modal'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'
import { SubtitleLarge } from 'components/Styled'
import styled from 'styled-components'
const customStyles = {
	overlay: {
		zIndex: '400'
	},
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		width: '530px',
		height: '165px',
		paddingTop: '0',
		position: 'relative',
	},
}

const CloseIconWrap = styled.div`
	position: absolute;
	cursor: pointer;
	top: 15px;
	right: 15px;
`

function SuccessModal({ openModal, closeModal, t, ...props }) {
	return (
		<div>
			<Modal
				isOpen={openModal}
				onRequestClose={closeModal}
				style={customStyles}
				contentLabel='Example Modal'
				ariaHideApp={false}
			>
				<CloseIconWrap onClick={closeModal}>
					<CloseIcon />
				</CloseIconWrap>
				<SubtitleLarge
					color='black'
					colorSpan='blue'
					className='mt-5 mb-3 text-center'
					weight='bold'
				>
					Інформація
					<span> успішно відправлена, </span>
					перевірте свою поштову скриньку.
				</SubtitleLarge>
			</Modal>
		</div>
	)
}

export default compose(withTranslation())(SuccessModal)
