import {
	Tooltip,
	ResponsiveContainer,
	BarChart,
	XAxis,
	YAxis,
	Legend,
	Bar,
    LabelList,
    Label
} from 'recharts'
import styled from 'styled-components'

const Wrapper = styled.div`
	height: ${props => props.height}px;
`

export const HorizontalBar = ({ pause = false, data, colors, datakey, dataheight = '570', datastack = '1', ...props }) => {
	const fixedDataKey = datakey.map(item => item.replaceAll('_', ' '))
	return (
		<Wrapper height={dataheight}>
			<ResponsiveContainer width='100%' height='100%'>
				<BarChart
					width={600}
					height={200}
					data={data}
					stackOffset='sign'
					barSize={100}
				>
					<YAxis tickCount={5} type='number' />
					<XAxis
						tickLine={false}
						type='category'
						dataKey='name'
					/>
					<Tooltip />
					{/* <Legend verticalAlign='bottom' align='center' /> */}
					{fixedDataKey && fixedDataKey.map( (datakey, index) => (  
                    <Bar
						isAnimationActive={!pause}
						animationDuration={400}
                        key={index}
						dataKey={datakey}
						fill={colors[index]}
						stackId={Number(datastack)}>
                            <LabelList fill='#fff' dataKey={datakey} position="inside" />
                    </Bar>))}
				</BarChart>
			</ResponsiveContainer>
		</Wrapper>
	)
}
