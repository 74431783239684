import React, { memo, useEffect } from 'react'
import WithImageHead from 'components/HeaderPhoto/with-image'
import { ContactsLocation } from 'components/Icons'
import { Container, Row, TitleLarge } from 'components/Styled'
import { actions, select } from 'containers/Community/slices'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { useParams } from 'react-router'
import { compose } from 'redux'
import { createStructuredSelector } from 'reselect'
import head_bg from 'static/images/cont_head_bg.png'
import { ContactItem, ContactItemText, ContactItemTitle } from './style'
import FooterForm from 'components/Form/footer-form'
import HeaderPath from 'components/HeaderPath/headerPath'
import Loader from 'components/Loader'
import MarkerMap from 'components/Map/markerMap'

function Contact({ t, loading, community, getCommunityWorker, ...props }) {
	const { contacts, name, location } = community
	const { address, phone, graph, email, site, facebook } = contacts

	let { id } = useParams()

	const links = [
		{ generalLink: '#' },
		{ label: t('headerPath.home'), link: '/home' },
		{ label: t('headerPath.contacts'), link: '/contacts' },
		{ label: t('headerPath.contacts') + ` "${name}"`, link: '#' },
	]

	useEffect(() => {
		getCommunityWorker({ id })
	}, [id])
	return loading ? (
		<Loader />
	) : (
		<>
			<WithImageHead
				bg={head_bg}
				image={community.mainphoto}
				label='Контакти'
				text={`Контакти ${community.name}`}
			/>
			<div className='container mt-6'>
				<HeaderPath links={links} />
			</div>
			<div className='w-100'>
				<MarkerMap isMarkerShown coordinates={location?.coordinates} />
			</div>
			<Container className='container mt-6'>
				<Row className='row'>
					{address && (
						<div className='col-12 col-md-6 col-lg-4'>
							<ContactItem className='mt-6'>
								<ContactsLocation />
								<div className='text ml-3'>
									<ContactItemTitle>Адреса</ContactItemTitle>
									<ContactItemText>{address.value}</ContactItemText>
								</div>
							</ContactItem>
						</div>
					)}
					{phone && (
						<div className='col-12 col-md-6 col-lg-4'>
							<ContactItem className='mt-6'>
								<ContactsLocation />
								<div className='text ml-3'>
									<ContactItemTitle>Телефон</ContactItemTitle>
									<ContactItemText>{phone.value}</ContactItemText>
								</div>
							</ContactItem>
						</div>
					)}
					{graph && (
						<div className='col-12 col-md-6 col-lg-4'>
							<ContactItem className='mt-6'>
								<ContactsLocation />
								<div className='text ml-3'>
									<ContactItemTitle>Режим роботи</ContactItemTitle>
									<ContactItemText>{graph.value}</ContactItemText>
								</div>
							</ContactItem>
						</div>
					)}
					{email && (
						<div className='col-12 col-md-6 col-lg-4'>
							<ContactItem className='mt-6'>
								<ContactsLocation />
								<div className='text ml-3'>
									<ContactItemTitle>E-mail</ContactItemTitle>
									<ContactItemText>{email.value}</ContactItemText>
								</div>
							</ContactItem>
						</div>
					)}
					{site && (
						<div className='col-12 col-md-6 col-lg-4'>
							<ContactItem className='mt-6'>
								<ContactsLocation />
								<div className='text ml-3'>
									<ContactItemTitle>Веб-сайт</ContactItemTitle>
									<ContactItemText>{site.value}</ContactItemText>
								</div>
							</ContactItem>
						</div>
					)}
					{facebook && (
						<div className='col-12 col-md-6 col-lg-4'>
							<ContactItem className='mt-6'>
								<ContactsLocation />
								<div className='text ml-3'>
									<ContactItemTitle>Фейсбук</ContactItemTitle>
									<ContactItemText>{facebook.value}</ContactItemText>
								</div>
							</ContactItem>
						</div>
					)}
				</Row>
				<TitleLarge className='mt-12'>Залиште запитання</TitleLarge>
				<FooterForm community={community} />
			</Container>
		</>
	)
}

const mapStateToProps = createStructuredSelector({
	community: select.community,
	loading: select.loading,
})

const withConnect = connect(mapStateToProps, {
	getCommunityWorker: actions.getCommunity,
})

export default compose(withConnect, memo, withTranslation())(Contact)
