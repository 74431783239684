import React, { memo, useEffect } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { createStructuredSelector } from 'reselect'
import { actions, select } from './slices'
import {
	select as historiesSelect,
	actions as historiesActions,
} from 'containers/HistoriesSuccess/slices'
import HeaderPath from 'components/HeaderPath/headerPath'
import { HashText, DataText } from 'components/Project/style'
import HistoriesSlider from 'components/Slider/history'
import GenerBG from 'static/images/historiesBG.png'
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView'


import {
	HistoryWrapper,
	Historywrapper,
	HistoryContentWrapper,
} from './style'

import { TitleLarge } from 'components/Styled'
import WithImageHead from 'components/HeaderPhoto/with-image'
import Loader from 'components/Loader'
import { useParams } from 'react-router'

import environment from 'environment'


function HistoryContent({ historyItem }) {
	const { date, hashtag, content } = historyItem
	return (
		<HistoryContentWrapper>
			<div className='d-flex justify-content-between aligin-items-center mb-4'>
				<DataText color='grey'>{date}</DataText>
				<HashText>{hashtag}</HashText>
			</div>
			<FroalaEditorView model={content} />
		</HistoryContentWrapper>
	)
}

function HistoryItem(props) {
	const {
		loading,
		loading_h,
		histories,
		historyItem,
		getHistoryItemWorker,
		getHistoies,
		t,
	} = props
	const { name, image } = historyItem

	let { id } = useParams()

	const links = [
		{ generalLink: '#' },
		{ label: t('headerPath.home'), link: '/home' },
		{ label: t('headerPath.histories'), link: '/histories' },
		{ label: name, link: '#' },
	]
	useEffect(() => {
		getHistoies()
	}, [])
	useEffect(() => {
		getHistoryItemWorker({ id })
	}, [id])
	return loading || loading_h ? (
		<Loader />
	) : (
		<HistoryWrapper>
			<WithImageHead label={name} image={environment.staticUrl + 'images/' + image} bg={GenerBG} />
			<Historywrapper>
				<HeaderPath plusLabel={'історію'} admin links={links} />
				<HistoryContent historyItem={historyItem} />
				<TitleLarge className='mb-8'>{t('history.sliderTitle')}</TitleLarge>
				<HistoriesSlider histories={histories} />
			</Historywrapper>
		</HistoryWrapper>
	)
}

const mapStateToProps = createStructuredSelector({
	loading: select.loading,
	loading_h: historiesSelect.loading,
	historyItem: select.historyItem,
	histories: historiesSelect.histories,
})

const withConnect = connect(mapStateToProps, {
	getHistoryItemWorker: actions.getHistoryItem,
	getHistoies: historiesActions.getHistoies,
})

export default compose(withConnect, memo, withTranslation())(HistoryItem)
