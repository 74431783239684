import Button from 'components/Buttons'
import Input from 'components/Form/input'
import { LoginUser } from 'components/Icons'
import AuthLoyout from 'components/Loyout/auth'
import { TitleLarge } from 'components/Styled'
import { Form, Formik } from 'formik'
import React from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { createStructuredSelector } from 'reselect'
import * as Yup from 'yup'
import { actions, select } from '../slices'

function Reset({ t, loading, resetWorker, error, ...props }) {
	return (
		<AuthLoyout>
			<TitleLarge>{t('reset.title')}</TitleLarge>
			<Formik
				initialValues={{
					login: '',
				}}
				validationSchema={Yup.object({
					login: Yup.string().email(t('incorect')).required(t('required')),
				})}
				onSubmit={(value) => {
					const payload = {
						login: value.login,
					}
					resetWorker(payload)
				}}
			>
				{(props) => {
					const { values, handleChange } = props
					return (
						<div className='container'>
							<div className='row'>
								<Form className='col-12 col-md-8 col-lg-6 mx-auto px-1 px-lg-4 d-flex justify-content-center align-items-center flex-wrapp flex-column'>
									<Input
										className='mt-5'
										id='email'
										icon={LoginUser}
										placeholder={t('login.emailPlaceholder')}
										name='login'
										type='text'
										value={values.login}
										onChange={handleChange}
									/>
									<Button
										disabled={loading}
										theme='blue'
										className='mt-5'
										type='submit'
									>
										{t('reset.button')}
									</Button>
								</Form>
							</div>
						</div>
					)
				}}
			</Formik>
		</AuthLoyout>
	)
}

const mapStateToProps = createStructuredSelector({
	loading: select.loading,
	error: select.error,
})

const withConnect = connect(mapStateToProps, {
	resetWorker: actions.resetStart,
})

export default compose(withConnect, withTranslation())(Reset)
