import React, { memo } from 'react';
import { SidebarContent, SidebarWrapper } from 'containers/Project/style';
import { Tab } from 'components/Styled';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';

function SideBar({ info, tabs, selected, hanleSetSelected, t, ...props }) {
  return (
    <SidebarWrapper className='col-12 col-sm-9 col-md-4'>
      <SidebarContent>
        {info.profile && (
          <Tab
            active={selected.name === 'profile'}
            onClick={hanleSetSelected({ name: 'profile' })}
          >
            {t('sideBar.Profile')}
          </Tab>
        )}
        {info.main && (
          <Tab
            active={selected.name === 'passport'}
            onClick={hanleSetSelected({ name: 'passport' })}
          >
            {t('sideBar.Passport')}
          </Tab>
        )}
        {info.water && (
          <Tab
            active={selected.name === 'water'}
            onClick={hanleSetSelected({ name: 'water' })}
          >
            {t('sideBar.Indicators')}
          </Tab>
        )}
        {tabs.map((item, _index) => {
          const active = selected === item;
          return (
            <Tab key={_index} active={active} onClick={hanleSetSelected(item)}>
              {t(`${item.name}`)}
            </Tab>
          );
        })}
      </SidebarContent>
    </SidebarWrapper>
  );
}

export default compose(memo, withTranslation())(SideBar);
