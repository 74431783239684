import React from 'react'
import { RadialBarChart, RadialBar } from 'recharts'

// const style = {
// 	top: '50%',
// 	right: 0,
// 	transform: 'translate(0, -50%)',
// 	lineHeight: '24px',
// }

export function RadialBarComponent({ data, ...props }) {
	return (
		<div>
			{/* <ResponsiveContainer width='100%' height='100%'> */}
			<RadialBarChart
				width={300}
				height={400}
				cx='50%'
				cy='50%'
				innerRadius='40%'
				outerRadius='80%'
				barSize={20}
				data={data}
				startAngle={90}
				endAngle={0}
			>
				<RadialBar
					// minAngle={15}
					label={{ position: 'insideEnd', fill: '#fff' }}
					background
					clockWise
					dataKey='value'
				/>
			</RadialBarChart>
			{/* </ResponsiveContainer> */}
		</div>
	)
}
