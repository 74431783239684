import { TitleLarge } from 'components/Styled'
import { device } from 'components/variables'
import styled from 'styled-components'

export const NewsWrapper = styled.div`
	max-width: 1140px;
	margin: 0 auto;
	display: flex !important;
	align-items: center;
	justify-content: center;
	padding: 0 10px;
	flex-direction: column-reverse;
	@media ${device.tablet} {
		flex-direction: ${(props) => (props.isRevers ? 'row-reverse' : 'row')};
	}
`

export const TextBlock = styled.div`
	text-align: start;
	max-width: 400px;
	width: 100%;
	margin-right: 0;
	@media ${device.tablet} {
		margin-right: ${(props) => (props.isRevers ? '0' : '6%')};
		margin-left: ${(props) => (props.isRevers ? '6%' : '0')};
		max-width: 453px;
	}
`

export const ImageBlock = styled.div`
	cursor: pointer;
	height: 160px;
	width: 100%;
	max-width: 400px;
	margin-bottom: 30px;
	@media ${device.tablet} {
		height: 250px;
		max-width: 400px;
		margin-bottom: 0;
	}
	@media ${device.laptopM} {
		height: ${(props) => (props.mode === 'large' ? '345px' : '300px')};
		max-width: ${(props) => (props.mode === 'large' ? '576px' : '622px')};
	}
`

export const NewsTitle = styled(TitleLarge)`
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: pre-wrap;
	overflow-wrap: break-word;
`
