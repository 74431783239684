import Button from 'components/Buttons'
import Input from 'components/Form/input'
import BasicSelct from 'components/Form/select'
import {
	EmailIcon,
	LoginUser,
	MapMarkIcon,
	PhoneIcon,
	PsswordIcon,
} from 'components/Icons'
import Loader from 'components/Loader'
import AuthLoyout from 'components/Loyout/auth'
import { LoginSmall, TitleLarge } from 'components/Styled'
import { Form, Formik } from 'formik'
import i18n from 'i18n'
import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import { compose } from 'redux'
import { createStructuredSelector } from 'reselect'
import * as Yup from 'yup'
import { actions as authActions, select as authSelect } from '../slices'
import { select as communitiesSelect } from 'containers/Communities/slices'
import { SelectModel } from 'models/select'

function useQuery() {
	return new URLSearchParams(useLocation().search)
}

function Register({
	t,
	loading,
	registerWorker,
	checkWorker,
	getCommunities,
	communities,
	...props
}) {
	const [option, setOption] = useState()

	let query = useQuery()

	let country = i18n.language.toUpperCase() === 'UK' ? 'UA' : 'US'
	useEffect(() => {
		checkWorker({ code: query.get('code') })
		// getCommunities()
	}, [])

	return (
		<AuthLoyout>
			{loading ? (
				<Loader />
			) : (
				<>
					<TitleLarge>{t('register.title')}</TitleLarge>
					<Formik
						initialValues={{
							//login: '',
							name: '',
							email: query.get('email'),
							phone: '',
							repeatPass: '',
							password: '',
							community: option,
						}}
						validationSchema={Yup.object({
							//login: Yup.string().required(t('required')),
							name: Yup.string().required(t('required')),
							email: Yup.string().required(t('required')).email(t('incorect')),
							phone: Yup.number().required(t('required')).min(10, 'short'),
							password: Yup.string()
								.required(t('required'))
								.min(8, t('short'))
								.matches(/[a-zA-Z]/, t('incorect')),
							repeatPass: Yup.string()
								.oneOf([Yup.ref('password'), null], 'notMatch')
								.required(t('required')),
							community: Yup.object().required(t('required')),
						})}
						onSubmit={(value) => {
							registerWorker({ ...value, code: query.get('code') })
						}}
					>
						{(props) => {
							const { values, handleChange, setFieldValue } = props

							const handleSetField = (newInput = 'phone', value) => {
								setFieldValue(newInput, value)
							}
							const handleSetOption = (value) => {
								setFieldValue('community', value)
								setOption(value)
							}

							return (
								<div className='container'>
									<div className='row'>
										<Form className='col-12 col-md-8 col-lg-6 mx-auto px-1 px-lg-4 d-flex justify-content-center align-items-center flex-wrapp flex-column'>
											{/* <Input
												className='mt-5'
												id='login'
												icon={LoginUser}
												placeholder={t('login.emailPlaceholder')}
												name='login'
												type='text'
												value={values.login}
												onChange={handleChange}
											/> */}
											<Input
												className='mt-3'
												id='name'
												icon={LoginUser}
												placeholder={t('register.name')}
												name='name'
												type='text'
												value={values.name}
												onChange={handleChange}
											/>
											<Input
												className='mt-3'
												id='email'
												icon={EmailIcon}
												placeholder={t('register.email')}
												name='email'
												type='text'
												value={values.email}
												onChange={handleChange}
											/>
											<Input
												phone={true}
												country={country}
												className='mt-3'
												id='phone'
												icon={PhoneIcon}
												placeholder={t('register.phone')}
												name='phone'
												value={values.phone}
												onChange={handleSetField}
											/>
											<Input
												className='mt-3'
												id='password'
												icon={PsswordIcon}
												placeholder={t('register.password')}
												name='password'
												type='password'
												value={values.password}
												onChange={handleChange}
											/>
											<Input
												className='mt-3'
												id='repeatPass'
												icon={PsswordIcon}
												placeholder={t('register.repetPass')}
												name='repeatPass'
												type='password'
												value={values.repeatPass}
												onChange={handleChange}
											/>
											<BasicSelct
												className='mt-3'
												options={communities.map(
													(item) => new SelectModel(item)
												)}
												value={option}
												onChange={handleSetOption}
												placeholder={t('register.community')}
												icon={MapMarkIcon}
												name='community'
											/>
											<LoginSmall className='mt-4'>
												<Link to='/auth/reset'>{t('register.resetLink')}</Link>
											</LoginSmall>
											<Button
												disabled={loading}
												theme='blue'
												className='mt-5'
												type='submit'
											>
												{t('register.button')}
											</Button>
										</Form>
									</div>
								</div>
							)
						}}
					</Formik>
				</>
			)}
		</AuthLoyout>
	)
}

const mapStateToProps = createStructuredSelector({
	loading: authSelect.loading,
	communities: communitiesSelect.communities,
})

const withConnect = connect(mapStateToProps, {
	registerWorker: authActions.registerStart,
	checkWorker: authActions.checkStart,
})

export default compose(withConnect, withTranslation())(Register)
