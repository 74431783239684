import React, { useState, useRef, useEffect } from 'react'
import Select, { components } from 'react-select'
import useClickOutside from 'components/UseClickOutside/useClickOutside'
import { withTranslation } from 'react-i18next'

import {
	FilterArrow,
	FilterClose,
	SortArrows,
	FilterDrop,
	FilterDropArrow,
} from 'components/Icons'

import {
	ProjectsFiltersWrapper,
	customFilterStyle,
	ProjectsFiltersClose,
	FilterContainer,
	ProjectsFiltersSmolClose,
	customFilterSmolStyle,
	FilterSmolContainer,
	FiltersAll,
	FiltersOpened,
	FiltersOpenedClose,
	ProjectsFiltersDrop,
	customFilterOpenStyle,
	SpaN,
} from './style'

const DropdownIndicator = (props) => {
	return (
		<components.DropdownIndicator {...props}>
			<FilterArrow className='filterArrow' />
		</components.DropdownIndicator>
	)
}

function FilterItem({
	item,
	style = customFilterStyle,
	WrapperClose = ProjectsFiltersClose,
	visInput = true,
	WrapperFilter = FilterContainer,
	smolOpen = false,
	handelCoolback = () => {},
}) {
	const [selected, setSelected] = useState(null)
	const [handleOpen, setOpenHandler] = useState(false)
	const hendleChange = (value) => {
		let name = reName(item.label)
		handelCoolback({ name, ...value })
		setSelected(value)
	}
	const setOpened = () => {
		setOpenHandler(!handleOpen)
	}
	const defView = (value) => {
		let name = reName(item.label)
		handelCoolback({ name, ...value })
		setSelected(null)
	}
	const reName = (el) => {
		if (el === 'Громади') return 'community'
		if (el === 'Галузі') return 'industry'
		if (el === 'Тип проекту') return 'type'
		if (el === 'Тип фінансування') return 'financeType'
		else return 'sortType'
	}
	if (handleOpen && selected) setOpened()
	return (
		<WrapperFilter
			className={
				handleOpen && smolOpen ? 'smolOpen hei' : smolOpen ? 'smolOpen' : null
			}
		>
			{selected ? (
				<WrapperClose>
					<FilterClose onClick={() => defView(selected)} />
				</WrapperClose>
			) : null}
			<Select
				onFocus={setOpened}
				onBlur={setOpened}
				styles={style}
				value={selected}
				placeholder={item.label}
				options={item.subFilter}
				onChange={(option) => hendleChange(option)}
				components={{
					DropdownIndicator: DropdownIndicator,
					IndicatorSeparator: () => null,
				}}
				captureMenuScroll={false}
				menuIsOpen={handleOpen}
				isSearchable={visInput}
			/>
		</WrapperFilter>
	)
}

function FilterCreate({
	filters,
	smolOpen,
	style = customFilterStyle,
	handelCoolback,
}) {
	return filters.map((item, _index) => {
		return (
			<FilterItem
				key={_index}
				item={item}
				smolOpen={smolOpen}
				style={style}
				handelCoolback={handelCoolback}
			/>
		)
	})
}

function FilterSortItem({ handelCoolback }) {
	const item = {
		label: <SortArrows className='sortArrowSmol' />,
		subFilter: [
			{ label: 'A-Z', value: 'asc' },
			{ label: 'Z-A', value: 'desc' },
			{
				label: (
					<SpaN>
						$ <FilterArrow className='arrows rotate' />
					</SpaN>
				),
				value: 'asc',
			},
			{
				label: (
					<SpaN>
						$ <FilterArrow className='arrows' />
					</SpaN>
				),
				value: 'desc',
			},
		],
	}
	return (
		<FilterItem
			item={item}
			WrapperClose={ProjectsFiltersSmolClose}
			style={customFilterSmolStyle}
			visInput={false}
			WrapperFilter={FilterSmolContainer}
			handelCoolback={handelCoolback}
		/>
	)
}

function Filter({ t, filters, changeFilterCallBack = () => {} }) {
	const [selectedAllFilters, setSelectedAllFilters] = useState({})
	const [smolFilters, setSmolFiltes] = useState(false)
	const setOpen = () => {
		setSmolFiltes(!smolFilters)
	}
	const ref = useRef()
	useClickOutside(ref, () => {
		if (smolFilters) setSmolFiltes(false)
	})
	useEffect(() => {
		changeFilterCallBack(selectedAllFilters)
	}, [selectedAllFilters])
	const change = (value) => {
		setSelectedAllFilters((selectedAllFilters) => {
			return selectedAllFilters[value.name]
				? Object.assign({}, selectedAllFilters, {
						[value.name]: '',
				  })
				: Object.assign({}, selectedAllFilters, {
						[value.name]: value.value,
				  })
		})
	}
	return (
		<ProjectsFiltersWrapper>
			<FilterCreate filters={filters} handelCoolback={change} />
			<FiltersOpened ref={ref} className={smolFilters ? 'FiltersOpen' : null}>
				<FiltersOpenedClose onClick={setOpen}>
					Фільтри <FilterDropArrow className='filterDropArrow' />
				</FiltersOpenedClose>
				<ProjectsFiltersDrop>
					<FilterCreate
						filters={filters}
						smolOpen={true}
						style={customFilterOpenStyle}
						handelCoolback={change}
					/>
				</ProjectsFiltersDrop>
			</FiltersOpened>
			<FiltersAll onClick={setOpen}>
				Фільтри
				<FilterDrop className='filterDrop' />
			</FiltersAll>
			<FilterSortItem handelCoolback={change} />
		</ProjectsFiltersWrapper>
	)
}

export default withTranslation()(Filter)
