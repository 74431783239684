import { ConnectedRouter } from 'connected-react-router'
import { Provider } from 'react-redux'
import React, {useEffect} from 'react'
import history from 'utils/history'
import App from 'containers/App'
import store from 'store'
import ReactGA from 'react-ga';

import 'static/sass/index.scss'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import '@react-pdf-viewer/core/lib/styles/index.css'
import '@react-pdf-viewer/default-layout/lib/styles/index.css'
import 'froala-editor/css/froala_editor.pkgd.min.css'
import 'react-day-picker/lib/style.css'
import 'leaflet/dist/leaflet.css'

export default function AppIndex() {
	useEffect(() => {
		ReactGA.initialize('UA-215592594-1');
		ReactGA.pageview(window.location.pathname + window.location.search);
	}, [])
	return (
		<Provider store={store}>
			<ConnectedRouter history={history}>
				<App />
			</ConnectedRouter>
		</Provider>
	)
}
