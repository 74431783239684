import React, { memo, useState } from 'react'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import Input from 'components/Form/input'
import { EmailIcon, LoginUser, MapMarkIcon, PhoneIcon } from 'components/Icons'
import i18n from 'i18n'
import BasicSelct from 'components/Form/select'
import { createStructuredSelector } from 'reselect'
import { select as communitiesSelect } from 'containers/Communities/slices'
import { connect } from 'react-redux'
import {
	actions as authActions,
	select as authSelect,
} from 'containers/Auth/slices'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'
import Button from 'components/Buttons'
import Textarea from '../textarea'
import CheckBox from '../cehck-box'
import { Link } from 'react-router-dom'
import { SelectModel } from 'models/select'
import { sendFeedBack } from 'api/api'
import { userSelect } from 'containers/selectors/user'
import SuccessModal from 'components/Modal/success'
import ReactGA from 'react-ga';

function FooterForm({
	communities,
	t,
	loading,
	userData,
	community,
	...props
}) {
	const [success, setSuccess] = useState(false)

	const handleCloseSuccess = () => {
		setSuccess(false)
	}
	const done = () => setSuccess(true)

	const communitiesFormat = [
		...communities.map((item) => new SelectModel(item)),
		new SelectModel({ label: 'загальне питання', _id: '' }),
	]

	const communityFormat = {
		label: community?.name,
		value: community?._id,
		_id: community?._id,
	}

	const [selected, setSelected] = useState(
		communityFormat.label ? communityFormat : ''
	)

	const handleSetSelected = (option) => {
		setSelected(option)
	}

	const handleCallBack = (value) => {
		if (value.personalData && value.policy) sendFeedBack({done: done, ...value})
	}

	let country = i18n.language.toUpperCase() === 'UK' ? 'UA' : 'US'
	const { email, name, phone } = userData
	//const { mockData: { user: { email, name, phone } } } = _config
	return (
		<>
			<SuccessModal openModal={success} closeModal={handleCloseSuccess} />
			<Formik
				initialValues={{
					name: name || '',
					phone: phone || '',
					email: email || '',
					community: selected,
					content: '',
					policy: false,
					personalData: false,
				}}
				validationSchema={Yup.object({
					name: Yup.string().trim().required(t('required')),
					phone: Yup.number().required(t('required')).min(10, 'short'),
					email: Yup.string().trim().required(t('required')).email(t('incorect')),
					community: Yup.object().required(t('required')),
					content: Yup.string().trim().required(t('required')),
					personalData: Yup.boolean().required(t('required')),
					policy: Yup.boolean().required(t('required')),
				})}
				onSubmit={(value) => {
					// sendQuestion(value)
					handleCallBack(value)
					ReactGA.ga('send', 'event', 'Question Form', 'Submit')
					// loginWorker(payload)
				}}
			>
				{(props) => {
					const { values, handleChange, setFieldValue } = props

					const handleSetField = (newInput = 'phone', value) => {
						setFieldValue(newInput, value)
					}
					const handleSetOption = (value) => {
						setFieldValue('community', value)
						handleSetSelected(value)
					}

					return (
						<Form className='mx-auto px-1 px-lg-4 d-flex justify-content-center align-items-center'>
							<div className='container px-0'>
								<div className='row'>
									<Input
										className='col-12 col-md-6 mt-2 mt-md-3'
										id='name'
										icon={LoginUser}
										placeholder={t('register.name')}
										name='name'
										type='text'
										value={values.name}
										onChange={handleChange}
									/>
									<Input
										className='col-12 col-md-6 mt-2 mt-md-3'
										id='email'
										icon={EmailIcon}
										placeholder={t('register.email')}
										name='email'
										type='text'
										value={values.email}
										onChange={handleChange}
									/>
									<Input
										phone={true}
										country={country}
										className='col-12 col-md-6 mt-2 mt-md-3'
										id='phone'
										icon={PhoneIcon}
										placeholder={t('register.phone')}
										name='phone'
										value={values.phone}
										onChange={handleSetField}
									/>
									<BasicSelct
										mode='true'
										className='col-12 col-md-6 mt-2 mt-md-3'
										options={communitiesFormat}
										value={selected}
										onChange={(option) => handleSetOption(option)}
										placeholder={t('register.community')}
										icon={MapMarkIcon}
										name='community'
									/>
									<Textarea
										className='col-12 mt-4 mt-md-4'
										id='content'
										icon={EmailIcon}
										placeholder={t('request.content')}
										name='content'
										type='textarea'
										value={values.content}
										onChange={handleChange}
									/>
									<CheckBox
										className='col-12 col-md-6 text-center d-flex d-md-block mt-4 mt-md-4'
										name='personalData'
										id='personalData'
										value={values.personalData}
										label={t('checkbox.personalData')}
										onChange={handleChange}
									/>
									<CheckBox
										className='col-12 col-md-6 text-center d-flex d-md-block mt-4 mt-md-4'
										name='policy'
										id='policy'
										value={values.policy}
										label={
											<div>
												{t('checkbox.privacy')}
												<Link to='/home'>
													{t('checkbox.privacy_link')}
												</Link>
											</div>
										}
										onChange={handleChange}
									/>
									<div className='col-12 text-center'>
										<Button
											disabled={loading}
											theme='blue'
											className='mt-5'
											type='submit'
										>
											{t('button.sendData')}
										</Button>
									</div>
								</div>
							</div>
						</Form>
					)
				}}
			</Formik>
		</>
	)
}

const mapStateToProps = createStructuredSelector({
	loading: authSelect.loading,
	communities: communitiesSelect.communities,
	userData: userSelect.user,
})

const withConnect = connect(mapStateToProps, {
	sendQuestion: authActions.questionStart(),
})

export default compose(withConnect, memo, withTranslation())(FooterForm)
