import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import styled from 'styled-components'
import { isAuth } from 'utils/auth'

export const Content = styled.div`
	width: 100%;
	flex: 1 0 auto;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	width: 100%;
`

const PrivateRouter = ({ component: Component, children, ...rest }) => {
	return (
		<Route
			{...rest}
			render={(props) => {
				return isAuth() ? (
					children ? (
						<Content>{children}</Content>
					) : (
						<Component {...props} />
					)
				) : (
					<Redirect
						to={{
							pathname: `/auth`,
						}}
					/>
				)
			}}
		/>
	)
}

export default PrivateRouter
