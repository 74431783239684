export const colors = {
	blue: '#293E81',
	black: '#161616',
	lightGrey: '#EBEBEB',
	white: '#ffffff',
	grey: '#A59F9F',
	orange: '#D66C20',
	darkGrey: '#6D6B6B',
	red: '#DE4848',
	arrowDis: '#DCDADA',
	nodata: '#2a3d7913',
}

export const size = {
	mobileS: '0',
	mobileM: '375',
	mobileL: '425',
	mobileXL: '480',
	tabletM: '640',
	tablet: '768',
	laptop: '992',
	laptopS: '1024',
	laptopXS: '1200',
	laptopM: '1366',
	laptopL: '1440',
	desktop: '1920',
	costSize: '1163',
	size_850: '850',
}

export const device = {
	mobileS: `(min-width: ${size.mobileS}px)`,
	mobileM: `(min-width: ${size.mobileM}px)`,
	mobileL: `(min-width: ${size.mobileL}px)`,
	mobileXL: `(min-width: ${size.mobileXL}px)`,
	tabletM: `(min-width: ${size.tabletM}px)`,
	tablet: `(min-width: ${size.tablet}px)`,
	laptop: `(min-width: ${size.laptop}px)`,
	laptopS: `(min-width: ${size.laptopS}px)`,
	laptopXS: `(min-width: ${size.laptopXS}px)`,
	laptopM: `(min-width: ${size.laptopM}px)`,
	laptopL: `(min-width: ${size.laptopL}px)`,
	desktop: `(min-width: ${size.desktop}px)`,
	costSize: `(min-width: ${size.costSize}px)`,
	size_850: `(min-width: ${size.size_850}px)`,
}
