import Axios from 'axios';
import { removeToken, getToken } from './auth';
import environment from 'environment';

const axios = Axios.create({
  baseURL: environment.apiUrl,
  headers: {
    'Content-Type': 'application/json',
  },
});

axios.interceptors.request.use((req) => {
  const { headers } = req;
  // if is JWT Authorization
  const token = getToken();
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  return req;
});

axios.interceptors.response.use(
  (response) => response.data,
  (err) => {
    if (err.response.status === 401) {
      removeToken();
    } else {
      console.error('Error');
    }
    return Promise.reject(err);
  }
);

export default axios;
