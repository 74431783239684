import {
	Tooltip,
	ResponsiveContainer,
	XAxis,
	YAxis,
	Legend,
    LabelList,
    Label,
    Line,
    LineChart,
    CartesianGrid
} from 'recharts'
import styled from 'styled-components'

const Wrapper = styled.div`
	height: ${props => props.height}px;
`
const CustomizedDot = ({ ...props }) => {
        const { cx, cy } = props;
        return (
            <rect x={cx-2} y={cy-2} width='5' height='5' stroke={props.stroke} strokeWidth={3} fill={props.stroke}/>
        )}
export const LineGraph = ({ pause = false, data, colors, dataheight, ...props }) => {
    const fixedDataKey = props.datakey.map(item => item.replace('_', ' '))
	return (
        <Wrapper height={dataheight}>
		<ResponsiveContainer width="100%" height="100%">
        <LineChart  data={data}>
        <XAxis dataKey="name" padding={{ left: 30, right: 30 }} />
        <YAxis hide={true} padding={{bottom: 10}}/>
          <Tooltip />
          <Legend iconSize={8} iconType='square'/>
          {fixedDataKey && fixedDataKey.map( (datakey, index) => (  
                    <Line
						isAnimationActive={!pause}
						animationDuration={400}
                        key={index}
						dataKey={datakey}
                        stroke={colors[index]}
                        dot={<CustomizedDot />}
                        strokeWidth={2.5}
                        >
                        <LabelList fill='#333' dataKey={datakey} position="top" offset='10'/>
                    </Line>))}
          {/* <Line type="monotone" dataKey="pv" stroke="#8884d8" activeDot={{ r: 8 }} />
          <Line type="monotone" dataKey="uv" stroke="#82ca9d" /> */}
        </LineChart>
      </ResponsiveContainer>
      </Wrapper>
	)
}
