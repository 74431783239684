import styled from 'styled-components'

export const HistoriesItem = styled.div`
	text-align: center;
	&.w-100 {
		width: 100%;
	}
`

export const HistoriesImg = styled.div`
	max-width: 100%;
	height: 450px;
	margin-bottom: 40px;
	overflow: hidden;
	position: relative;
	.img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
`

export const HistoriesLabel = styled.span`
	font-weight: 500;
	font-size: 30px;
	line-height: 37px;
	background-repeat: no-repeat;
	background-size: cover;
`
