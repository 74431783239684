import { DelateCategory } from 'components/Icons'
import { useField } from 'formik'
import React, { useRef } from 'react'
import { withTranslation } from 'react-i18next'
import {
	CategoryItem,
	ErrorText,
	Input,
	InputWrapperCategory,
	Label,
	Wrapper,
} from './style'

function Category(props) {
	const {
		categories,
		className,
		label,
		noBold,
		largeLabel,
		type,
		t,
		placeholder,
		onKeyDown,
		handleRemoveCategory,
	} = props

	const [field, meta] = useField({ ...props })
	const inputRef = useRef(null)

	const errors =
		meta.touched && meta.error === 'required'
			? !categories.length
				? true
				: false
			: meta.error

	const handleFocus = () => {
		if (categories.length >= 3) {
			return
		}
		inputRef.current.click()
	}

	return (
		<Wrapper className={className}>
			{label && (
				<Label noBold={noBold} largeLabel={largeLabel} htmlFor={field.name}>
					{label}
				</Label>
			)}
			<InputWrapperCategory error={errors ? 1 : 0} onClick={handleFocus}>
				{errors && <ErrorText>{t(`error.${meta.error}`)}</ErrorText>}
				{categories.map((item, _index) => (
					<CategoryItem key={_index}>
						{item}
						<DelateCategory
							className='close cursor-pointer'
							onClick={handleRemoveCategory(item)}
						/>
					</CategoryItem>
				))}
				{categories.length < 3 && (
					<Input
						className='category'
						ref={inputRef}
						placeholder={placeholder}
						type={type}
						onKeyDown={onKeyDown}
						{...field}
						{...meta}
					/>
				)}
			</InputWrapperCategory>
		</Wrapper>
	)
}

export default withTranslation()(Category)
