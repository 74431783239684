import { createSlice } from '@reduxjs/toolkit'

export * from './selectors'

const projects = createSlice({
	name: 'projects',
	initialState: {
		loading: false,
		projects: [],
		count: 0,
	},
	reducers: {
		getProjectsStart: (state) => {
			state.loading = true
		},
		getProjectsSuccess: (state, { payload }) => {
			state.projects = payload.projects
			state.count = payload.count
			state.loading = false
		},
		getProjectsError: (state) => {
			state.loading = false
		},
		sendQuestionStart: (state) => {},
		sendQuestionEnd: (state) => {},
	},
})

export const { actions, reducer } = projects
