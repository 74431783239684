import Button from 'components/Buttons'
import InvestModal from 'components/Modal/invest'
import { TitleLarge } from 'components/Styled'
import { colors, device } from 'components/variables'
import React, { memo, useState } from 'react'
import styled from 'styled-components'
import { GlobalStyle } from 'components/Styled'
import { sendFeedBack } from 'api/api'

const CtaWrapp = styled.div`
	width: 100%;
	background: ${colors.blue};
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	min-height: 180px;
	padding: 40px;
	.button {
		margin-top: 30px;
		margin-left: 0;
	}
	@media ${device.tablet} {
		flex-direction: row;
		.button {
			margin-left: 100px;
			margin-top: 0;
		}
	}
`

function CTA({
	text = '',
	buttonText = '',
	link = '',
	hanleOpen,
	...props
}) {
	const [openModal, setopenModal] = useState(false)

	const handleSetOpenModal = () => {
		setopenModal(true)
	}
	const closeModal = () => {
		setopenModal(false)
	}
	const handleCallBack = (data) => {
		const body = {
			name: data.modal_name,
			email: data.modal_email,
			phone: data.modal_phone,
			text: '',
			community: '',
			done: data.done,
		}
		if (data.modal_personalData && data.modal_policy_1) sendFeedBack(body)
	}
	return (
		<>
			<GlobalStyle open={openModal} />
			<InvestModal
				openModal={openModal}
				title={text}
				closeModal={closeModal}
				buttonText={buttonText}
				handleCallBack={handleCallBack}
			/>
			<CtaWrapp {...props}>
				<TitleLarge color='white'>{text}</TitleLarge>
				<Button className='button' onClick={handleSetOpenModal}>
					{buttonText}
				</Button>
			</CtaWrapp>
		</>
	)
}

export default memo(CTA)
