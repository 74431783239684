const toArrayFunc = (_object) => {
	let array = []
	for (const key in _object) {
		array.push({ type: key, value: _object[key] })
	}
	return array
}
export class PropositionModel {
	constructor(data = {}) {
		const { offer } = data
		this._id = offer?.id
		this.image = offer?.image
		this.name = offer?.name
		this.date = offer?.createdAt
		this.hashtag = offer?.hashtag
		this.content = offer?.content
		this.property = toArrayFunc(new PropositionProperty(offer))
	}
}
export class PropositionProperty {
	constructor(data = {}) {
		this.invest = data.invest
		this.square = data.square
		this.implementation_period = data.implementation_period
		this.phone = data.phone
		this.email = data.email
		this.ownership = data.ownership
		this.state_part = data.state_part
		this.projectGoal = data.projectGoal
		this.adress = data.adress
		this.result = data.result
		this.interest = data.interest
	}
}
