import React, { memo } from 'react'
import CreateForm from 'components/Form/createItem'
import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import { compose } from 'redux'
import Loader from 'components/Loader'
import { actions, select } from 'containers/News/slices'

function CreateNews({ createNewsWorker, loading, ...props }) {
	const handleSubmit = (data) => {
		const payload = {
			name: data.name,
			date: data.date,
			hashtag: data.hashtag,
			type: 'news',
			content: data.content,
			description: data.description,
			category: 'mock',
			author: data.author,
			mainphoto: data.mainphoto,
		}
		createNewsWorker(payload)
	}

	return (
		<>
			{loading ? (
				<Loader />
			) : (
				<CreateForm
					input_label_1='Заголовок новини:'
					handleSubmit={handleSubmit}
					typeOfItem='news'
					isDescribe
				/>
			)}
		</>
	)
}

const mapStateToProps = createStructuredSelector({
	loading: select.loading,
})

const withConnect = connect(mapStateToProps, {
	createNewsWorker: actions.createNews,
})

export default compose(withConnect, memo)(CreateNews)
