import { useEffect } from 'react'

const useClickOutside = (ref, callback) => {
	const handleClick = (e) => {
		if (ref.current && !ref.current.contains(e.target)) {
			callback()
		}
	}
	useEffect(() => {
		document.addEventListener('click', handleClick)
		document.addEventListener('scroll', handleClick)
		return () => {
			document.removeEventListener('click', handleClick)
		}
	})
}

export default useClickOutside
