import { colors } from 'components/variables'
import React from 'react'
import styled, { css } from 'styled-components'

const generateTheme = (props) => {
	let style
	switch (props.mode) {
		case 'small': {
			style = css`
				width: 70px;
				height: 40px;
				padding: 10px 0;
			`
			break
		}
		case 'large': {
			style = css`
				width: 290px;
				height: 40px;
				padding: 10px 0;
			`
			break
		}
		default: {
			style = css`
				width: 250px;
				height: 45px;
				padding: 12px 0;
			`
		}
	}

	return style
}

const generateStyle = (props) => {
	let style =
		props.theme === 'blue'
			? css`
					background-color: ${colors.blue};
					&:hover {
						background-color: ${colors.white};
						border: 1px solid ${colors.blue};
						color: ${colors.blue};
					}
			  `
			: css`
					background-color: ${colors.orange};
					&:hover {
						background-color: ${colors.white};
						border: 1px solid ${colors.orange};
						color: ${colors.orange};
					}
			  `
	return style
}

const ButtonStyle = styled.button`
	text-align: center;
	background-color: ${(p) =>
		p.theme === 'orange' ? colors.orange : colors.blue};
	color: ${colors.lightGrey};
	font-size: 16px;
	border: none;
	transition: 0.25s;
	cursor: pointer;
	outline: none;
	&:disabled {
		background-color: ${colors.lightGrey} !important;
		color: ${colors.grey} !important;
		border: none !important;
		cursor: wait;
	}
	${generateTheme}
	${generateStyle}
`

function Button({ theme, children, ...props }) {
	return (
		<ButtonStyle theme={theme} {...props}>
			{children}
		</ButtonStyle>
	)
}

export default Button
