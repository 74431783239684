import { colors, device } from 'components/variables'
import styled from 'styled-components'
import { textColor } from 'utils/help-func'

export const ProjectContainer = styled.div`
	margin: 0 auto;
	@media ${device.laptopS} {
		margin: 0 15px;
		&.marg-0 {
			width: 95%;
			margin: 0;
		}
		&.marg-0.n32 {
			margin: 0 auto;
		}
		&.marg-0.n33 {
			margin-left: auto;
		}
	}
`

export const ProjectWrapp = styled.div``

export const ProjectImgBlock = styled.div`
	height: 430px;
	cursor: pointer;
`

export const ProjectData = styled.div`
	padding: 0 5px;
`

export const ProjectDate = styled.p`
	color: ${colors.grey};
	font-size: 15px;
	padding: 15px 0;
`

export const DataTitle = styled.div`
	width: 100%;
	font-weight: bold;
	font-size: 13px;
	color: ${colors.black};
	display: flex;
	justify-content: space-between;
	${(props) => textColor({ defaultColor: 'black', ...props })}
	margin-bottom: 18px;
`

export const DataText = styled.span`
	min-width: 77px;
	height: ${({ height }) => height ? height : 'auto'};
	font-size: 15px;
	font-weight: 300;
	display: -webkit-box;
	-webkit-line-clamp: 4;
	-webkit-box-orient: vertical;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: pre-wrap;
	overflow-wrap: break-word;
	${(props) => textColor({ defaultColor: 'black', ...props })}
`

export const HashLine = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
`

export const HashText = styled.span`
	font-size: 15px;
	color: ${colors.orange};
	cursor: pointer;
	margin-right: 5px;
`

export const HashtagWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	margin-right: 10px;
`

export const ProjectImgWrapp = styled.div`
	width: 100%;
	height: 100%;
	.img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
`