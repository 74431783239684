import { createSlice } from '@reduxjs/toolkit'
import { CommunityModel } from 'models/community'

export * from './selectors'

const communitiesSlice = createSlice({
	name: 'communities',
	initialState: {
		communities: [],
		loading: false,
	},
	reducers: {
		getCommunities: (state) => {
			state.loading = true
		},
		getSuccess: (state, { payload }) => {
			state.communities = payload.data.map((item) => new CommunityModel(item))
			state.loading = false
		},
		getError: (state) => {
			state.loading = false
		},
		getEnd: (state) => {
			state.loading = false
		},
	},
})

export const { actions, reducer } = communitiesSlice
