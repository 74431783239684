import { createSlice } from '@reduxjs/toolkit'
import { CommunityModel } from 'models/community'

export * from './selectors'

const projectSlice = createSlice({
	name: 'community',
	initialState: {
		loading: false,
		community: new CommunityModel(),
	},
	reducers: {
		getCommunity: (state) => {
			state.loading = true
		},
		getSuccess: (state, { payload }) => {
			state.community = new CommunityModel(payload.community)
			state.loading = false
		},
		getError: (state) => {
			state.loading = false
		},
	},
})

export const { actions, reducer } = projectSlice
