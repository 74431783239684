import React, { memo, useEffect, useState } from 'react'
import { useTranslation, withTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { actions } from './slices'
import HeaderPhoto from 'components/HeaderPhoto'
import GenerBG from 'static/images/new_projects.png'
import HeeaderPath from 'components/HeaderPath/headerPath'
import { useLocation } from 'react-router-dom'

import { ProjectsWrapper, HeadWrapper } from '../Projects/style'
import CTA from 'components/CTA'
import ProrgamsItem from 'components/Program'
import Pagination from 'components/Pagination/pagination'

function Programs(props) {
  const {t} = useTranslation()
  const dispatch = useDispatch()
	const {programs,count} = useSelector(store => ({programs: store.programs?.programs, count:store?.programs?.count}))
console.log(programs,count)
	const location = useLocation()

	const [paginationObj, setPaginationObj] = useState({})
	const [nameObj, setNameObj] = useState({})
	const [hashtag, setHashtag] = useState(location.state?.detail || '')
	const [selected, setSelected] = useState(0)
	const [def_count, setDef_count] = useState(6)

	const links = [
		{ generalLink: '/create/project' },
		{ label: t('headerPath.home'), link: '/home' },
		{ label: t('header.programs'), link: '#' },
	]

	const nameCallBack = (name) => {
		setHashtag('')
    setNameObj({ name })
	}
  const handleCallBack = (obj) => {
		setSelected(obj.selected)
    setPaginationObj(obj);
  };

	useEffect(() => {
		let def = window.screen.width < 768 ? 6 : 18

		dispatch(actions.getPrograms({ skip: 0, }))
	}, [])

	useEffect(() => {
		dispatch(actions.getPrograms({  ...nameObj, ...paginationObj }))
	}, [paginationObj])

	useEffect(() => {
		setSelected(0)
		dispatch(actions.getPrograms(({ ...nameObj, hashtag, skip: 0 })))
	}, [ nameObj, hashtag])

	return (
		<ProjectsWrapper>
			<HeaderPhoto bg={GenerBG} label={t('header.programs')} />
			<HeadWrapper className='mt-6'>
				<HeeaderPath
					nameHash={hashtag}
					nameName={nameObj.name}
					plusLabel={'програми та документи'}
					searchBox
					links={links}
					hendleCallBack={nameCallBack}
				/>
			</HeadWrapper>

			<div className='container p-0'>
					<div className='row'>
      {programs?.map((item, _index)=> {
						const n32 = (_index + 2) % 3 === 0 ? 'n32' : ''
						const n33 = (_index + 1) % 3 === 0 ? 'n33' : ''
							return(
									<div key={_index} className='mb-8 col-12 col-md-6 col-lg-4'>
        		<ProrgamsItem title={item?.name} sub={item?.description} _id={item?._id}  />
						</div>
								)
							})}
						</div>
					</div>

			<CTA
				text={t('projectInfo.InterestedProjects')}
				buttonText={t('projectInfo.Invest')}
				link='contacts_form'
				className='mt-12'
			/>
		</ProjectsWrapper>
	)
}

export default Programs
