import { ProjectArrowW } from 'components/Icons'
import {
	FillImage,
	Line,
	SubtitleLarge,
	SupperImg,
	NoImage,
	NoImg,
} from 'components/Styled'
import React from 'react'
import { withTranslation } from 'react-i18next'
import history from 'utils/history'
import {
	DataText,
	ProjectContainer,
	ProjectData,
	ProjectDate,
	ProjectImgBlock,
	ProjectWrapp,
	ProjectImgWrapp,
} from '../Project/style.js'
import pdf from 'static/images/pdf.png'
import moment from 'moment';

function ProrgamsItem({t,title, sub,_id, ...props}) {
	const handleClick = () => {
		history.push(`/program/${_id}`)
	}

	return (
		<ProjectWrapp >
			<ProjectContainer >
				<ProjectImgBlock onClick={handleClick}>
					<ProjectImgWrapp>
							{/* <NoImage>
								<NoImg src={img1} alt='Project Image' />
							</NoImage> */}
							  <FillImage
                className='img'
               src={pdf}
                alt='Programs Image'
              />
					</ProjectImgWrapp>
				</ProjectImgBlock>
				<ProjectData>
					<SubtitleLarge
						onClick={handleClick}
						className='blockSlice cursor-pointer'
						weight='bold'
					>
					{title}
					</SubtitleLarge>
					<Line />
					<DataText height='60px'>{sub}</DataText>
				</ProjectData>
			</ProjectContainer>
		</ProjectWrapp>
	)
}

export default withTranslation()(ProrgamsItem)
