import React from 'react'
import Slider from 'react-slick'
import { CaruselWrapp } from './style'
import { SliderNext, SliderPrev } from 'components/Icons'
import ProjectItem from 'components/Project'
import history from 'utils/history'

const SlickButtonFix = ({ currentSlide, slideCount, children, ...props }) => (
	<span {...props}>{children}</span>
)

const settings = {
	dots: false,
	infinite: true,
	speed: 500,
	slidesToShow: 3,
	slidesToScroll: 3,
	adaptiveHeight: false,
	nextArrow: (
		<SlickButtonFix>
			<SliderNext />
		</SlickButtonFix>
	),
	prevArrow: (
		<SlickButtonFix>
			<SliderPrev />
		</SlickButtonFix>
	),
	centerMode: true,
	cssEase: 'linear',
	variableWidth: true,
	variableHeight: true,
	responsive: [
		{
			breakpoint: 1024,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 2,
				infinite: true,
				centerMode: false,
				cssEase: 'linear',
				variableWidth: false,
				variableHeight: false,
			},
		},
		{
			breakpoint: 640,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
				infinite: true,
				centerMode: false,
				cssEase: 'linear',
				variableWidth: false,
				variableHeight: false,
			},
		},
	],
}

export function ProjectsSlider(props) {
	const { projects, slidesToShow } = props
	const LocalSettings = {
		...settings,
		slidesToShow: slidesToShow,
		slidesToScroll: slidesToShow,
	}

	const hendleCallHashteg = (hashtag) => {
		history.push('/projects', { detail: hashtag })
	}

	return (
		<>
			<CaruselWrapp>
				<Slider {...LocalSettings}>
					{projects.map((item, _index) => (
						<div key={_index}>
							<div className='project-slide'></div>
							<ProjectItem item={item} hashtagCallBack={hendleCallHashteg} />
						</div>
					))}
				</Slider>
			</CaruselWrapp>
		</>
	)
}
