import { colors, device } from 'components/variables'
import styled, { css } from 'styled-components'
import { textColor } from 'utils/help-func'

export const FooterCompany = styled.div`
	${(props) =>
		textColor({ defaultColor: 'black', colorSpan: 'blue', color: props.color })}
	font-size: 15px;
	span {
		font-weight: bold;
	}
`

export const FootText = styled.span`
	display: none;
	font-weight: normal !important;
	color: ${colors.black} !important;
	@media ${device.tablet} {
		display: inline;
	}
`

export const MobileText = styled.span`
	display: inline;
	font-weight: normal !important;
	color: ${colors.black} !important;
	@media ${device.tablet} {
		display: none;
	}
`

// Main Footer ------------

export const FooterWrapper = styled.div`
	max-width: 1220px;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	padding: 0 10px;
	display: flex;
	align-items: center;
	justify-content: space-between;
`
// Footer Menu    -----------------------

// export const FooterMenu = styled.div

// Footer blocks  ------------------------

export const FooterBlock = styled.ul`
	width: 100%;
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	align-items: center;
	${(props) =>
		props.open &&
		css`
			color: ${colors.blue};
			.arrow {
				transform: rotate(180deg);
				path {
					fill: ${colors.blue};
				}
			}
			.subMenu {
				max-height: 120px;
				transition: max-height 0.25s ease-in;
			}
		`}
	@media ${device.tablet} {
		flex-direction: row;
		align-items: flex-start;
	}
`

export const ItemWrapp = styled.li`
	padding: 0 5px;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	@media ${device.tablet} {
		width: auto;
		align-items: flex-start;
	}
`

export const BlockItem = styled.div`
	a {
		font-weight: 500;
		font-size: 14px;
	}
	color: ${(props) => (props.open ? colors.blue : colors.white)};
	background: ${(props) => (props.open ? colors.white : colors.blue)};
	.arrow {
		transition: 0.25s;
		path {
			fill: ${(props) => (props.open ? colors.blue : colors.white)};
		}
		transform: ${(props) => (props.open ? 'rotate(180deg)' : 'rotate(0deg))')};
	}
	border: 1px solid ${colors.blue};
	padding: 15px;
	max-width: 290px;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 10px;
	@media ${device.tablet} {
		border: none;
		background: transparent;
		color: ${colors.black};
		padding: 0;
		max-width: none;
		width: auto;
		display: block;
		margin-top: 0;
	}
`

export const SubItems = styled.ul`
	margin-top: 0;
	max-width: 290px;
	width: 100%;
	margin-top: ${(props) => (props.open ? '10px' : '0px')};
	padding-left: 10px;
	transition: 0.25s;
	max-height: ${(props) => (props.open ? '120px' : '0px')};
	overflow: hidden;
	overflow-y: auto;
	&::-webkit-scrollbar {
		width: 20px;
	}
	&::-webkit-scrollbar-track {
		background: ${colors.darkGrey};
		border-left: 13.7px solid ${colors.white};
		border-right: 6.3px solid ${colors.white};
	}
	&::-webkit-scrollbar-thumb {
		background: ${colors.orange};
		border-left: 11px solid ${colors.white};
		border-right: 3px solid ${colors.white};
	}
	@media ${device.tablet} {
		margin-top: 21px;
		max-height: 120px;
		width: 100%;
		padding-left: 0;
		padding-top: 0;
	}
`

export const SubItem = styled.li`
	font-weight: normal;
	font-size: 13px;
	line-height: 16px;
	margin: 10px 0;
	display: flex;
	align-items: center;
	svg {
		margin-right: 10px;
	}
	&:first-child {
		margin-top: 0;
	}
`
