import React from 'react';
import { TextSmallPt, TitleLarge } from 'components/Styled';
// import pdf_file from 'static/pdf/sample_3.pdf'
import { pdfjs } from 'react-pdf';
import { useTranslation } from 'react-i18next';

import { AnimateHOC } from 'components/Animation';
import NumberRun from 'components/AnimateNumber';
import ReactHtmlParser from 'react-html-parser';
import {
  BarGraph,
  PieGraph,
  RadialBarComponent,
  NormalBar,
  HorizontalBar,
  LineGraph,
} from 'components/KPI';
import { colors } from 'components/variables';
import { Link } from 'react-router-dom';

import PdfComponent from 'components/ReaderPDF/test';
import { PDFDownloadLink } from '../style';

import environment from 'environment';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
// Example graph data -------------
// const dataPie = [
// 	{ name: 'Промисловість', value: 1665 },
// 	{ name: 'Сільське господарство', value: 419 },
// ]

// const dataGender = [
// 	{ name: '0-5', men: '-169', women: '163' },
// 	{ name: '6-10', men: '-290', women: '258' },
// ]

// const dataRadial = [
// 	{
// 		name: 'road',
// 		value: 41.25,
// 		fill: '#224085',
// 	},
// 	{
// 		name: 'road',
// 		value: 31.33,
// 		fill: '#40AE49',
// 	},
// 	{
// 		name: 'road',
// 		value: 27.42,
// 		fill: '#E46724',
// 	},
// 	{
// 		name: 'road',
// 		value: 100,
// 		fill: '#ffffff',
// 	},
// ]

const pieColors = [
  '#224085',
  '#EC1163',
  '#FCD406',
  '#40AE49',
  '#E46724',
  '#4289de',
];
const radialbarColors = ['#224085', '#40AE49', '#E46724'];
const barColors = [
  colors.blue,
  colors.orange,
  '#2bb71d',
  '#fdcc09',
  '#40AE49',
  '#E46724',
];

function transform(node, index) {
  if (node.type === 'tag' && node.name === 'animnumber') {
    return <NumberRun number={+node.children[0].data} />;
  }

  if (node.type === 'tag' && node.name === 'bargraph') {
    return (
      <AnimateHOC
        Component={BarGraph}
        colors={barColors}
        data={JSON.parse(node.children[0].data)}
      />
    );
  }

  if (node.type === 'tag' && node.name === 'piegraph') {
    return (
      <AnimateHOC
        Component={PieGraph}
        colors={pieColors}
        data={JSON.parse(node.children[0].data)}
        datakey={
          node.attribs['data-key'] ? node.attribs['data-key'].split(' ') : ''
        }
      />
    );
  }

  if (node.type === 'tag' && node.name === 'radialbar') {
    return (
      <AnimateHOC
        Component={RadialBarComponent}
        colors={radialbarColors}
        data={JSON.parse(node.children[0].data)}
      />
    );
  }

  if (node.type === 'tag' && node.name === 'normalbar') {
    return (
      <AnimateHOC
        Component={NormalBar}
        colors={barColors}
        data={JSON.parse(node.children[0].data)}
        datakey={
          node.attribs['data-key'] ? node.attribs['data-key'].split(' ') : ''
        }
        datasize={node.attribs['data-size'] ? node.attribs['data-size'] : '25'}
        dataheight={
          node.attribs['data-height'] ? node.attribs['data-height'] : '570'
        }
        datastack={
          node.attribs['data-stack'] ? node.attribs['data-stack'] : '1'
        }
        dataYwidth={
          node.attribs['data-ywidth'] ? node.attribs['data-ywidth'] : '60'
        }
      />
    );
  }
  if (node.type === 'tag' && node.name === 'horizontalbar') {
    return (
      <AnimateHOC
        Component={HorizontalBar}
        colors={barColors}
        data={JSON.parse(node.children[0].data)}
        datakey={
          node.attribs['data-key'] ? node.attribs['data-key'].split(' ') : ''
        }
        dataheight={
          node.attribs['data-height'] ? node.attribs['data-height'] : '570'
        }
        datastack={
          node.attribs['data-stack'] ? node.attribs['data-stack'] : '1'
        }
      />
    );
  }
  if (node.type === 'tag' && node.name === 'linechart') {
    return (
      <AnimateHOC
        Component={LineGraph}
        colors={barColors}
        data={JSON.parse(node.children[0].data)}
        datakey={
          node.attribs['data-key'] ? node.attribs['data-key'].split(' ') : ''
        }
        dataheight={
          node.attribs['data-height'] ? node.attribs['data-height'] : '570'
        }
      />
    );
  }
}

const options = {
  decodeEntities: true,
  transform,
};

function Info({ title, pdf, match, content,details,description, ...props }) {
  const { t } = useTranslation();
  
  return (
    <>
      <TitleLarge className='text-center mb-3'>{title}</TitleLarge>
     {description && <TextSmallPt>{description}</TextSmallPt>}
      {!!pdf ? (
        pdf.map((item, _index) => (
          <React.Fragment key={_index}>
            <PdfComponent
              file={`${environment.staticUrl}docs/${item}`}
              className='text-center mt-5'
            />
            <PDFDownloadLink
              href={`${environment.staticUrl}docs/${item}`}
              download
              className='mt-3'
            >
              {t('downloadDocument.title')}
            </PDFDownloadLink>
          </React.Fragment>
        ))
      ) : (
        <TitleLarge weight='normal' className='mt-8'>
          No File
        </TitleLarge>
      )}
      {!!content && <div>{ReactHtmlParser(content, options)}</div>}
     {!details && <Link to={`${match.url}/profile`} className='d-block text-center mt-5'>
        {t(`sideBar.Details`)}
      </Link>}
    </>
  );
}

export default Info;
