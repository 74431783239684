import { createSlice } from '@reduxjs/toolkit'
import { NewsModel } from 'models/newsItem'

export * from './selectors'

const newItemReducer = createSlice({
	name: 'newItem',
	initialState: {
		newItem: new NewsModel(),
		loading: false,
	},
	reducers: {
		getNewItem: (state) => {
			state.loading = true
		},
		getSuccess: (state, { payload }) => {
			state.newItem = new NewsModel(payload.data)
			state.loading = false
		},
		getError: (state) => {
			state.loading = false
		},
	},
})

export const { actions, reducer } = newItemReducer
