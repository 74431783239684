import { createSlice } from '@reduxjs/toolkit'
import { HistoryItem } from 'models/historyItem'

export * from './selectors'

const HistoryItemReducer = createSlice({
	name: 'historyItem',
	initialState: {
		historyItem: {},
		loading: false,
	},
	reducers: {
		getHistoryItem: (state) => {
			state.loading = true
		},
		getSuccess: (state, { payload }) => {
			state.historyItem = new HistoryItem(payload.data)
			state.loading = false
		},
		getError: (state) => {
			state.loading = false
		},
	},
})

export const { actions, reducer } = HistoryItemReducer
