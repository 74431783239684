import { call, put, takeLatest } from '@redux-saga/core/effects'
import { actions } from 'containers/slices/user'
import Api from 'utils/api'

function* getUser() {
	try {
		const data = yield call(Api.get, '/api/admins/getById')
		yield put({
			...actions.getUserSuccess({ data: data.admin }),
		})
	} catch (error) {
		yield put({ ...actions.getUserError() })
	}
}

export function* userSaga() {
	yield takeLatest(actions.getUserStart, getUser)
}
