import { createSlice } from '@reduxjs/toolkit'
import { BranchModel } from 'models/branch'

export * from './selectors'

const branchSlice = createSlice({
	name: 'branch',
	initialState: {
		branch: new BranchModel(),
		loading: false,
	},
	reducers: {
		getSingleStart: (state) => {
			state.loading = true
		},
		getSingleError: (state) => {
			state.loading = false
		},
		getSingleSuccess: (state, { payload }) => {
			state.branch = new BranchModel(payload.data)
			state.loading = false
		},
	},
})

export const { actions, reducer } = branchSlice
