import styled from 'styled-components'
import { colors, device } from 'components/variables'

export const Message = styled.h2`
	font-weight: bold;
	padding: 0 15px;
	font-size: 30px;
	line-height: 37px;
	text-align: center;
	max-width: 290px;
	margin-bottom: 45px;
	@media ${device.tabletM} {
		font-size: 36px;
		line-height: 44px;
	}
	@media ${device.mobileXL} {
		max-width: 551px;
	}
`

export const ToHomeBtn = styled.div`
	width: 250px;
	padding: 12px 0;
	text-align: center;
	background-color: ${colors.blue};
	font-weight: bold;
	font-size: 16px;
	line-height: 21px;
	color: ${colors.white};
`
