import environment from 'environment'
export class CommunityModel {
	constructor(data = {}) {
		this.info = {}
		if (!!data.info) {
			const temp = data.info.filter((obj) => !!obj)
			temp.map((item) => {
				if (!item?.name) {
					return false
				} else if (item?.name === 'Профіль громади') {
					this.info.profile = `${environment.staticUrl}docs/${item.file}`
				} else if (item?.name === 'Якість води') {
					this.info.water = `${environment.staticUrl}docs/${item.file}`
				} else if (item?.name === 'Інвестиційний паспорт громади') {
					this.info.main = `${environment.staticUrl}docs/${item.file}`
				} else if (item?.name === 'Сценарії розвитку') {
					this.info.scenario = `${environment.staticUrl}docs/${item.file}`
				} else if (item?.name === 'Реорганізація вугільних підприємств') {
					this.info.reorganize = `${environment.staticUrl}docs/${item.file}`
				} else {
					return false
				}
				return true
			})
		}
		this.tabs = data?.tabs?.filter((obj) => !!obj) || []
		this._id = data?._id || ''
		this.name = data?.name || ''
		this.square = data?.square || ''
		this.workers = data?.workers || ''
		this.mainphoto = data?.mainphoto
			? `${environment.staticUrl}images/${data.mainphoto}`
			: ''
		this.map = data?.map || ''
		this.location = data?.location || {}
		this.contacts = convertArrayToObject(data?.contacts || [], 'name')
		this.polygon = data?.polygon ? reversePolygon(data?.polygon) : ``
	}
}

const convertArrayToObject = (array, key) => {
	const initialValue = {}
	const temp = array.filter((obj) => !!obj)
	return temp.reduce((obj, item) => {
		return {
			...obj,
			[item[key]]: item,
		}
	}, initialValue)
}

const reversePolygon = (array) => {
	return array.map((point) => {
		return point.reverse()
	})
}
