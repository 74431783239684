import { createSlice } from '@reduxjs/toolkit'

const programReducer = createSlice({
	name: 'Program',
	initialState: {
		program: {},
		loading: false,
	},
	reducers: {
		getProgramItem: (state) => {
			state.loading = true
		},
		getSuccess: (state, { payload }) => {
			state.program = payload.data
			state.loading = false
		},
		getError: (state) => {
			state.loading = false
		},
	},
})

export const { actions, reducer } = programReducer
