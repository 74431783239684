import {
  Col,
  Container,
  FillImage,
  Row,
  SubtitleLarge,
  CommunityImg,
  SupperImg,
  NoImage,
  NoImg,
} from 'components/Styled';
import { BranchImage, BranchWrapp } from 'containers/Branches/style';
import React, { memo } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { select } from './slices';
import bg from 'static/images/new_community.png';
import HeaderPhoto from 'components/HeaderPhoto';
import HeeaderPath from 'components/HeaderPath/headerPath';
import { withTranslation } from 'react-i18next';
import img1 from 'static/images/NonImgColor.png';

import { NoData } from 'components/Styled';

function Communities({ t, communities, ...props }) {
  const links = [
    { generalLink: '#' },
    { label: t('headerPath.home'), link: '/home' },
    { label: t('headerPath.communities'), link: '#' },
  ];
  const dataDone = communities.length ? false : true;
  return (
    <>
      <HeaderPhoto text='' label='Громади' bg={bg} />
      {dataDone ? (
        <NoData>громади не найдено</NoData>
      ) : (
        <Container className='mt-6 container'>
          <HeeaderPath searchBox links={links} />
          <Row className='row'>
            {communities.map((item, _index) => {
              return (
                <Col key={_index} className='col-12 col-sm-6 col-lg-4 mt-6'>
                  {console.warn(item)}
                  <Link to={`/community/${item._id}`}>
                    <BranchWrapp>
                      <BranchImage>
                        <CommunityImg>
                          <SupperImg>
                            {item.mainphoto ? (
                              <FillImage
                                className='img'
                                src={item.mainphoto}
                                alt='Project Image'
                              />
                            ) : (
                              <NoImage>
                                <NoImg src={img1} alt='Project Image' />
                              </NoImage>
                            )}
                          </SupperImg>
                        </CommunityImg>
                      </BranchImage>
                      <SubtitleLarge
                        weight='bold'
                        className='text text-center mt-2'
                      >
                        {item.name}
                      </SubtitleLarge>
                    </BranchWrapp>
                  </Link>
                </Col>
              );
            })}
          </Row>
        </Container>
      )}
    </>
  );
}

const mapStateToPtops = createStructuredSelector({
  communities: select.communities,
});

const withConnect = connect(mapStateToPtops, {});

export default compose(withConnect, memo, withTranslation())(Communities);
