import { call, put, takeLatest } from '@redux-saga/core/effects'
import { push } from 'connected-react-router'
import Api from 'utils/api'
import { setToken } from 'utils/auth'
import { delay } from 'utils/help-func'
import { actions } from './slices'

function* loginWorker({ payload }) {
	const { email, password } = payload
	try {
		const body = {
			email,
			password,
		}
		const data = yield call(Api.post, '/api/admins/login/email', body)
		setToken(data.token)
		yield put({ ...actions.loginSuccess() })
		yield put(push('/home'))
	} catch (e) {
		yield put({ ...actions.loginError({ error: 'SOme EROOR' }) })
	}
}

function* registerWorker({ payload }) {
	const { name, email, phone, password, community, code } = payload
	const body = {
		name,
		email,
		phoneNumber: phone,
		password,
		community: community.value,
		code,
	}
	try {
		yield call(Api.post, '/api/admins/register', body)
		yield put({ ...actions.registerSuccess() })
		yield call(loginWorker, { payload })
	} catch (e) {
		console.error(e)
	}
}

function* resetWorker({ payload }) {
	try {
		yield call(delay, 2000)
		yield put({ ...actions.registerSuccess() })
		yield put(push('/auth/signin'))
	} catch (e) {
		yield put({ ...actions.resetError({ error: 'SOme EROOR' }) })
	}
}

function* checkWorker({ payload }) {
	// const body = { code: payload.code }
	try {
		// const data = yield call(Api.post, 'api/admins/isCodeValid', body)
		yield put({ ...actions.checkSuccess() })
	} catch (e) {
		yield put({ ...actions.checkError() })
		yield put(push('/error'))
	}
}

function* questionWorker({ payload }) {
	try {
		yield call(delay, 2000)
		yield put({ ...actions.questionSuccess() })
	} catch (e) {
		yield put({ ...actions.questionError() })
	}
}

export function* authSaga() {
	yield takeLatest(actions.loginStart, loginWorker)
	yield takeLatest(actions.registerStart, registerWorker)
	yield takeLatest(actions.resetStart, resetWorker)
	yield takeLatest(actions.checkStart, checkWorker)
	yield takeLatest(actions.questionStart, questionWorker)
}
