import { ProjectsSearchLoopInp } from './style'
import React, { useEffect, useState } from 'react'

const SearchInp = ({ handelCoolbackSubmit, inputValue, ...props }) => {
	const [value, setValue] = useState('')
	useEffect(() => {
		setValue(inputValue)
	}, [inputValue])
	const inputChange = (e) => {
		setValue(e.target.value)
	}
	const inputSubmit = (e) => {
		handelCoolbackSubmit(value)
		e.preventDefault()
	}
	const onBlur = () => {
		handelCoolbackSubmit(value)
		props.changeVisibleSearch()
	}
	return (
		<form onSubmit={inputSubmit}>
			<ProjectsSearchLoopInp
				placeholder='Пошук'
				onBlur={onBlur}
				onChange={inputChange}
				value={value}
			/>
		</form>
	)
}

export default SearchInp
