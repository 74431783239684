import React from 'react'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'
import { shallowEqual, useSelector } from 'react-redux'
import Registration from './Registration'
import Login from './Login'
import Reset from './Reset'
import MailSuccess from 'containers/MailSuccess/MailSuccess'

function Auth(props) {
	let match = useRouteMatch()

	const { isAuth } = useSelector((state) => {
		return {
			isAuth: state.auth.isAuth,
		}
	}, shallowEqual)

	const path = {
		auth: `${match.path}`,
		login: `${match.path}/signin`,
		signup: `${match.path}/signup`,
		reset: `${match.path}/reset`,
		mailSuccess: `${match.path}/mail-success`,
	}

	if (isAuth) {
		return <Redirect to='/' />
	}

	return (
		<Switch>
			<Route
				exact
				path={path.auth}
				render={() => <Redirect to={path.login} />}
			/>
			<Route exact path={path.login} component={Login} />
			<Route exact path={path.signup} component={Registration} />
			<Route exact path={path.reset} component={Reset} />
			<Route exact path={path.mailSuccess} component={MailSuccess} />
			{/* <Route exact path={path.check} component={Check} /> */}
		</Switch>
	)
}

export default Auth
