import { useState } from 'react'
import { InView, useInView } from 'react-intersection-observer'

const ScrollWrapper = ({ children, ...props }) => (
	<span className='scroll' {...props}>
		{children}
	</span>
)

export const AnimateHOC = ({
	Component,
	handle_cb = () => {},
	number = 0,
	data = [],
	colors,
	...props
}) => {
	const [pause, setPause] = useState(true)
	const [, inView] = useInView({
		threshold: 0,
	})

	const handleChange = (inView) => {
		setPause(!inView)
		inView && handle_cb()
	}
	return (
		<ScrollWrapper inView={inView}>
			<InView delay={800} as='span' onChange={(inView) => handleChange(inView)}>
				<Component pause={pause} number={number} data={data} colors={colors} 
				datakey={props.datakey} datasize={props.datasize}  dataheight={props.dataheight} datastack={props.datastack} dataYwidth={props.dataYwidth}/>
			</InView>
		</ScrollWrapper>
	)
}
