import {
  HeaderArrow,
  HeaderLang,
  HeaderUser,
  LogoIcon,
} from 'components/Icons';
import { select as authSelect } from 'containers/Auth/slices';
import { select as branchesSelect } from 'containers/Branches/slices';
import { select as communitiesSelect } from 'containers/Communities/slices';
import i18n from 'i18n';
import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import {
  HeadreContainer,
  Menu,
  MenuItem,
  SubMenu,
  SubItem,
  HeaderLogin,
  Language,
  ThirdBlock,
  LangList,
  ListItem,
  CurrentLang,
  HeaderWrapper,
  LogoWrapp,
  Burger,
  DiV,
} from './style';

import { GlobalStyle } from 'components/Styled';

function Header({ t, isAuth, communities, branches, ...props }) {
  const currentLang = i18n.language.toUpperCase();
  const [open, setOpen] = useState(false);

  const branchesFormatMenu = branches.map(({ name, _id }) => {
    return { label: name, value: _id, _id: _id };
  });
  const communitiesFormatMenu = communities.map(({ name, _id }) => {
    return { label: name, value: _id, _id: _id };
  });

  const menu = [
    {
      label: 'communities',
      link: '/communities',
      subLink: '/community',
      subMenu: communitiesFormatMenu,
    },
    {
      label: 'subInvest3',
      link: '/histories',
      // subLink: '/branch',
      // subMenu: branchesFormatMenu,
    },
    {
      label: 'investors',
      link: '/projects',
      mode: 'large',
      subMenu: [
        { label: 'header.subInvest1', link: '/projects' },
        { label: 'header.programs', link: '/programs' },
        // { label: 'Комерційні пропозиції', link: '/propositions' }, Release v.0.0.1
        // { label: 'Історії успіху', link: '/histories' }, Release v.0.0.1
      ],
    },
    {
      label: 'news',
      link: '/news',
    },
    {
      label: 'contacts',
      link: '/contacts',
    },
  ];

  const changeLanguage = (lang) => () => {
    i18n.changeLanguage(lang);
  };
  const handleOpen = () => setOpen(!open);
  const handleClose = () => setOpen(false);

  return (
    <>
      <GlobalStyle open={open} />
      <HeaderWrapper>
        <LogoWrapp>
          <Link to='/home' className='d-block'>
            <LogoIcon />
          </Link>
        </LogoWrapp>
        <DiV open={open} onClick={handleOpen}>
          <Burger open={open} />
        </DiV>
        <HeadreContainer open={open}>
          <Link to='/home'>
            <LogoIcon onClick={handleClose} />
          </Link>
          <Menu>
            {menu.map((item, _index) => {
              const hasSub = !!item.subMenu;
              return (
                <MenuItem mode={item.mode} key={_index}>
                  <Link to={item.link} onClick={handleClose}>
                    {t(`header.${item.label}`)}
                  </Link>
                  {hasSub && <HeaderArrow className='arrow' />}
                  {hasSub && (
                    <SubMenu
                      large={item.subMenu.length > 3}
                      className='subMenu'
                    >
                      {item.subMenu.map((element, _index) => (
                        <Link
                          to={
                            item.subLink
                              ? `${item.subLink}/${element._id || element.link}`
                              : element.link
                          }
                          key={_index}
                          onClick={handleClose}
                        >
                          <SubItem>{t(element.label)}</SubItem>
                        </Link>
                      ))}
                    </SubMenu>
                  )}
                </MenuItem>
              );
            })}
          </Menu>
          <ThirdBlock>
            {!isAuth && (
              <HeaderLogin>
                <HeaderUser />
                <Link to='/auth/signin' onClick={handleClose}>
                  {t('header.login')}
                </Link>
              </HeaderLogin>
            )}
            <Language>
              <HeaderLang className='langicon' />
              <CurrentLang active={true}>
                {i18n.language.toUpperCase()}
              </CurrentLang>
              <LangList className='list'>
                {currentLang !== 'EN' && (
                  <ListItem onClick={changeLanguage('en')}>EN</ListItem>
                )}
                {currentLang !== 'UK' && (
                  <ListItem onClick={changeLanguage('uk')}>UK</ListItem>
                )}
                {currentLang !== 'RU' && (
                  <ListItem onClick={changeLanguage('ru')}>RU</ListItem>
                )}
              </LangList>
              <HeaderArrow className='arrow' />
            </Language>
          </ThirdBlock>
        </HeadreContainer>
      </HeaderWrapper>
    </>
  );
}

const mapStateToProps = createStructuredSelector({
  isAuth: authSelect.isAuth,
  communities: communitiesSelect.communities,
  branches: branchesSelect.branches,
});

const withConnect = connect(mapStateToProps, {});

export default compose(withConnect, withTranslation())(Header);
