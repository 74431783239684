import React, { memo, useEffect } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import {
	actions as newsActions,
	select as newsSelect,
} from 'containers/News/slices'
import { withTranslation } from 'react-i18next'
import HeaderPath from 'components/HeaderPath/headerPath'
import { HashText, DataText } from 'components/Project/style'
import NewsSlider from 'components/Slider/news'
import { createStructuredSelector } from 'reselect'
import { actions as newItemActions, select as newItemSelect } from './slices'
import GenerBG from 'static/images/new_news.png'

import {
	NewsWrapper,
	NewWrapper,
	NewsContentWrapper,
	WraperrSlider,
	NewsModelWrapper,
} from './style'

import { TitleLarge } from 'components/Styled'
import WithImageHead from 'components/HeaderPhoto/with-image'
import { useParams } from 'react-router'
import Loader from 'components/Loader'
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView'
import history from 'utils/history'

function NewsContent({ newItem, news, hendleCallHashteg, t }) {
	const { date, hashtag, content } = newItem
	return (
		<NewsContentWrapper>
			<div className='d-flex justify-content-between aligin-items-center mb-4'>
				<DataText color='grey'>{date}</DataText>
				<div>
					{hashtag.map((item, _index) => (
						<HashText
							onClick={() => hendleCallHashteg(item.value)}
							key={_index}
						>
							{item.label}
						</HashText>
					))}
				</div>
			</div>
			<NewsModelWrapper>
				<FroalaEditorView model={content} />
			</NewsModelWrapper>
			<TitleLarge className='mt-12 mb-8'>{t('newItem.sliderTitle')}</TitleLarge>
			<WraperrSlider>
				<NewsSlider news={news} />
			</WraperrSlider>
		</NewsContentWrapper>
	)
}

function New(props) {
	const {
		getNewItemWorker,
		getNewsWorker,
		news,
		newItem,
		t,
		loading_news,
		loading,
	} = props
	const { image, name } = newItem
	let { id } = useParams()
	const links = [
		{ generalLink: '/create/news' },
		{ label: t('headerPath.home'), link: '/home' },
		{ label: t('headerPath.news'), link: '/news' },
		{ label: name, link: '#' },
	]

	const hendleCallHashteg = (hashtag) => {
		history.push('/news', { detail: hashtag })
	}

	useEffect(() => {
		getNewItemWorker({ _id: id })
		getNewsWorker({ limit: 4, skip: 0 })
	}, [id])
	return loading || loading_news ? (
		<Loader />
	) : (
		<NewsWrapper>
			<WithImageHead label={name} image={image} bg={GenerBG} />
			<NewWrapper>
				<HeaderPath plusLabel={'новину'} admin links={links} />
				<NewsContent
					t={t}
					news={news}
					newItem={newItem}
					hendleCallHashteg={hendleCallHashteg}
				/>
			</NewWrapper>
		</NewsWrapper>
	)
}

const mapStateToProps = createStructuredSelector({
	loading: newItemSelect.loading,
	loading_news: newsSelect.loading,
	newItem: newItemSelect.newItem,
	news: newsSelect.news,
})

const withConnect = connect(mapStateToProps, {
	getNewItemWorker: newItemActions.getNewItem,
	getNewsWorker: newsActions.getNews,
})

export default compose(withConnect, memo, withTranslation())(New)
