import React from 'react'
import Slider from 'react-slick'
import { SliderNext, SliderPrev } from 'components/Icons'
import { NewsSliderWrapp } from './style'
import { /*Col,*/ FillImage, /*Row,*/ SubtitleLarge } from 'components/Styled'
import { Link } from 'react-router-dom'
import { BranchImage } from 'containers/Branches/style'
import styled from 'styled-components'
import { colors, device } from 'components/variables'

const SlickButtonFix = ({ currentSlide, slideCount, children, ...props }) => (
	<span {...props}>{children}</span>
)

const settings = {
	dots: false,
	infinite: true,
	speed: 800,
	slidesToShow: 3,
	slidesToScroll: 3,
	adaptiveHeight: true,
	nextArrow: (
		<SlickButtonFix>
			<SliderNext />
		</SlickButtonFix>
	),
	prevArrow: (
		<SlickButtonFix>
			<SliderPrev />
		</SlickButtonFix>
	),
	responsive: [
		{
			breakpoint: 940,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 2,
				infinite: true,
			},
		},
		{
			breakpoint: 580,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
				infinite: true,
			},
		},
	],
}

export const BranchWrappSlide = styled.div`
	cursor: pointer;
	width: 100%;
	&:hover .text {
		color: ${colors.blue};
	}
	@media ${device.laptopS} {
		max-width: 370px;
		margin: 0 auto;
	}
`

const BranchesSliderWrapp = styled(NewsSliderWrapp)`
	.slick-list {
		max-width: 1170px;
		margin: 0 auto;
	}
	.slick-slide {
		padding: 0 15px;
	}
	@media ${device.tablet} {
		.slick-arrow {
			bottom: -28%;
		}
	}
`

function BranchesSlider({ branches, ...props }) {
	return (
		<BranchesSliderWrapp {...props}>
			<Slider {...settings}>
				{/* <Row className='row'> */}
				{branches.map((item, _index) => (
					<div key={_index}>
						<Link to={`/branch/${item._id}`} key={_index}>
							<BranchWrappSlide>
								<BranchImage>
									<FillImage src={item.image} alt='Branch img' />
								</BranchImage>
								<SubtitleLarge weight='bold' className='text text-center mt-2'>
									{item.label}
								</SubtitleLarge>
							</BranchWrappSlide>
						</Link>
					</div>
				))}
				{/* </Row> */}
			</Slider>
		</BranchesSliderWrapp>
	)
}

export default BranchesSlider
