import React from 'react'
import ReactPaginate from 'react-paginate'
import styled from 'styled-components'
import { PaginationArrow } from 'components/Icons'
import { colors } from 'components/variables'

const PaginationWrapp = styled.div`
	float: right;
	.content {
		display: flex;
	}
	.prev,
	.page,
	.next,
	.break {
		cursor: pointer;
		width: 24px;
		font-weight: normal;
		margin-left: 15px;
		font-size: 16px;
		line-height: 22px;
		text-align: center;
		a {
			display: inline-block;
			width: 100%;
			height: 100%;
		}
	}
	.page {
		color: ${colors.white};
		border: 1px solid ${colors.blue};
		background-color: ${colors.blue};
	}
	.prev,
	.next {
		border: 1px solid transparent;
		background-color: transparent;
		position: relative;
		a {
			.arrows {
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%);
				height: 14px;
			}
		}
	}
	.disabled {
		.arrows {
			path {
				fill: ${colors.arrowDis};
			}
		}
	}
	.break {
		color: ${colors.blue};
		border: 1px solid transparent;
		background-color: transparent;
		position: relative;
		letter-spacing: 1px;
		a {
			position: absolute;
			bottom: -5px;
			left: 50%;
			transform: translate(-50%);
		}
	}
	.next {
		transform: rotateZ(180deg);
	}
	.selected {
		background-color: ${colors.white};
		color: ${colors.blue};
	}
`

function Pagination({ pageCount = 10, callBack, sel, ...props }) {
	const Change = (data) => {
		callBack(data)
	}
	return (
		<PaginationWrapp>
			<ReactPaginate
				previousLabel={<PaginationArrow className='arrows' />}
				nextLabel={<PaginationArrow className='arrows' />}
				previousClassName='prev'
				nextClassName='next'
				containerClassName='content'
				pageClassName='page'
				pageCount={pageCount}
				pageRangeDisplayed={2}
				marginPagesDisplayed={1}
				onPageChange={(data) => Change(data)}
				forcePage={sel}
			/>
		</PaginationWrapp>
	)
}

export default Pagination
