import { createSlice } from '@reduxjs/toolkit'
import { NewsModel } from 'models/newsItem'

export * from './selectors'

const newsReducer = createSlice({
	name: 'news',
	initialState: {
		news: [],
		count: 0,
		loading: false,
	},
	reducers: {
		getNews: (state) => {
			state.loading = true
		},
		getSuccess: (state, { payload }) => {
			state.news = payload.data.articles.map((item) => new NewsModel(item))
			state.count = payload.data.count
			state.loading = false
		},
		getError: (state) => {
			state.loading = false
		},
		createNews: (state) => {
			state.loading = true
		},
		createNewsEnd: (state) => {
			state.loading = false
		},
	},
})

export const { actions, reducer } = newsReducer
