import Loader from 'components/Loader';
import { Line, TitleLarge } from 'components/Styled';
import { ProjectWrapper } from 'containers/Project/style';
import React, { memo, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useParams, useRouteMatch, useLocation } from 'react-router';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import history from 'utils/history';
import bg from 'static/images/new_community.png';
import WithImageHead from 'components/HeaderPhoto/with-image';
import { actions, select as projectsSelect } from 'containers/Projects/slices';
import {
  actions as communityActions,
  select as communitySelect,
} from './slices';
import {
  actions as propositionsActions,
  select as propositionsSelect,
} from 'containers/Propisitions/slices';
import { ProjectsDesktop, ProjectsMobile } from 'containers/Home/style';
// import { PropositionSlider } from 'components/Slider/proposition' Release v.0.0.1
import ProjectItem from 'components/Project';
import { Link } from 'react-router-dom';
import Button from 'components/Buttons';
import MarkerMap from 'components/Map/markerMap';
import CTA from 'components/CTA';
import { ProjectsSlider } from 'components/Slider/projects';
import HeeaderPath from 'components/HeaderPath/headerPath';
import SideBar from './components/sidebar';
import Routing from './components/routing';

const tabsWater = [
  { link: '/profile', name: 'profile', label: 'Профіль громади' },
  { link: '/passport', name: 'passport', label: 'Інвестиційний паспорт' },
  { link: '/water', name: 'water', label: 'Якість води' },
];

function Community(props) {
  const {
    community,
    loading_com,
    loading_pr,
    getCommunity,
    getProjects,
    projects,
    // propositions, Release v.0.0.1
    t,
  } = props;

  const { name, mainphoto, location, info, tabs } = community;
  console.log(community, 'cominity');
  let match = useRouteMatch();
  let { id } = useParams();
  const [selected, setselected] = useState(
    info.profile ? tabsWater[0] : info.main ? tabsWater[1] : tabsWater[1]
  );

  const hanleSetSelected = (item) => () => {
    setselected(item);
    history.push(match.url + '/' + item.name);
  };

  const links = [
    { generalLink: '#' },
    { label: t('headerPath.home'), link: '/home' },
    { label: t('headerPath.communities'), link: '/communities' },
    { label: name, link: '#' },
  ];

  useEffect(() => {
    getCommunity({ id });
    getProjects({ community: id });
  }, [id]);
  useEffect(() => {
    setselected(
      info.profile ? tabsWater[0] : info.main ? tabsWater[1] : tabsWater[1]
    );
  }, [info]);
  return (
    <>
      {loading_com || loading_pr ? (
        <Loader />
      ) : (
        <>
          <WithImageHead label={name} text='' bg={bg} image={mainphoto} />
          <div className='container mt-6'>
            <HeeaderPath className='mb-6' links={links} />
            <ProjectWrapper className='row justify-content-center'>
              <SideBar
                info={info}
                selected={selected}
                hanleSetSelected={hanleSetSelected}
                tabs={tabs}
              />
              <Line className='col-12 col-sm-9 d-md-none mt-6 mb-0 mx-auto pl-4' />
              <Routing
                tabs={tabs}
                match={match}
                info={info}
                selected={selected}
              />
            </ProjectWrapper>
          </div>
          <TitleLarge className='mt-12'>{t('projectInfo.prod')}</TitleLarge>
          <div className='w-100'>
            {projects.length > 0 ? (
              <>
                <ProjectsDesktop>
                  <ProjectsSlider
                    projects={projects}
                    slidesToShow={projects.length < 3 ? 1 : 3}
                  />
                </ProjectsDesktop>
                <ProjectsMobile>
                  {projects.slice(0, 3).map((item, _index) => (
                    <ProjectItem key={_index} className='mt-7' item={item} />
                  ))}
                </ProjectsMobile>
              </>
            ) : (
              <TitleLarge className='mt-6'>No Projects</TitleLarge>
            )}
          </div>
          <div className='w-100 text-center mt-9'>
            <Link to='/projects'>
              <Button theme='blue'>{t('button.allProjects')}</Button>
            </Link>
          </div>
          <TitleLarge className='mt-12'>
            {t('projectInfo.InteractiveMap')}
          </TitleLarge>
          <div className='w-100'>
            <MarkerMap isMarkerShown coordinates={location.coordinates} />
          </div>
          {/* Release v.0.0.1 */}
          {/* <TitleLarge className='mt-12'>
						Комерційні пропозиції цієї громади
					</TitleLarge>
					<ProjectsDesktop className='mt-8'>
						<PropositionSlider projects={propositions} slidesToShow={4} />
					</ProjectsDesktop>
					<ProjectsMobile>
						{projects.slice(0, 3).map((item, _index) => (
							<ProjectItem key={_index} className='mt-7' item={item} />
						))}
					</ProjectsMobile>
					<div className='w-100 text-center mt-9'>
						<Link to='/propositions'>
							<Button theme='blue'>{t('button.allPropositions')}</Button>
						</Link>
					</div> */}
          <CTA
            text={t('projectInfo.InterestedProjects')}
            buttonText={t('projectInfo.Invest')}
            link='contacts_form'
            className='mt-12'
          />
        </>
      )}
    </>
  );
}

const mapStateToProps = createStructuredSelector({
  loading_com: communitySelect.loading,
  loading_pr: projectsSelect.loading,
  loading_por: propositionsSelect.loading,
  community: communitySelect.community,
  projects: projectsSelect.projects,
  propositions: propositionsSelect.propositions,
});

const withConnect = connect(mapStateToProps, {
  getCommunity: communityActions.getCommunity,
  getProjects: actions.getProjectsStart,
  getPropositionsStart: propositionsActions.getPropositionsStart,
});

export default compose(withConnect, memo, withTranslation())(Community);
