import styled from 'styled-components'

export const AuthHeader = styled.div`
	display: flex;
	justify-content: center;
	padding-top: 50px;
	margin: 0 auto;
	width: 100%;
`

export const AuthContent = styled.div`
	flex: 1 0 auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
`

export const AuthFooter = styled.div`
	width: 100%;
	text-align: center;
	padding-bottom: 50px;
`
