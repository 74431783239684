import React, { memo } from 'react'
import CreateForm from 'components/Form/createItem'
import { toArrayFunc } from 'utils/help-func'
import { ProjectProperty } from 'models/project'
import { createStructuredSelector } from 'reselect'
import { actions, select } from 'containers/Project/slices'
import { connect } from 'react-redux'
import { compose } from 'redux'
import Loader from 'components/Loader'

function CreateProject({ createProjectWorker, loading, ...props }) {
	const properties = toArrayFunc(new ProjectProperty())

	const handleSubmit = (data) => {
		// createProjectWorker(data)
	}

	return (
		<>
			{loading ? (
				<Loader />
			) : (
				<CreateForm
					isProperties
					properties={properties}
					isTypes
					types={[
						{ label: 'Грінфілд', value: 'greenfield' },
						{ label: 'Браунфілд', value: 'brownfield' },
					]}
					input_label_1='Назва проекту:'
					handleSubmit={handleSubmit}
					typeOfItem='project'
				/>
			)}
		</>
	)
}

const mapStateToProps = createStructuredSelector({
	loading: select.loading,
})

const withConnect = connect(mapStateToProps, {
	createProjectWorker: actions.postProject,
})

export default compose(withConnect, memo)(CreateProject)
