import { call, put, takeLatest } from 'redux-saga/effects';
import Api from 'utils/api';
import { actions } from './slices';
import i18n from 'i18n';

function* getHistoriesSecWorker(payload) {
  const body = {
    filter: {
      name: '',
      type: 'successStory',
      hashtag: '',
      limit: 10,
      skip: 0,
      language: i18n.language,
    },
  };
  try {
    const data = yield call(Api.post, '/api/articles/get', body);
    yield put({
      ...actions.getSuccess({
        data: data.data,
      }),
    });
  } catch (error) {
    yield put({ ...actions.getError() });
  }
}

export function* historiesSecSaga() {
  yield takeLatest(actions.getHistoies, getHistoriesSecWorker);
}
