import React, { useState } from 'react'
import { CloseIcon, EmailIcon, LoginUser, PhoneIcon } from 'components/Icons'
import { Form, Formik } from 'formik'
import i18n from 'i18n'
import Modal from 'react-modal'
import Button from 'components/Buttons'
import * as Yup from 'yup'
import Input from 'components/Form/input'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'
import { TitleLarge } from 'components/Styled'
import CheckBox from 'components/Form/cehck-box'
import { Link } from 'react-router-dom'
import SuccessModal from './success'
import styled from 'styled-components'
import ReactGA from 'react-ga';
const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		width: '529px',
		paddingTop: '0',
		position: 'relative',
		overflow: 'visible',
	},
}

const CloseIconWrap = styled.div`
	position: absolute;
	cursor: pointer;
	top: 15px;
	right: 15px;
`

function InvestModal({
	openModal,
	closeModal,
	loading,
	t,
	title,
	buttonText,
	handleCallBack,
	...props
}) {
	const [success, setSuccess] = useState(false)

	const handleCloseSuccess = () => {
		setSuccess(false)
	}
	const done = () => {
		closeModal()
		setSuccess(true)
	}

	let country = i18n.language.toUpperCase() === 'UK' ? 'UA' : 'US'
	return (
		<div>
			<SuccessModal openModal={success} closeModal={handleCloseSuccess} />
			<Modal
				isOpen={openModal}
				onRequestClose={closeModal}
				style={customStyles}
				contentLabel='Example Modal'
				ariaHideApp={false}
			>
				<CloseIconWrap onClick={closeModal}>
					<CloseIcon />
				</CloseIconWrap>
				<TitleLarge color='black' className='mt-5 mb-3'>
					{title}
				</TitleLarge>
				<Formik
					initialValues={{
						modal_name: '',
						modal_email: '',
						modal_phone: '',
						modal_policy_1: false,
						modal_personalData: false,
					}}
					validationSchema={Yup.object({
						modal_name: Yup.string().required(t('required')),
						modal_email: Yup.string()
							.required(t('required'))
							.email(t('incorect')),
						modal_phone: Yup.number().required(t('required')).min(10, 'short'),
						modal_policy_1: Yup.boolean().required(t('required')),
						modal_personalData: Yup.boolean().required(t('required')),
					})}
					onSubmit={(value) => {
						handleCallBack({ done: done, ...value })
						ReactGA.ga('send', 'event', 'Invest Form', 'Submit')
					}}
				>
					{(props) => {
						const { values, handleChange, setFieldValue } = props

						const handleSetField = (newInput = 'modal_phone', value) => {
							setFieldValue(newInput, value)
						}

						return (
							<Form
								id='modal_form'
								className='d-flex justify-content-center align-items-start flex-wrapp flex-column'
							>
								<Input
									className='mt-3'
									id='modal_name'
									icon={LoginUser}
									placeholder={t('register.name')}
									name='modal_name'
									type='text'
									value={values.modal_name}
									onChange={handleChange}
								/>
								<Input
									phone={true}
									country={country}
									className='mt-3'
									id='modal_phone'
									icon={PhoneIcon}
									placeholder={t('register.phone')}
									name='modal_phone'
									value={values.modal_phone}
									onChange={handleSetField}
								/>
								<Input
									className='mt-3'
									id='modal_email'
									icon={EmailIcon}
									placeholder={t('register.email')}
									name='modal_email'
									type='text'
									value={values.modal_email}
									onChange={handleChange}
								/>
								<CheckBox
									className='d-flex d-md-block mt-2 mt-md-2'
									name='modal_personalData'
									id='modal_personalData'
									value={values.modal_personalData}
									label={t('checkbox.personalData')}
									onChange={handleChange}
								/>
								<CheckBox
									className='d-flex d-md-block mt-1 mt-md-1'
									name='modal_policy_1'
									id='modal_policy_1'
									value={values.modal_policy_1}
									label={
										<div>
											{t('checkbox.privacy')}
											<Link to='/app/privacy'>
												{t('checkbox.privacy_link')}
											</Link>
										</div>
									}
									onChange={handleChange}
								/>
								<Button
									disabled={loading}
									theme='blue'
									className='mt-5 m-auto'
									type='submit'
								>
									{buttonText}
								</Button>
							</Form>
						)
					}}
				</Formik>
			</Modal>
		</div>
	)
}

export default compose(withTranslation())(InvestModal)
