import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import {
  actions as communitiActions,
  select as communitiSelect,
} from 'containers/Communities/slices';
import {
  actions as branchesActions,
  select as branchesSelect,
} from 'containers/Branches/slices';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { memo } from 'react';
import Loader from 'components/Loader';
import Header from 'containers/Header';
import Footer from 'containers/Footer';
import { Content } from 'components/PrivateRoute';
import i18n from 'i18n';

function GlobalLoadData({ children, t, ...props }) {
  const { comm_loading, bran_loading, isFooter = true } = props;
  const { getCommunities, getBranches } = props;
  let globalLoad = comm_loading || bran_loading;
  const currentLang = i18n.language;

  useEffect(() => {
    getCommunities();
    getBranches();
  }, [currentLang]);

  return (
    <>
      {globalLoad ? (
        <Loader />
      ) : (
        <>
          <Header />
          <Content>{children}</Content>
          {isFooter && <Footer />}
        </>
      )}
    </>
  );
}

GlobalLoadData.defaultProps = {
  children: <></>,
};

const mapStateToProps = createStructuredSelector({
  comm_loading: communitiSelect.loading,
  bran_loading: branchesSelect.loading,
});

const withConnect = connect(mapStateToProps, {
  getCommunities: communitiActions.getCommunities,
  getBranches: branchesActions.getBranchesStart,
});

export default compose(withConnect, memo, withTranslation())(GlobalLoadData);
