import styled, { css } from 'styled-components'
import { colors, device } from 'components/variables'

const DefStyles = (props) => {
	return css`
		width: 100%;
		margin-bottom: 40px;
		overflow: hidden;
		position: relative;
		text-align: center;
		z-index: 0;
		.img {
			z-index: -2;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	`
}

export const ProjectsBg = styled.div`
	${DefStyles}
	padding: 169px 0;
	margin-bottom: 0;
	&:before {
		content: ' ';
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		z-index: -1;
		background: rgba(56, 56, 56, 0.7);
	}
`

export const ProjectsSmBg = styled.div`
	${DefStyles}
	padding: 65px 0;
	margin-bottom: 0;
	&:before {
		content: ' ';
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		z-index: -1;
		background: rgba(56, 56, 56, 0.7);
	}
`

export const Containe = styled.div``

export const ProgectsTitle = styled.span`
	z-index: 10;
	display: block;
	color: ${colors.white};
	font-weight: 800;
	font-size: 40px;
	line-height: 48px;
	margin-bottom: 15px;
	@media ${device.tablet} {
		font-size: 60px;
		line-height: 73px;
		margin-bottom: 20px;
	}
`

export const ProgectsUnderTitle = styled.span`
	z-index: 10;
	max-width: 760px;
	margin: 0 auto;
	display: block;
	color: ${colors.white};
	font-weight: normal;
	font-size: 25px;
	line-height: 30px;
	@media ${device.tablet} {
		font-size: 40px;
		line-height: 49px;
	}
`

export const ImgBlock = styled.div`
	width: 100%;
	max-width: 500px;
	height: auto;
	overflow: hidden;
	z-index: 0;
	.img {
		position: relative;
	}
	@media ${device.tabletM} {
		min-height: 400px;
		position: relative;
		.img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}
`

export const TextBlock = styled.div`
	width: 90%;
	margin-right: 0;
	word-wrap: break-word;
	@media ${device.laptopS} {
		width: 50%;
		margin-right: 1%;
	}
`
