import React from 'react'
import { Link } from 'react-router-dom'
import environment from 'environment'

import { HistoriesItem, HistoriesImg, HistoriesLabel } from './style'

function HistoryOneItem({ item, className }) {
	const { _id, name, mainphoto } = item
	return (
		<HistoriesItem className={className}>
			<Link to={`/history/${_id}`}>
				<HistoriesImg>
					<img className='img' src={environment.staticUrl + 'images/' + mainphoto} alt='' />
				</HistoriesImg>
				<HistoriesLabel>{name}</HistoriesLabel>
			</Link>
		</HistoriesItem>
	)
}

export default HistoryOneItem
