import { colors } from 'components/variables'
import styled from 'styled-components'

export const ContactItem = styled.div`
	display: flex;
	max-width: 280px;
	width: 100%;
	align-items: center;
	margin: 0 auto;
`

export const ContactItemTitle = styled.h5`
	font-size: 21px;
	line-height: 26px;
	font-weight: 600;
	color: ${colors.blue};
`

export const ContactItemText = styled.span`
	font-size: 18px;
	line-height: 23px;
	font-family: 'PT Sans', 'sans-serif';
`
