import { colors } from 'components/variables'
import styled, { css } from 'styled-components'

export const Wrapper = styled.div``

const generateLarge = (props) => {
	return css`
		border: 1px solid ${props.error ? colors.red : colors.blue};
	`
}

const generateSmall = (props) => {
	return css`
		border: 1px solid ${props.error ? colors.red : colors.grey};
		border-radius: 10px;
		padding: 5px;
	`
}

export const TextareaWrapper = styled.div`
	position: relative;
	width: 100%;
	box-sizing: border-box;
	${props => props.view === 'small' ? generateSmall(props) : generateLarge(props)}
`

export const Textarea = styled.textarea`
	padding: 16px 20px;
	width: 100%;
	min-height: ${props => props.view === 'small' ? '100px' : '160px'};
	resize: vertical;
	border: none;
	outline: none;
	max-height: 450px;
	&::placeholder {
		color: ${(props) => (props.error ? colors.red : colors.grey)};
	}
`

// Property -----------------------------------------

export const WrapperProperty = styled.div`
	width: 100%;
`

export const TextareaProperty = styled(Textarea)`
	padding: 0 22px;
	padding-top: 13px;
	min-height: 47px;
	padding-bottom: 1px;
	&::placeholder {
		font-size: 15px;
		font-family: 'Montserrat', sans-serif;
	}
`
