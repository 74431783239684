import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router'
import AdminRouter from 'components/Loyout/admin'
import CreateProject from './components/project'
import CreateNews from './components/news'
import CreateStory from './components/history'
import NotFoundPage from 'containers/404'
import GlobalLoadData from 'components/Loyout/loadLoyout'
import PrivateRouter from 'components/PrivateRoute'

function Create(props) {
	let match = useRouteMatch()
	const path = {
		create: match.path,
		project: `${match.path}/project`,
		news: `${match.path}/news`,
		proposition: `${match.path}/proposition`,
		history: `${match.path}/history`,
	}
	return (
		<Switch>
			{/* <PrivateRouter exact path={path.project}>
				<GlobalLoadData>
					<AdminRouter>
						<CreateProject />
					</AdminRouter>
				</GlobalLoadData>
			</PrivateRouter> */}
			<PrivateRouter exact path={path.news}>
				<GlobalLoadData>
					<AdminRouter>
						<CreateNews />
					</AdminRouter>
				</GlobalLoadData>
			</PrivateRouter>
			<PrivateRouter exact path={path.history}>
				<GlobalLoadData>
					<AdminRouter>
						<CreateStory />
					</AdminRouter>
				</GlobalLoadData>
			</PrivateRouter>
			<Route path={path.create}>
				<NotFoundPage />
			</Route>
		</Switch>
	)
}

export default Create
