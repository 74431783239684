import styled from 'styled-components'
import bg from 'static/images/prrojectBG.png'
import { colors, device } from 'components/variables'

export const ProjectPageWrapp = styled.div`
	width: 100%;
`

// Top background block ------------

export const HeaderPathWrapper = styled.div`
	max-width: 1170px;
	margin: 0 auto;
`

export const ProjectBg = styled.div`
	width: 100%;
	background-repeat: no-repeat;
	background-size: cover;
	background-image: url(${bg});
`

export const ProgectBgWrapp = styled.div`
	max-width: 1136px;
	margin: 0 auto;
	display: flex;
	align-items: center;
	flex-direction: column-reverse;
	padding: 60px 15px;
	@media ${device.tablet} {
		flex-direction: row;
	}
`

export const ProjectText = styled.div`
	text-align: center;
	margin-right: 0;
	margin-top: 30px;
	@media ${device.tablet} {
		text-align: start;
		margin-right: 60px;
		margin-top: 0;
	}
`

export const ProgectsTitle = styled.span`
	display: block;
	color: ${colors.white};
	font-weight: 800;
	font-size: 35px;
	line-height: 44px;
	margin-bottom: 20px;
	@media ${device.mobileL} {
		font-weight: 800;
		font-size: 40px;
		line-height: 49px;
	}
	@media ${device.tablet} {
		font-size: 50px;
	}
	@media ${device.laptopS} {
		font-size: 45px;
		line-height: 62px;
		text-align: start;
	}
`

export const ProgectsUnderTitle = styled.span`
	display: block;
	color: ${colors.white};
	font-weight: normal;
	font-size: 25px;
	line-height: 30px;
	@media ${device.tablet} {
		font-size: 32px;
	}
	@media ${device.laptopS} {
		font-size: 40px;
		line-height: 49px;
	}
`

export const ProjectImageBlock = styled.div`
	max-width: 290px;
	width: 100%;
	height: 200px;
	@media ${device.tablet} {
		height: 300px;
		max-width: 400px;
	}
	@media ${device.laptopS} {
		height: 400px;
		max-width: 500px;
	}
`

export const ProjectImage = styled.img`
	display: block;
	width: 100%;
	height: 100%;
	object-fit: fill;
`

// Project data -----------

export const ProjectWrapper = styled.div`
	max-width: 1170px;
	margin: 0 auto;
`

// Side bar   --------------

export const SidebarWrapper = styled.div`
	padding-left: 15px;
	padding-right: 15px;
	margin: 0 auto;
	@media ${device.tablet} {
		padding-left: 0px;
		border-right: 1px solid ${colors.orange};
	}
`

export const SidebarContent = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
`

// Sidebar Property  ------------------

export const SidebarProperty = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
	margin-top: 30px;
	flex-direction: column;
	align-items: flex-start;
`

export const Property = styled.span`
	font-size: 18px;
	line-height: 32px;
	font-weight: bold;
	color: ${colors.black};
`

export const PropertyValue = styled.span`
	font-size: 16px;
	line-height: 21px;
`

// Content  ----------------------------

export const Content = styled.div`
	padding-left: 15px;
	padding-right: 15px;
	margin: 0 auto;
	padding-top: 60px;
	@media ${device.tablet} {
		padding-top: 0;
		border: none;
	}
	.fr-view * {
		white-space: pre-wrap !important;
	}
`

export const FloatImage = styled.img`
	margin-left: 0;
	margin-bottom: 20px;
	width: 100%;
	float: none;
	@media ${device.tablet} {
		float: right;
		width: 370px;
		margin-left: 30px;
	}
`

export const DivData = styled.div`
	display: block;
	text-align: center;
	.mT_3 {
		margin-bottom: 30px;
	}
	@media ${device.mobileXL} {
		display: flex;
		justify-content: space-around;
	}
`
