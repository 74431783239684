import React from 'react'
import { SliderNext } from 'components/Icons'
import { ListItem, ListWrapp, WrapperOrder } from './styled'

const oredredList = [
	{ text: 'wayInvest.text_1' },
	{ text: 'wayInvest.text_2' },
	{ text: 'wayInvest.text_3' },
	{ text: 'wayInvest.text_4' },
	{ text: 'wayInvest.text_5' },
]

export function OrederedList({ t, mode = 'large', ...props }) {
	return (
		<ListWrapp mode={mode} {...props}>
			{oredredList.map((item, _index) => (
				<WrapperOrder key={_index}>
					<ListItem mode={mode} color={mode === 'large' ? 'blue' : 'black'}>
						<div className='num'>{_index + 1}</div>
						{t(item.text)}
					</ListItem>
					{oredredList.length > _index + 1 && (
						<SliderNext
							width={mode === 'large' ? '24' : '13'}
							height={mode === 'large' ? '42' : '12'}
							className='arrow'
							color='orange'
						/>
					)}
				</WrapperOrder>
			))}
		</ListWrapp>
	)
}
