import { select } from 'containers/Auth/slices'
import React from 'react'
import { connect } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'
import { compose } from 'redux'
import { createStructuredSelector } from 'reselect'
import styled from 'styled-components'

export const Content = styled.div`
	flex: 1 0 auto;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
`

function Router(props) {
	const { component: Component, children, isAuth, ...rest } = props
	return (
		<Route
			{...rest}
			render={(props) => {
				return isAuth ? (
					children ? (
						<Content>{children}</Content>
					) : (
						<Component {...props} />
					)
				) : (
					<Redirect
						to={{
							pathname: '/home',
						}}
					/>
				)
			}}
		/>
	)
}

const mapStetToProps = createStructuredSelector({
	isAuth: select.isAuth,
})

const withConnect = connect(mapStetToProps, {})

export default compose(withConnect)(Router)
