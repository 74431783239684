import { useField } from 'formik'
import { withTranslation } from 'react-i18next'
import {
	DateInputStyle,
	ErrorText,
	InputWrapper,
	Label,
	Wrapper,
} from './style'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import { colors } from 'components/variables'
import { Calendar } from 'components/Icons'

const modifiersStyles = {
	navButtonPrev: {
		'&:hover': {
			backgroundColor: colors.orange,
		},
	},
	navButtonNext: {
		'&:hover': {
			backgroundColor: colors.orange,
		},
	},
	selected: {
		color: 'white',
		backgroundColor: colors.blue,
	},
	container: {
		border: `1px solid ${colors.blue}`,
	},
	wrapper: {
		backgroundColor: colors.blue,
	},
}

const DateInput = (props) => {
	const [field, meta] = useField({ ...props })

	const { label, className, id, t, type, placeholder, pattern } = props

	const { value } = field

	const errors = meta.touched && meta.error

	return (
		<Wrapper className={className}>
			{label && <Label>{label}</Label>}
			<InputWrapper error={errors ? 1 : 0} value={value ? 1 : 0}>
				{errors && <ErrorText>{t(`error.${meta.error}`)}</ErrorText>}
				<DayPickerInput
					component={DateInputStyle}
					placeholder={placeholder}
					type={type}
					id={id}
					pattern={pattern}
					{...field}
					{...meta}
					{...props}
					value={value}
					dayPickerProps={{
						modifiersStyles: modifiersStyles,
					}}
				/>
				<Calendar className='calendar' />
			</InputWrapper>
		</Wrapper>
	)
}

export default withTranslation()(DateInput)
