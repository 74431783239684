import React, { memo, useState } from 'react'
import { Line, Tab, TitleLarge } from 'components/Styled'
import {
	ProjectWrapper,
	SidebarContent,
	SidebarWrapper,
} from 'containers/Project/style'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router'
import { compose } from 'redux'
import { createStructuredSelector } from 'reselect'
import history from 'utils/history'
import bg from 'static/images/new_investors.png'
import { Wrapper } from 'containers/Community/style'
import HeaderPhoto from 'components/HeaderPhoto'
import FooterForm from 'components/Form/footer-form'
import Benefits from './components/benefits'
import InvestPath from './components/investPath'
import Documents from './components/documents'
import HeeaderPath from 'components/HeaderPath/headerPath'

function Invest({ t, ...props }) {
	const tabs = [
		{ link: '/benefits', label: t('investors.advantagesOfTerritory') },
		{ link: '/investPath', label: t('investors.investorsWay') },
		{ link: '/documents', label: t('investors.documentTemplates') },
	]
	let match = useRouteMatch()
	const [selected, setselected] = useState(tabs[0])

	const hanleSetSelected = (item) => () => {
		setselected(item)
		history.push(match.url + item.link)
	}

	const path = {
		community: `${match.url}`,
		benefits: `${match.url}/benefits`,
		invest_path: `${match.url}/investPath`,
		documents: `${match.url}/documents`,
	}

	const links = [
		{ generalLink: '#' },
		{ label: t('headerPath.home'), link: '/home' },
		{ label: t('headerPath.investors'), link: '#' },
	]
	return (
		<>
			<>
				<HeaderPhoto
					label='Для інвесторів'
					text='Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
					bg={bg}
				/>
				<div className='container mt-6 px-0'>
					<HeeaderPath className='mb-6' searchBox links={links} />
					<ProjectWrapper className='row justify-content-center'>
						<SidebarWrapper className='col-12 col-sm-9 col-md-4'>
							<SidebarContent>
								{tabs.map((item, _index) => {
									const active = selected === item
									return (
										<Tab
											key={_index}
											active={active}
											onClick={hanleSetSelected(item)}
										>
											{item.label}
										</Tab>
									)
								})}
							</SidebarContent>
						</SidebarWrapper>
						<Line className='col-12 col-sm-9 d-md-none mt-6 mb-0 mx-auto pl-4' />
						<Wrapper className='col-12 col-sm-9 col-md-8 pr-0'>
							<Switch>
								<Route
									exact
									path={path.community}
									render={() => <Redirect to={path.benefits} />}
								/>
								<Route exact path={path.benefits}>
									<Benefits />
								</Route>
								<Route exact path={path.documents}>
									<Documents />
								</Route>
								<Route exact path={path.investPath}>
									<InvestPath />
								</Route>
							</Switch>
						</Wrapper>
					</ProjectWrapper>
				</div>
				<TitleLarge className='mt-12'>Залиште запитання</TitleLarge>
				<FooterForm />
			</>
		</>
	)
}

const mapStateToProps = createStructuredSelector({
	// loading_com: communitySelect.loading,
	// loading_pr: projectsSelect.loading,
	// community: communitySelect.community,
	// projects: projectsSelect.projects,
	// branches: branchesSelect.branches,
})

const withConnect = connect(mapStateToProps, {
	// getCommunity: communityActions.getCommunity,
	// getProjects: actions.getProjectsStart,
})

export default compose(withConnect, memo, withTranslation())(Invest)
