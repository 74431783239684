import React, { useState, useRef, useEffect } from 'react';
import {
  ProjectsPlus,
  ProjectsLoop,
  ProjectsLoopInp,
  LiltePlus,
} from 'components/Icons';
import SearchInp from 'components/Form/input/searchInp';
import { Link } from 'react-router-dom';
import useClickOutside from 'components/UseClickOutside/useClickOutside';
import { select as selectAuth } from 'containers/Auth/slices/selectors';

import {
  ProjectsLinksSearch,
  ProjectsLinks,
  ProjectsLink,
  ProjectsPath,
  ProjectsSearch,
  ProjectsSearchPlas,
  ProjectsSearchLoop,
  ProjectsIcons,
  ProjectsInpContainer,
  ProjectsIconsInp,
} from './style';
import { withTranslation } from 'react-i18next';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { connect } from 'react-redux';

function HeeaderPath({
  links = [],
  admin = false,
  searchBox = false,
  plusLabel = '',
  hendleCallBack = () => {},
  isAuth,
  nameHash = '',
  nameName = '',
  t,
  ...props
}) {
  const [inputValue, setInputValue] = useState();
  useEffect(() => {
    if (nameHash) setInputValue(nameHash);
  }, [nameHash]);
  useEffect(() => {
    if (nameName) setInputValue(nameName);
  }, [nameName]);

  const [visibleSearch, setVisibleSearch] = useState(false);
  const changeVisibleSearch = () => {
    setVisibleSearch(!visibleSearch);
  };
  const [gen, ...linkss] = links;
  const currentLabel = linkss.pop();
  const ref = useRef();
  useClickOutside(ref, () => {
    if (visibleSearch) changeVisibleSearch();
  });

  const inputSubmit = (value) => {
    hendleCallBack(value);
    setInputValue(value);
    setVisibleSearch(false);
  };
  return (
    <ProjectsLinksSearch {...props}>
      <ProjectsLinks admin={admin}>
        {linkss.map((item, _index) => {
          return (
            <Link key={_index} to={item.link}>
              <ProjectsLink>{item.label}</ProjectsLink>
              <span> / </span>
            </Link>
          );
        })}
        <ProjectsPath>{currentLabel.label}</ProjectsPath>
      </ProjectsLinks>
      <ProjectsSearch>
        {admin && isAuth && (
          <Link to={gen.generalLink}>
            <ProjectsSearchPlas searchBox={searchBox}>
              <ProjectsIcons className='blur'>
                <ProjectsPlus />
              </ProjectsIcons>
              <ProjectsIcons className='vis'>
                <LiltePlus className='litlePlus' />
                Додати {plusLabel}
              </ProjectsIcons>
            </ProjectsSearchPlas>
          </Link>
        )}
        {searchBox &&
          (!visibleSearch && !inputValue ? (
            <ProjectsSearchLoop
              className='blur_480px'
              onClick={changeVisibleSearch}
            >
              <ProjectsIcons>
                <ProjectsLoop />
              </ProjectsIcons>
            </ProjectsSearchLoop>
          ) : (
            <ProjectsInpContainer ref={ref} className='vis_480px'>
              <ProjectsIconsInp>
                <ProjectsLoopInp />
              </ProjectsIconsInp>
              <SearchInp
                handelCoolbackSubmit={inputSubmit}
                changeVisibleSearch={changeVisibleSearch}
                inputValue={inputValue}
              />
            </ProjectsInpContainer>
          ))}
      </ProjectsSearch>
    </ProjectsLinksSearch>
  );
}

const mapStateToProps = createStructuredSelector({
  isAuth: selectAuth.isAuth,
});

const withConnect = connect(mapStateToProps, {});

export default compose(withConnect, withTranslation())(HeeaderPath);
