import { createSlice } from '@reduxjs/toolkit'
import { isAuth } from 'utils/auth'

export * from './selectors'

const userAuth = createSlice({
	name: 'auth',
	initialState: {
		loading: false,
		isAuth: isAuth(),
		error: '',
	},
	reducers: {
		loginStart: (state) => {
			state.loading = true
		},
		loginEnd: (state) => {
			state.loading = false
		},
		loginError: (state, { payload }) => {
			state.error = payload.error
			state.loading = false
		},
		loginSuccess: (state) => {
			state.loading = false
			state.isAuth = true
		},
		registerStart: (state) => {
			state.loading = true
		},
		registerEnd: (state) => {
			state.loading = false
		},
		registerSuccess: (state) => {
			state.loading = false
		},
		resetStart: (state) => {
			state.loading = true
		},
		resetError: (state, { payload }) => {
			state.error = payload.error
			state.loading = false
		},
		resetEnd: (state) => {
			state.loading = false
		},
		checkStart: (state) => {
			state.loading = true
		},
		checkSuccess: (state) => {
			state.loading = false
		},
		checkError: (state) => {
			state.loading = false
		},
		questionStart: (state) => {
			state.loading = true
		},
		questionSucces: (state) => {
			state.loading = false
		},
		questionError: (state) => {
			state.loading = false
		},
	},
})

export const { actions, reducer } = userAuth
