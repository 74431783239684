import React, { useState } from 'react'
import { withTranslation } from 'react-i18next'
import { Label } from '../input/style'
import BasicSelct from './'
import Textarea from '../textarea/property'

function PropertySelect({
	values,
	increment,
	t,
	options,
	removeItem,
	...props
}) {
	const [seleced, setSeleced] = useState(null)

	const handleSetOption = (item) => {
		setSeleced(item)
		removeItem(item)
	}

	return (
		<div className='w-100 d-flex flex-wrap' key={increment}>
			<div className='col-12 col-lg-6 mt-3 d-block d-md-flex align-items-center'>
				<Label noBold largeLabel className=''>
					Властивість {increment + 1}:
				</Label>
				<BasicSelct
					className='w-100'
					options={options}
					value={seleced}
					onChange={handleSetOption}
					placeholder={t('register.community')}
					name='community'
				/>
			</div>
			<div className='col-12 col-lg-6 mt-3 d-block d-md-flex align-items-center'>
				<Label noBold largeLabel className=''>
					Значення в-ті {increment + 1}:
				</Label>
				<Textarea
					disabled={!!seleced}
					largeLabel
					id={seleced?.type || options[increment]}
					placeholder='Назва'
					name={seleced?.type || options[increment]?.type || `mock${increment}`}
					type='text'
					// onChange={handleChange}
				/>
			</div>
		</div>
	)
}

export default withTranslation()(PropertySelect)
