import { call, put, takeLatest } from '@redux-saga/core/effects';
import Api from 'utils/api';
import { actions } from './slices';
import i18n from 'i18n';

function* getProjectsWorker({ payload }) {
  const currentLang = i18n.language;
  const body = {
    filter: {
      limit: 20,
      skip: payload?.skip || 0,
      name: payload?.name || '',
      hashtag: payload?.hashtag || '',
      community: payload?.community || '',
      industry: payload?.industry || '',
      type: payload?.type || '',
      financeType: payload?.financeType || '',
      sortField: payload?.sortField || 'name',
      sortType: payload?.sortType || 'asc',
      language: currentLang,
    },
  };
  try {
    const { data } = yield call(Api.post, `/api/projects/get`, body);
    yield put({
      ...actions.getProjectsSuccess({
        //projects: _config.mockData.projects,
        //count: 6,
        projects: data.projects,
        count: data.count,
      }),
    });
  } catch (e) {
    yield put({ ...actions.getProjectsError() });
  }
}

function* sendQuestionWrker({ payload }) {
  const { done } = payload;
  // const body = {
  // 	name: payload?.name || '',
  // 	phone: payload?.phone || '',
  // 	email: payload?.email || '',
  // }
  try {
    yield call(done);
    yield put({ ...actions.sendQuestionEnd() });
  } catch (e) {
    yield put({ ...actions.sendQuestionEnd() });
  }
}

export function* projectsSaga() {
  yield takeLatest(actions.getProjectsStart, getProjectsWorker);
  yield takeLatest(actions.sendQuestionStart, sendQuestionWrker);
}
