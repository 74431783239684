import { colors, device } from 'components/variables'
import styled, { css } from 'styled-components'

const InputStyling = (props) => {
	return css`
		padding: 0;
		padding-left: ${props.is_icon ? '0' : '22px'};
		position: relative;
		border: none;
		height: 50px;
		outline: none;
		width: 100%;
		color: ${(props) => (props.error ? colors.red : colors.black)};
		&::placeholder {
			color: ${(props) => (props.error ? colors.red : colors.grey)};
			font-size: 15px;
			font-family: 'PT Sans', 'sans-serif';
		}

		&:-webkit-autofill {
			-webkit-box-shadow: 0 0 0px 1000px white inset;
			animation-name: onAutoFillStart;
		}
		&:-webkit-autofill:focus {
			-webkit-box-shadow: 0 0 0 50px white inset;
			-webkit-text-fill-color: ${colors.black};
			animation-name: onAutoFillStart;
		}
		&:-webkit-autofill,
		&:-webkit-autofill:hover,
		&:-webkit-autofill:focus,
		&:-webkit-autofill:active {
			animation-name: onAutoFillStart;
			transition: background-color 5000s ease-in-out 0s;
		}
		@keyframes onAutoFillStart {
		}
	`
}

export const Wrapper = styled.div`
	display: block;
	align-items: center;
	width: 100%;
	&.category {
		@media ${device.laptop} {
			padding-left: 15px;
		}
	}
	@media ${device.tablet} {
		display: flex;
	}
`

export const InputWrapper = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	border: 1px solid ${(props) => (props.error ? colors.red : colors.blue)};
	width: 100%;
	padding-right: 13px;
	${(props) =>
		props.value &&
		css`
			input {
				color: ${colors.blue};
			}
			.calendar path {
				fill: ${colors.blue};
			}
		`}
	.phoneInput {
		${InputStyling}
		padding: 0;
	}
	.DayPicker-NavButton {
		&:hover {
			fill: ${colors.orange};
			color: ${colors.orange};
		}
	}
	.DayPickerInput {
		width: 100%;
	}
	.DayPicker {
		margin-left: -1px;
		top: 100px;
	}
	.DayPicker-wrapper {
		border: 1px solid ${colors.blue};
	}
`

export const Input = styled.input`
	&[type='file'] {
		position: absolute;
		z-index: -1;
		opacity: 0;
	}
	${InputStyling}
	&.category {
		height: auto;
	}
`

export const ErrorText = styled.div`
	color: ${colors.red};
	font-size: 12px;
	position: absolute;
	font-size: 11px;
	top: -15px;
`

export const Label = styled.label`
	font-size: 21px;
	font-weight: ${(props) => (props.noBold ? 'normal' : 600)};
	color: ${colors.black};
	white-space: nowrap;
	padding-left: 0;
	min-width: 185px;
	margin-bottom: 30px;
	display: inline-table;
	margin-right: 30px;
	@media ${device.laptop} {
		font-size: 22px;
		min-width: ${(props) => (props.largeLabel ? '196px' : 'auto')};
		padding-left: 15px;
	}
	@media ${device.tablet} {
		margin-bottom: 0;
	}
`

export const IconWrapp = styled.div`
	padding: 14px;
`

export const ProjectsSearchLoopInp = styled.input`
	height: 40px;
	width: 100%;
	outline: none;
	padding-left: 37px;
	padding-top: 11px;
	padding-bottom: 10px;
	padding-right: 10px;
	font-weight: normal;
	font-size: 15px;
	line-height: 130%;
	border: 1px solid ${colors.blue};
	background-color: #fff;
	@media ${device.mobileXL} {
		width: 232px;
	}
`
export const DateInputStyle = styled.input`
	height: 50px;
	border: 1px solid ${colors.blue};
	${InputStyling}
`

export const InputWrapperCategory = styled(InputWrapper)`
	display: flex;
	padding: 11px 0;
	padding-left: 15px;
	height: 51px;
`

export const CategoryItem = styled.span`
	font-size: 15px;
	line-height: 130%;
	font-family: 'PT Sans', 'sans-serif';
	padding: 3px 25px 3px 8px;
	position: relative;
	border: 1px solid ${colors.black};
	color: ${colors.black};
	margin-right: 15px;
	display: flex;
	.close {
		position: absolute;
		top: 35%;
		right: 6px;
	}
`
