import {
	Tooltip,
	ResponsiveContainer,
	BarChart,
	XAxis,
	YAxis,
	Legend,
	Bar,
} from 'recharts'
import styled from 'styled-components'

const Wrapper = styled.div`
	height: 570px;
`

export const BarGraph = ({ pause = false, data, colors, ...props }) => {
	return (
		<Wrapper>
			<ResponsiveContainer width='100%' height='100%'>
				<BarChart
					width={600}
					height={400}
					data={data}
					layout='vertical'
					stackOffset='sign'
					barSize={23}
				>
					<XAxis tickCount={11} type='number' />
					<YAxis
						axisLine={false}
						tickLine={false}
						type='category'
						dataKey='name'
					/>
					<Tooltip />
					<Legend verticalAlign='top' align='right' />
					<Bar
						isAnimationActive={!pause}
						animationDuration={400}
						layout='vertical'
						dataKey='men'
						fill={colors[0]}
						stackId='stack'
					/>
					<Bar
						isAnimationActive={!pause}
						animationDuration={400}
						layout='vertical'
						dataKey='women'
						fill={colors[1]}
						stackId='stack'
					/>
				</BarChart>
			</ResponsiveContainer>
		</Wrapper>
		// <KPIWrapper>
		// 	<ResponsiveContainer width='100%' height='100%'>
		// 		<BarChart
		// 			width={500}
		// 			height={300}
		// 			data={data}
		// 			stackOffset='sign'
		// 			layout='vertical'
		// 			// margin={{
		// 			// 	top: 5,
		// 			// 	right: 30,
		// 			// 	left: 20,
		// 			// 	bottom: 5,
		// 			// }}
		// 		>
		// 			<CartesianGrid strokeDasharray='3 3' />
		// 			<XAxis />
		// 			<YAxis dataKey='name' />
		// 			<Tooltip />
		// 			<Legend />
		// 			<ReferenceLine y={0} stroke='#000' />
		// 			<Bar
		// 				dataKey='men'
		// 				// isAnimationActive={!pause}
		// 				layout='vertical'
		// 				fill='#8884d8'
		// 				stackId='stack'
		// 			/>
		// 			<Bar
		// 				dataKey='women'
		// 				// isAnimationActive={!pause}
		// 				layout='vertical'
		// 				fill='#82ca9d'
		// 				stackId='stack'
		// 			/>
		// 		</BarChart>
		// 	</ResponsiveContainer>
		// </KPIWrapper>
	)
}
