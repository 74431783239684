import React from 'react'
import Pagination from 'components/Pagination/pagination'
import { ProjectsContentWrapper } from './style'

import { NoData } from 'components/Styled'

export default function PaternBuild(props) {
	const {
		paternItem,
		ViewItem,
		plusLabel = '',
		setSel,
		sel,
		def_count,
		countItems,
		hendleCallBack,
		hashtagCallBack,
	} = props
	const handleCallBack = (object) => {
		const obj = { skip: object.selected * def_count, limit: def_count }
		setSel(object.selected)
		hendleCallBack(obj)
	}
	const dataDone = paternItem.length ? false : true
	const labelNon = plusLabel === 'проект' ? 'проектів' : 'пропозицій'
	return (
		<ProjectsContentWrapper>
			{dataDone ? (
				<NoData>{labelNon} не найдено</NoData>
			) : (
				<>
					<div className='container p-0'>
						<div className='row'>
							{paternItem.map((item, _index) => {
								const n32 = (_index + 2) % 3 === 0 ? 'n32' : ''
								const n33 = (_index + 1) % 3 === 0 ? 'n33' : ''
								return (
									<div key={_index} className='mb-8 col-12 col-md-6 col-lg-4'>
										<ViewItem
											className={`marg-0 ${n32} ${n33}`}
											item={item}
											hashtagCallBack={hashtagCallBack}
										/>
									</div>
								)
							})}
						</div>
					</div>
				</>
			)}
			<Pagination
				pageCount={countItems / def_count}
				callBack={handleCallBack}
				sel={sel}
			/>
		</ProjectsContentWrapper>
	)
}
