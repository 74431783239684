import { ProjectArrowW } from 'components/Icons';
import {
  FillImage,
  Line,
  SubtitleLarge,
  SupperImg,
  NoImage,
  NoImg,
} from 'components/Styled';
import React from 'react';
import { withTranslation } from 'react-i18next';
import history from 'utils/history';
import {
  DataText,
  DataTitle,
  HashLine,
  HashText,
  ProjectContainer,
  ProjectData,
  ProjectDate,
  ProjectImgBlock,
  ProjectWrapp,
  HashtagWrapper,
  ProjectImgWrapp,
} from './style';
import img1 from 'static/images/NonImgColor.png';
import moment from 'moment';
import enviroment from 'environment';

function ProjectItem({ t, item, hashtagCallBack, ...props }) {
  const { _id, mainphoto, name, date, financeValue, hashtag, properties } =
    item;
  const temp = properties.filter((obj) => !!obj);
  const propertiesObj = temp.reduce((acc, obj) => {
    return { ...acc, [obj.name]: obj.value };
  }, {});

  const handleClick = (e) => {
    history.push(`/project/${_id}`, item);
  };

  const regularExp = new RegExp('img_');
  const imge = regularExp.exec(mainphoto);
  const dat = moment(date).format('DD.MM.YYYY');
  const hashtg =
    hashtag?.split(' ')?.map((item) => ({ label: '#' + item, value: item })) ||
    [];
  return (
    <ProjectWrapp {...props}>
      <ProjectContainer {...props}>
        <ProjectImgBlock onClick={handleClick}>
          <ProjectImgWrapp>
            {imge ? (
              <FillImage
                className='img'
                src={`${enviroment.apiUrl}/images/${mainphoto}`}
                alt='Project Image'
              />
            ) : (
              <NoImage>
                <NoImg src={img1} alt='Project Image' />
              </NoImage>
            )}
          </ProjectImgWrapp>
        </ProjectImgBlock>
        <ProjectData>
          <ProjectDate>{dat}</ProjectDate>
          <SubtitleLarge
            onClick={handleClick}
            className='blockSlice cursor-pointer'
            weight='bold'
          >
            {name}
          </SubtitleLarge>
          <Line />
          {!!financeValue && (
            <DataTitle>
              {t('project.invest')}
              <DataText>{financeValue}</DataText>
            </DataTitle>
          )}
          {!!propertiesObj.square && (
            <DataTitle>
              {t('project.square')}
              <DataText>{propertiesObj.square}</DataText>
            </DataTitle>
          )}
          <DataTitle className='mb-2'>{t('project.projectGoal')}</DataTitle>
          <DataText height='60px'>{propertiesObj.projectGoal}</DataText>
          <HashLine className='mt-3'>
            <HashtagWrapper>
              {hashtg.map((item, _index) => {
                return (
                  <HashText
                    onClick={() => hashtagCallBack(item.value)}
                    key={_index}
                  >
                    {item.label}
                  </HashText>
                );
              })}
            </HashtagWrapper>
            <ProjectArrowW className='cursor-pointer' onClick={handleClick} />
          </HashLine>
        </ProjectData>
      </ProjectContainer>
    </ProjectWrapp>
  );
}

export default withTranslation()(ProjectItem);
