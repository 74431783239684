import { call, put, takeLatest } from '@redux-saga/core/effects';
import Api from 'utils/api';
import { actions } from './slices';
import i18n from 'i18n';

function* getCommunities({ payload }) {
  const body = {
    filter: {
      name: '',
      limit: 14,
      skip: 0,
      language: i18n.language,
    },
  };
  try {
    const { data } = yield call(Api.post, '/api/communities/get', body);
    yield put({
      ...actions.getSuccess({
        data: data.communities,
      }),
    });
  } catch (e) {
    yield put({ ...actions.getError() });
  }
}

export function* communitiesSaga() {
  yield takeLatest(actions.getCommunities, getCommunities);
}
