import styled, { css } from 'styled-components'
import bg from 'static/images/homeBg.jpg'
import { colors, device } from 'components/variables'

export const HomeWrapper = styled.div`
	width: 100%;
`

export const HomeContainer = styled.div``

export const HomeBg = styled.div`
	background-image: url(${bg});
	background-repeat: no-repeat;
	background-size: cover;
	position: relative;
`

export const ProjectsMobile = styled.div`
	padding: 0 15px;
	display: block;
	@media ${device.tabletM} {
		display: none;
	}
`

export const ProjectsDesktop = styled.div`
	display: none;
	@media ${device.tabletM} {
		display: block;
	}
`

// Why us block  ----------------

export const AboutBlock = styled.div`
	max-width: 1350px;
	margin: 0 auto;
	padding: 0 15px;
	padding-bottom: 40px;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	max-height: 1000px;
	overflow: hidden;
	position: relative;
	transition: max-height 0.25s ease-out;
	${(props) =>
		props.open &&
		css`
			max-height: 2000px !important;
			transition: max-height 0.25s ease-in;
			.arrow_more {
				transform: translate(-50%, -50%) rotate(180deg) !important;
			}
		`}
	.arrow_more {
		position: absolute;
		bottom: -10px;
		left: 50%;
		display: block;
		transform: translate(-50%, -50%);
	}
	.arrow_more:hover path {
		fill: ${colors.orange};
	}
	@media ${device.mobileM} {
		max-height: 910px;
	}
	@media ${device.tablet} {
		max-height: initial;
		.arrow_more {
			display: none;
		}
	}
`

export const AboutItem = styled.div`
	max-width: 410px;
	text-align: center;
	padding: 0 20px;
	&.small {
		padding: 0;
	}
	@media ${device.tablet} {
		max-width: 360px;
	}
	@media ${device.laptopS} {
		max-width: 410px;
		&.small {
			max-width: 290px;
		}
	}
	@media ${device.laptopXS} {
		&.small {
			max-width: 324px;
		}
	}
`

export const AboutItemText = styled.div`
	font-size: 17px;
	line-height: 130%;
	font-family: 'PT Sans', 'sans-serif';
`
