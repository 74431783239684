import { createSlice } from '@reduxjs/toolkit'

export * from './selectors'

const branchesSlice = createSlice({
	name: 'branches',
	initialState: {
		branches: [],
		loading: false,
	},
	reducers: {
		getBranchesStart: (state) => {
			state.loading = true
		},
		getSuccess: (state, { payload }) => {
			state.branches = payload.data
			state.loading = false
		},
		getError: (state) => {
			state.loading = false
		},
		getEnd: (state) => {
			state.loading = false
		},
	},
})

export const { actions, reducer } = branchesSlice
