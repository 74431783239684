import { colors } from 'components/variables'
import { useField } from 'formik'
import React from 'react'
import { withTranslation } from 'react-i18next'
import Select from 'react-select'
import styled from 'styled-components'
import { ErrorText } from '../input/style'

const SelectWrapp = styled.div`
	position: relative;
	min-height: 50px;
	width: 100%;
`

const IconWrapp = styled.div`
	position: absolute;
	top: 14px;
	left: ${(props) => (props.mode ? '26px' : '14px')};
`

const customStyles = {
	placeholder: (provided, state) => {
		return {
			...provided,
			fontSize: '15px',
			color: state.selectProps.errors ? colors.red : colors.grey,
		}
	},
	container: (provided, state) => {
		return {
			...provided,
			width: '100%',
			position: 'inherit',
			border: `1px solid ${
				state.selectProps.errors ? colors.red : colors.blue
			}`,
		}
	},
	control: (provided, state) => {
		let paddingLeft = state.selectProps.isIcon
			? '2px'
			: state.selectProps.mode
			? '39px'
			: '35px'
		return {
			...provided,
			border: 0,
			boxShadow: 'none',
			minHeight: '50px',
			paddingLeft,
			background: 'transparent',
		}
	},
	indicatorSeparator: (provided, state) => {
		return { ...provided, display: 'none' }
	},
	option: (provided, state) => {
		return {
			...provided,
			background: 'transparent',
			color: colors.grey,
			fontSize: '15px',
			'&:hover': {
				color: colors.black,
				cursor: 'pointer',
			},
		}
	},
	singleValue: (provided, state) => {
		return { ...provided, fontSize: '15px' }
	},
	menu: (provided, state) => {
		return {
			...provided,
			width: 'calc(100% + 2px)',
			top: '80%',
			left: '-1px',
			boxShadow: 'none',
			border: `1px solid ${
				state.selectProps.errors ? colors.red : colors.blue
			}`,
			borderTop: 'none',
			borderRadius: '0px',
			backgroundColor: '#ffffff',
			zIndex: 999,
		}
	},
}

function BasicSelct(props) {
	const { className, options, value, onChange, icon, placeholder, t, mode } =
		props

	const [, meta] = useField(props)

	const errors = meta.touched && meta.error
	const Icon = icon

	return (
		<SelectWrapp className={className}>
			{errors && <ErrorText>{t(`error.${meta.error}`)}</ErrorText>}
			{icon && (
				<IconWrapp mode={mode}>
					<Icon error={errors ? 1 : 0} active={value} />
				</IconWrapp>
			)}
			<Select
				mode={mode}
				errors={errors ? 1 : 0}
				styles={customStyles}
				value={value}
				onChange={(option) => onChange(option)}
				options={options}
				placeholder={placeholder}
				isIcon={!icon}
			/>
		</SelectWrapp>
	)
}

export default withTranslation()(BasicSelct)
