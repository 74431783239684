import React from 'react'
import { Link } from 'react-router-dom'
import { Map, TileLayer, Polygon, Marker, Popup, Tooltip } from 'react-leaflet'
import L from 'leaflet'
import MarkerIcon from 'static/images/marker.png'

const purpleOptions = { color: 'purple' }
const MarkerMap = ({coordinates, ...props }) => {


	const ICON = L.icon({
		iconUrl: MarkerIcon,
		iconSize: [32, 32],
	})
	return (
		<Map
			className='map'
			center={coordinates}
			zoom={13}
			style={{ height: 500, width: '100%' }}
        >
			<Marker icon={ICON} position={coordinates}></Marker>
			<TileLayer
				attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
				url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
			/>
		</Map>
	)
}

export default MarkerMap
