import { useSpring, animated } from 'react-spring'
import React, { memo } from 'react'
import { AnimateHOC } from 'components/Animation'
import { NumberWrapper } from './style'

function AnimateNumber({ number, pause }) {
	const props = useSpring({ val: number, from: { val: 0 }, pause })
	return (
		<NumberWrapper>
			<animated.span className='number'>
				{number - Math.floor(number) === 0
					? props.val.interpolate((val) => Math.floor(val))
					: props.val.interpolate((val) => val.toFixed(2))}
			</animated.span>
		</NumberWrapper>
	)
}

function NumberRun({ number }) {
	return <AnimateHOC Component={AnimateNumber} number={number} />
}

export default memo(NumberRun)
