import React, { memo, useEffect, useState } from 'react'
import { useTranslation, withTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { actions } from './slices'
import HeaderPhoto from 'components/HeaderPhoto'
import GenerBG from 'static/images/new_projects.png'
import HeeaderPath from 'components/HeaderPath/headerPath'
import { useLocation, useParams, useRouteMatch } from 'react-router-dom'

import { ProjectsWrapper, HeadWrapper } from '../Projects/style'
import CTA from 'components/CTA'
import ProrgamsItem from 'components/Program'
import Pagination from 'components/Pagination/pagination'
import Info from 'containers/Community/components/info'
import i18n from 'i18n';

function DocScreen(props) {
  const {t} = useTranslation()
  let match = useRouteMatch();
  let { id } = useParams();
	const programs = useSelector(store =>  store.programs?.programs)
  // const program = programs.find(el => el?._id === id)
  const currentLang = i18n.language;
	const dispatch = useDispatch()
	const doc = useSelector(store => store.program.program)
	

	const location = useLocation()

	const [nameObj, setNameObj] = useState({})
	const [hashtag, setHashtag] = useState(location.state?.detail || '')


	const links = [
		{ generalLink: '/programs' },
		{ label: t('headerPath.home'), link: '/home' },
		{ label: t('header.programs'), link: '#' },
	]

	const nameCallBack = (name) => {
		setHashtag('')
    setNameObj({ name })
	}

useEffect(()=>{	
	dispatch(actions.getProgramItem({_id:id}))
}, [])


	return (
		<ProjectsWrapper>
			<HeaderPhoto bg={GenerBG} label={t('header.programs')} />
			<HeadWrapper className='mt-6'>
				<HeeaderPath
					nameHash={hashtag}
					nameName={nameObj.name}
					plusLabel={'проект'}
					searchBox
					links={links}
					hendleCallBack={nameCallBack}
				/>
			</HeadWrapper>
      <div className='p-5'>
    <Info title={doc?.name} pdf={[doc?.pdf]} match={match} details description={doc?.content} />
    </div>
      <CTA
				text={t('projectInfo.InterestedProjects')}
				buttonText={t('projectInfo.Invest')}
				link='contacts_form'
				className='mt-12'
			/>
		</ProjectsWrapper>
	)
}



export default DocScreen
