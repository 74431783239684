import React, { memo, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import Input from 'components/Form/input'
import FileInput from 'components/Form/input/file.js'
import DateInput from 'components/Form/input/date.js'
import { withTranslation } from 'react-i18next'
import Button from 'components/Buttons'
import { ImgIcon } from 'components/Icons'
import { useState } from 'react'
import Category from '../input/category'
import { Label } from '../input/style'
import PropertySelect from '../select/property'
import { AddProperty, EditorWrapper } from './style'
import { FroalaEditor } from './FroalaEditor'
import BasicSelct from 'components/Form/select'
import { createStructuredSelector } from 'reselect'
import { select as selectBranch } from 'containers/Branches/slices'
import { userSelect } from 'containers/selectors/user'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { SelectModel } from 'models/select'
import Location from './location'
import { propToArray } from 'utils/help-func'
import Textarea from '../textarea'

const MINE_TYPES = ['image/png', 'image/pjpeg', 'image/jpeg', 'image/webp']

const pluginsEnabled = [
	'table',
	'spell',
	'quote',
	'fontSize',
	'fontFamily',
	'save',
	'quickInsert',
	'paragraphFormat',
	'paragraphStyle',
	'help',
	'draggable',
	'align',
	'link',
	'lists',
	'file',
	'image',
	'emoticons',
	'url',
	'embedly',
	'video',
	'colors',
	'entities',
	'inlineClass',
	'inlineStyle',
	'codeBeautif ',
	'spellChecker',
	'imageTUI',
]

const toolbarButtons = {
	moreText: {
		buttons: [
			'bold',
			'italic',
			'underline',
			'strikeThrough',
			'subscript',
			'superscript',
			'fontFamily',
			'fontSize',
			'textColor',
			'backgroundColor',
			'inlineClass',
			'inlineStyle',
			'clearFormatting',
		],
	},
	moreParagraph: {
		buttons: [
			'alignLeft',
			'alignCenter',
			'formatOLSimple',
			'alignRight',
			'alignJustify',
			'formatOL',
			'formatUL',
			'paragraphFormat',
			'paragraphStyle',
			'lineHeight',
			'outdent',
			'indent',
			'quote',
		],
	},
	moreRich: {
		buttons: [
			'insertLink',
			'insertImage',
			'insertVideo',
			'insertTable',
			'emoticons',
			'fontAwesome',
			'specialCharacters',
			'embedly',
			'insertFile',
			'insertHR',
		],
	},
	moreMisc: {
		buttons: [
			'undo',
			'redo',
			'fullscreen',
			'print',
			'getPDF',
			'spellChecker',
			'selectAll',
			'html',
			'help',
		],
		align: 'right',
		buttonsVisible: 2,
	},
}
function CreateForm(props) {
	const {
		isProperties,
		properties,
		isTypes,
		types,
		input_label_1,
		handleSubmit,
		branches,
		user,
		typeOfItem,
		isDescribe,
		t,
	} = props

	const [image, setImage] = useState(null)
	const [categories, setCategories] = useState([])
	const [count, setCount] = useState(3)
	const [selects, setSelects] = useState([])
	const [model, setModel] = useState('')
	const [selectedType, setSelectedType] = useState()
	const [branch, setBranch] = useState()
	const [finance, setFinance] = useState()
	const [latLng, setLatLng] = useState()

	const selectBranches = branches.map((item) => new SelectModel(item))
	let accept = MINE_TYPES.reduce((acc, val) => `${acc},${val}`, '').slice(1)

	const creatingArray = () => {
		let arr = []
		for (let i = 0; i < properties.length; i++) {
			arr.push({
				type: properties[i].type,
				label: t(`project.${properties[i].type}`),
				value: properties[i].type,
			})
		}
		setSelects(arr)
	}

	const handleRemove = (item) => {
		setSelects(selects.filter((element) => element !== item))
	}
	const handelSetCount = () => {
		setCount(count + 1)
	}
	const handleSetModel = (value) => {
		setModel(value)
	}

	const renderArray = (values) => {
		let arr = []

		for (let i = 0; i < count; i++) {
			arr.push(
				<PropertySelect
					increment={i}
					values={values}
					options={selects}
					removeItem={handleRemove}
					key={i}
				/>
			)
		}
		return arr
	}

	useEffect(() => {
		creatingArray()
	}, [])

	const handleRemoveCategory = (item) => () => {
		setCategories(categories.filter((element) => element !== item))
	}

	const initialProject = {
		validation: {
			title: Yup.string().trim().required(t('required')),
			date: Yup.date().required(t('required')),
			image: Yup.string().required(t('required')),
			categories: Yup.string().max(8, 'MAX').required(t('required')),
			type: Yup.object().required(t('required')),
			branch: Yup.object().required(t('required')),
			location: Yup.object().required(t('required')),
			financeType: Yup.object().required(t('required')),
		},
		initialValues: {
			title: '',
			date: new Date(),
			image: '',
			categories: '',
			type: selectedType,
			branch: branch,
			location: latLng,
			financeType: finance,
			invest: '',
			square: '',
			implementation_period: '',
			phone: '',
			email: '',
			ownership: '',
			state_part: '',
			projectGoal: '',
			adress: '',
			result: '',
			interest: '',
		},
	}
	const initialNews = {
		validation: {
			title: Yup.string().trim().required(t('required')),
			date: Yup.date().required(t('required')),
			image: Yup.string().required(t('required')),
			description: Yup.string().max(350)
			.when('showEmail', {
				is: isDescribe,
				then: Yup.string().required(t('required')),
			}),
			categories: Yup.string().max(8, 'MAX').required(t('required')),
		},
		initialValues: {
			title: '',
			date: new Date(),
			image: '',
			categories: '',
			community: '',
			content: model,
			description: '',
			type: 'news',
		},
	}

	const validate = typeOfItem === 'news' ? initialNews : initialProject
	return (
		<Formik
			initialValues={validate.initialValues}
			validationSchema={Yup.object(validate.validation)}
			onSubmit={(value) => {
				const payload = {
					name: value.title,
					date: value.date,
					hashtag: categories.join(' '),
					type: selectedType?.value || '',
					industry: branch?.value || '',
					location: { type: 'Point', coordinates: [latLng?.lat, latLng?.lng] },
					financeType: finance?.value || '',
					financeValue: 123,
					community: user.community,
					author: user._id,
					mainphoto: image,
					content: model,
					description: value.description,
					properties: propToArray(properties, value),
				}
				handleSubmit(payload)
			}}
		>
			{(props) => {
				const { values, handleChange, setFieldValue } = props

				const handleFileChange = (e) => {
					setImage(e.currentTarget.files[0])
					setFieldValue('image', 'isImage')
				}
				const handleKeyDown = (event) => {
					if (event.key === 'Enter' || event.keyCode === 32) {
						if (event.target.value.trim()) {
							setCategories([...categories, event.target.value])
						}
						setFieldValue('categories', ' ')
					}
				}
				const handleSetType = (value) => {
					setFieldValue('type', value)
					setSelectedType(value)
				}
				const handleSetBranch = (value) => {
					setFieldValue('branch', value)
					setBranch(value)
				}
				const handleSetFinance = (value) => {
					setFieldValue('financeType', value)
					setFinance(value)
				}
				const handleSetLatLang = (value) => {
					setFieldValue('location', value)
					setLatLng(value)
				}

				return (
					<Form className='container'>
						<div className='row'>
							<Input
								largeLabel
								className='col-12 col-lg-9 mt-3'
								id='title'
								label={input_label_1}
								placeholder='Назва'
								name='title'
								type='text'
								value={values.title}
								onChange={handleChange}
							/>
							<DateInput
								format='DD/MM/YYYY'
								className='col-12 col-lg-3 mt-3'
								id='date'
								label='Дата:'
								placeholder='Назва'
								name='date'
								type='text'
								value={values.date}
								onChange={handleChange}
							/>
							<FileInput
								largeLabel={true}
								className='col-12 col-lg-5 mt-3'
								id='image'
								name='image'
								label='Основне фото:'
								icon={ImgIcon}
								type='file'
								onChange={handleFileChange}
								accept={accept}
								file={image}
							/>
							<Category
								categories={categories}
								className='col-12 col-lg-7 mt-3 category'
								id='categories'
								label='Категорії:'
								placeholder='Категорія'
								name='categories'
								type='text'
								value={values.categories}
								onChange={handleChange}
								onKeyDown={handleKeyDown}
								handleRemoveCategory={handleRemoveCategory}
							/>
							{isTypes && (
								<>
									<div className='d-block d-md-flex align-items-center justify-content-between col-12 col-lg-7 mt-3'>
										<Label largeLabel>Тип прєкта:</Label>
										<BasicSelct
											options={types}
											value={selectedType}
											onChange={handleSetType}
											placeholder='Тип проєкта'
											name='type'
										/>
									</div>
									<div className='d-block d-md-flex align-items-center justify-content-between col-12 col-lg-5 mt-3'>
										<Label>Галузь:</Label>
										<BasicSelct
											options={selectBranches}
											value={branch}
											onChange={handleSetBranch}
											placeholder='Галузь'
											name='branch'
										/>
									</div>
									<Location
										name='location'
										handleSetLatLang={handleSetLatLang}
										className='d-block d-md-flex align-items-center justify-content-between col-12 col-lg-5 mt-3'
									/>
									<div className='d-block d-md-flex align-items-center justify-content-between col-12 col-lg-7 mt-3'>
										<Label largeLabel>Фінансування:</Label>
										<BasicSelct
											options={[
												{ label: 'Продаж', value: 'sales' },
												{ label: 'Інвестиції', value: 'investing' },
												{ label: 'Оренда', value: 'rent' },
											]}
											value={finance}
											onChange={handleSetFinance}
											placeholder='Тип фінансування'
											name='financeType'
										/>
									</div>
								</>
							)}
							{isProperties && (
								<>
									<Label className='col-12 mt-3'>Властивості проекту:</Label>
									{renderArray()}
									{count < properties.length && (
										<AddProperty
											className='col-12 mt-2'
											onClick={handelSetCount}
										>
											+ Додати властивість
										</AddProperty>
									)}
								</>
							)}
							{isDescribe && 
								<div className='w-100'>
									<Textarea 
										view='small'
										className='col-12 mt-3 pl-2 pr-1'
										id='description'
										label='Короткий опис:'
										placeholder='Назва'
										name='description'
										value={values.description}
										onChange={handleChange}
									/>
								</div>
							}
							<EditorWrapper className='col-12 mt-3'>
								<FroalaEditor
									tag='textarea'
									config={{
										attribution: false,
										placeholder: 'Start typing...',
										toolbarButtons: toolbarButtons,
										pluginsEnabled: pluginsEnabled,
									}}
									model={model}
									onModelChange={handleSetModel}
								/>
							</EditorWrapper>
						</div>
						<div className='w-100 text-center mt-6'>
							<Button type='submit' theme='blue'>
								Відправити
							</Button>
						</div>
					</Form>
				)
			}}
		</Formik>
	)
}

CreateForm.propTypes = {
	isProperties: PropTypes.bool,
	properties: PropTypes.arrayOf(PropTypes.object),
	isTypes: PropTypes.bool,
	types: PropTypes.arrayOf(PropTypes.object),
	input_label_1: PropTypes.string,
	handleSubmit: PropTypes.func,
	user: PropTypes.object,
	typeOfItem: PropTypes.string,
	isDescribe: PropTypes.bool,
	t: PropTypes.func,
}

CreateForm.defaultProps = {
	isProperties: false,
	properties: [],
	isTypes: false,
	types: [],
	input_label_1: 'Item title',
	user: {},
	typeOfItem: '',
	isDescribe: false,
	handleSubmit: () => {},
	t: () => {},
}

const mapStateToProps = createStructuredSelector({
	branches: selectBranch.branches,
	user: userSelect.user,
})

const withConnect = connect(mapStateToProps, {})

export default compose(memo, withConnect, withTranslation())(CreateForm)
