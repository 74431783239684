import environment from 'environment'

export class UploadModel {
	constructor(data) {
		data = data || {}
		this.id = data._id || data.id
		this.location = null

		if (data.location) {
			this.location = `${environment.staticUrl + data.location}`
		}
	}
}

export class UploadModelFolder {
	constructor(data) {
		data = data || {}
		this.id = data._id || data.id
		this.location = null

		if (data.name) {
			this.location = data.name
		}
	}
}
