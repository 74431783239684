import { useField } from 'formik'
import React from 'react'
import { CheckBoxHidden, CheckLabel, Wrapper } from './style'

function CheckBox(props) {
	const [field, meta] = useField({ ...props })

	const { label, className } = props

	const errors = meta.touched && !meta.value

	return (
		<Wrapper className={className}>
			<CheckBoxHidden type='checkbox' {...field} {...props} />
			<CheckLabel errors={errors ? 1 : 0} htmlFor={props.name}>
				{label}
			</CheckLabel>
		</Wrapper>
	)
}

export default CheckBox
