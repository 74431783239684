import { useField } from 'formik'
import { withTranslation } from 'react-i18next'
import { ErrorText } from '../input/style'
import { TextareaProperty, TextareaWrapper, WrapperProperty } from './style'

const Textarea = (props) => {
	const [field, meta] = useField({ ...props })

	const { className, id, t, type, placeholder } = props

	const { value } = field

	const errors = meta.touched && meta.error

	return (
		<WrapperProperty className={className}>
			<TextareaWrapper error={errors ? 1 : 0}>
				{errors && <ErrorText>{t(`error.${meta.error}`)}</ErrorText>}
				<TextareaProperty
					error={errors ? 1 : 0}
					placeholder={placeholder}
					type={type}
					id={id}
					{...field}
					{...meta}
					value={value}
				/>
			</TextareaWrapper>
		</WrapperProperty>
	)
}

export default withTranslation()(Textarea)
