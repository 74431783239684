import { colors } from 'components/variables'
import styled from 'styled-components'

export const BranchWrapp = styled.div`
	cursor: pointer;
	width: 100%;
	&:hover .text {
		color: ${colors.blue};
	}
`
export const BranchImage = styled.div`
	width: 100%;
	height: 344px;
`
