import { call, put, takeLatest } from '@redux-saga/core/effects'
import Api from 'utils/api'
import { actions } from './slices'

function* getPropisitions({ payload }) {
	const body = {
		filter: {
			limit: payload?.limit || 10,
			skip: payload?.skip || 0,
			name: payload?.name || '',
			hashtag: payload?.hashtag || '',
			community: payload?.community || '',
			industry: payload?.industry || '',
			type: payload?.type || '',
			financeType: payload?.financeType || '',
			sortField: payload?.sortField || 'name',
			sortType: payload?.sortType || 'asc',
		},
	}
	try {
		const { data } = yield call(Api.post, `/api/offers/get`, body)
		yield put({
			...actions.getSuccess({
				data: data.offers,
				count: data.count,
			}),
		})
	} catch (e) {
		yield put({ ...actions.getError() })
	}
}

export function* propositionsSaga() {
	yield takeLatest(actions.getPropositionsStart, getPropisitions)
}
