import { ProjectArrow } from 'components/Icons'
import {
	DataText,
	HashLine,
	HashText,
	ProjectDate,
} from 'components/Project/style'
import {
	FillImage,
	Line,
	SubtitleLarge,
	SupperImg,
	NoImage,
	NoImg,
} from 'components/Styled'
import img1 from 'static/images/NonImgColor.png'
import React from 'react'
import PropTypes from 'prop-types'
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView'
import history from 'utils/history'
import { ImageBlock, NewsTitle, NewsWrapper, TextBlock } from './style'

function NewsItem({
	item,
	mode = 'large',
	isRevers = false,
	hendleCallHashteg,
	...props
}) {
	const { _id, name, date, description, image, hashtag } = item

	const handleClick = (e) => {
		history.push(`/news/${_id}`)
	}
	const regularExp = new RegExp('img_')
	const imge = regularExp.exec(image)
	return (
		<NewsWrapper {...props} isRevers={isRevers}>
			<TextBlock isRevers={isRevers}>
				<ProjectDate>{date}</ProjectDate>
				{mode === 'large' ? (
					<NewsTitle onClick={handleClick} className='text-start'>
						{name}
					</NewsTitle>
				) : (
					<SubtitleLarge
						onClick={handleClick}
						className='text-start'
						weight='bold'
					>
						{name}
					</SubtitleLarge>
				)}
				<Line className='mt-3 mb-2' />
				<DataText>
					<FroalaEditorView model={description} />
				</DataText>
				<HashLine className='mt-3'>
					<div>
						{hashtag.map((item, _index) => {
							return (
								<HashText
									onClick={() => hendleCallHashteg(item.value)}
									key={_index}
								>
									{item.label}
								</HashText>
							)
						})}
					</div>
					<ProjectArrow className='cursor-pointer' onClick={handleClick} />
				</HashLine>
			</TextBlock>
			<ImageBlock mode={mode} onClick={handleClick}>
				<SupperImg>
					{imge ? (
						<FillImage className='img' src={image} alt='Project Image' />
					) : (
						<NoImage>
							<NoImg src={img1} alt='Project Image' />
						</NoImage>
					)}
				</SupperImg>
			</ImageBlock>
		</NewsWrapper>
	)
}

NewsItem.propTypes = {
  item: PropTypes.shape({
		name: PropTypes.string,
		_id: PropTypes.string,
		date: PropTypes.instanceOf(Date),
		description: PropTypes.string,
		image: PropTypes.string,
		hashtag: PropTypes.arrayOf(PropTypes.string)
	}).isRequired,
	mode: PropTypes.string,
	isRevers: PropTypes.bool,
	hendleCallHashteg: PropTypes.func
};

export default NewsItem
