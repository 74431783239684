import { all, fork } from 'redux-saga/effects'

import { authSaga } from 'containers/Auth/saga'
import { communitiesSaga } from 'containers/Communities/saga'
import { projectsSaga } from 'containers/Projects/saga'
import { newsSaga } from 'containers/News/saga'
import { barnchesSaga } from 'containers/Branches/saga'
import { propositionsSaga } from 'containers/Propisitions/saga'
import { projectSaga } from 'containers/Project/saga'
import { barnchSaga } from 'containers/Branch/saga'
import { newItemSaga } from 'containers/New/saga'
import { historiesSecSaga } from 'containers/HistoriesSuccess/saga'
import { histotyItemSaga } from 'containers/History/saga'
import { propositionSaga } from 'containers/Proposition/saga'
import { communitySaga } from 'containers/Community/saga'
import { userSaga } from 'containers/sagas/user'
import { programsSaga } from 'containers/Programs/saga'
import { programSaga } from 'containers/DocumentScreen/saga'

// eslint-disable-next-line import/no-anonymous-default-export
export default function* () {
	yield all([
		fork(authSaga),
		fork(communitiesSaga),
		fork(projectsSaga),
		fork(projectSaga),
		fork(newsSaga),
		fork(barnchesSaga),
		fork(barnchSaga),
		fork(propositionsSaga),
		fork(newItemSaga),
		fork(historiesSecSaga),
		fork(histotyItemSaga),
		fork(propositionSaga),
		fork(communitySaga),
		fork(userSaga),
		fork(programsSaga),
		fork(programSaga),

	])
}
