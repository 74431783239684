import { colors } from 'components/variables'
import { useField } from 'formik'
import React, { useState } from 'react'
import { withTranslation } from 'react-i18next'
import PlacesAutocomplete, {
	geocodeByAddress,
	getLatLng,
} from 'react-places-autocomplete'
import styled from 'styled-components'
import { ErrorText, Label } from '../input/style'

const Wrapper = styled.div`
	.autocomplete-container {
		width: 100%;
		position: relative;
	}
	.location-search-input {
	}
	.autocomplete-dropdown-container {
		position: absolute;
		background: ${colors.white};
		border: none;
		width: 100%;
		z-index: 11;
		padding-left: 10px;
		&.active {
			border: 1px solid ${colors.blue};
			border-top: none;
		}
	}
	.suggestion-item {
		padding: 5px 0;
		&:first-child {
			padding-top: 10px;
		}
	}
`

const Input = styled.input`
	width: 100%;
	border: 1px solid ${(props) => (props.error ? colors.red : colors.blue)};
	border-bottom: ${(props) =>
		props.active
			? 'none'
			: `1px solid ${(props) => (props.error ? colors.red : colors.blue)}`};
	height: 52px;
	padding-left: 15px;
	position: relative;
	outline: none;
	&::placeholder {
		font-family: 'Montserrat', 'sans-serif';
		font-size: 15px;
		color: ${(props) => (props.error ? colors.red : colors.grey)};
	}
`

function Location({ handleSetLatLang, t, ...props }) {
	const [address, setAddress] = useState('')

	const [, meta] = useField({ ...props })

	const errors = meta.touched && meta.error

	const handleChange = (address) => {
		setAddress(address)
	}
	const handleSelect = (address) => {
		setAddress(address)
		geocodeByAddress(address)
			.then((results) => getLatLng(results[0]))
			.then((latLng) => {
				handleSetLatLang(latLng)
			})
			.catch((error) => console.error('Error', error))
	}

	return (
		<Wrapper {...props}>
			<Label largeLabel htmlFor='location'>
				Місце:
			</Label>
			<PlacesAutocomplete
				value={address}
				onChange={handleChange}
				onSelect={handleSelect}
			>
				{({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
					<div className='autocomplete-container'>
						{errors && <ErrorText>{t(`error.${meta.error}`)}</ErrorText>}
						<Input
							{...getInputProps({
								placeholder: 'Пошук ...',
								className: `location-search-input`,
								name: 'location',
								id: 'location',
							})}
							error={errors ? 1 : 0}
							active={!!suggestions.length > 0}
						/>
						<div
							className={`autocomplete-dropdown-container ${
								!!suggestions.length > 0 ? 'active' : ''
							}`}
						>
							{loading && <div>Loading...</div>}
							{suggestions.map((suggestion, _index) => {
								const className = suggestion.active
									? 'suggestion-item--active'
									: 'suggestion-item'
								// inline style for demonstration purpose
								const style = suggestion.active
									? { backgroundColor: '#f4f4f4', cursor: 'pointer' }
									: { backgroundColor: '#ffffff', cursor: 'pointer' }
								return (
									<div
										{...getSuggestionItemProps(suggestion, {
											className,
											style,
										})}
										key={_index}
									>
										<span>{suggestion.description}</span>
									</div>
								)
							})}
						</div>
					</div>
				)}
			</PlacesAutocomplete>
		</Wrapper>
	)
}

export default withTranslation()(Location)
