import {
	Tooltip,
	ResponsiveContainer,
	BarChart,
	XAxis,
	YAxis,
	Legend,
	Bar,
    LabelList
} from 'recharts'
import styled from 'styled-components'

const Wrapper = styled.div`
	height: ${props => props.height}px;
`

export const NormalBar = ({ pause = false, data, colors, datasize = 25, dataheight = 570, datastack = '1', dataYwidth = 60, ...props }) => {
	const fixedDataKey = props.datakey.map(item => item.replaceAll('_', ' '))
	return (
		<Wrapper height={dataheight}>
			<ResponsiveContainer width='100%' height='100%'>
				<BarChart
					width={400}
					// height={350}
					data={data}
					layout='vertical'
					stackOffset='sign'
					barSize={parseInt(datasize)}
				>
					<XAxis tickCount={5} type='number' />
					<YAxis
						width={parseInt(dataYwidth)}
						axisLine={false}
						tickLine={false}
						type='category'
						dataKey='name'
					/>
					<Tooltip />
					<Legend verticalAlign='bottom' align='center' />
					{fixedDataKey && fixedDataKey.map( (datakey, index) => (  
                    <Bar
						isAnimationActive={!pause}
						animationDuration={400}
						layout='vertical'
                        key={index}
						dataKey={datakey}
						fill={colors[index]}
						stackId={Number(datastack)}> 
                            <LabelList fill='#fff' dataKey={datakey} position="inside" />
                    </Bar>))}
				</BarChart>
			</ResponsiveContainer>
		</Wrapper>
		// <KPIWrapper>
		// 	<ResponsiveContainer width='100%' height='100%'>
		// 		<BarChart
		// 			width={500}
		// 			height={300}
		// 			data={data}
		// 			stackOffset='sign'
		// 			layout='vertical'
		// 			// margin={{
		// 			// 	top: 5,
		// 			// 	right: 30,
		// 			// 	left: 20,
		// 			// 	bottom: 5,
		// 			// }}
		// 		>
		// 			<CartesianGrid strokeDasharray='3 3' />
		// 			<XAxis />
		// 			<YAxis dataKey='name' />
		// 			<Tooltip />
		// 			<Legend />
		// 			<ReferenceLine y={0} stroke='#000' />
		// 			<Bar
		// 				dataKey='men'
		// 				// isAnimationActive={!pause}
		// 				layout='vertical'
		// 				fill='#8884d8'
		// 				stackId='stack'
		// 			/>
		// 			<Bar
		// 				dataKey='women'
		// 				// isAnimationActive={!pause}
		// 				layout='vertical'
		// 				fill='#82ca9d'
		// 				stackId='stack'
		// 			/>
		// 		</BarChart>
		// 	</ResponsiveContainer>
		// </KPIWrapper>
	)
}
