import { FillImage, NoImage, NoImg } from 'components/Styled'
import { ProgectsTitle } from 'containers/Project/style'
import React from 'react'
import { ImgBlock, ProjectsSmBg, TextBlock } from './style'
import img1 from 'static/images/NonImgColor.png'

function WithImageHead({ label, text, bg, image }) {
	const regularExp = new RegExp('img_')
	const imge = regularExp.exec(image)
	return (
		<ProjectsSmBg>
			<img className='img' src={bg} alt='' />
			<div className='container'>
				<div className='row d-flex flex-column-reverse flex-lg-row justify-content-between flex-nowrap align-items-center'>
					<TextBlock className='mt-3 mt-lg-0'>
						<ProgectsTitle>{label}</ProgectsTitle>
						{/* <ProgectsUnderTitle>{text}</ProgectsUnderTitle> */}
					</TextBlock>
					<ImgBlock>
						{imge ? (
							<FillImage className='img' src={image} alt='Item Image' />
						) : (
							<NoImage>
								<NoImg src={img1} alt='Project Image' />
							</NoImage>
						)}
					</ImgBlock>
				</div>
			</div>
		</ProjectsSmBg>
	)
}

export default WithImageHead
