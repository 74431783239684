import React from 'react'
import { Link } from 'react-router-dom'
import { Map, TileLayer, Polygon, Marker, Popup, Tooltip } from 'react-leaflet'
import L from 'leaflet'
import MarkerIcon from 'static/images/marker.png'

const purpleOptions = { color: 'purple' }
const LeafMap = ({ ...props }) => {
	const { communities } = props

	const ICON = L.icon({
		iconUrl: MarkerIcon,
		iconSize: [32, 32],
	})
	return (
		<Map
			className='map'
			center={[48.3474384, 36.3929667]}
			zoom={11}
			style={{ height: 500, width: '100%' }}
		>
			{communities?.map((community, index) => (
				<React.Fragment key={index}>
					{!!community.polygon && (
						<Polygon
							pathOptions={purpleOptions}
							positions={community.polygon}
						/>
					)}
					<Marker icon={ICON} position={community.location.coordinates}>
						<Popup>
							<div>
								<Link to={`/community/${community._id}`}>{community.name}</Link>
							</div>
						</Popup>
						<Tooltip>{community.name}</Tooltip>
					</Marker>
				</React.Fragment>
			))}
			<TileLayer
				attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
				url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
			/>
		</Map>
	)
}

export default LeafMap
