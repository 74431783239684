import styled from 'styled-components'

export const HistoriesWrapper = styled.div`
	width: 100%;
`

export const HistoriesContentWrapper = styled.div`
	margin: 0 auto;
	padding: 61px 15px 0px 15px;
	max-width: 1170px;
`
