import styled from 'styled-components'
import bg from 'static/images/newsBG.png'
import { colors, device } from 'components/variables'

export const NewsWrapper = styled.div`
	width: 100%;
`

export const ProjectBg = styled.div`
	width: 100%;
	background-repeat: no-repeat;
	background-size: cover;
	background-image: url(${bg});
	margin-bottom: 60px;
`

export const ProgectBgWrapp = styled.div`
	max-width: 1136px;
	margin: 0 auto;
	display: flex;
	align-items: center;
	flex-direction: column-reverse;
	padding: 60px 15px;
	@media ${device.tablet} {
		flex-direction: row;
	}
`

export const ProjectText = styled.div`
	text-align: center;
	margin-right: 0;
	margin-top: 30px;
	@media ${device.tablet} {
		text-align: start;
		margin-right: 60px;
		margin-top: 0;
	}
`

export const ProgectsTitle = styled.span`
	display: block;
	color: ${colors.white};
	font-weight: 800;
	font-size: 40px;
	line-height: 49px;
	margin-bottom: 20px;
	@media ${device.tablet} {
		font-size: 50px;
	}
	@media ${device.laptopS} {
		font-size: 60px;
		line-height: 73px;
	}
`

export const ProgectsUnderTitle = styled.span`
	display: block;
	color: ${colors.white};
	font-weight: normal;
	font-size: 25px;
	line-height: 30px;
	@media ${device.tablet} {
		font-size: 32px;
	}
	@media ${device.laptopS} {
		font-size: 40px;
		line-height: 49px;
	}
`

export const ProjectImageBlock = styled.div`
	max-width: 290px;
	width: 100%;
	height: 200px;
	@media ${device.tablet} {
		height: 300px;
		max-width: 400px;
	}
	@media ${device.laptopS} {
		height: 400px;
		max-width: 500px;
	}
`

export const ProjectImage = styled.img`
	display: block;
	width: 100%;
	height: 100%;
	object-fit: fill;
`

export const NewWrapper = styled.div`
	max-width: 1170px;
	margin: 0 auto;
	padding: 0 15px;
	padding-top: 60px;
	@media ${device.laptopXS} {
		padding: 0;
		padding-top: 60px;
	}
`

export const NewsContentWrapper = styled.div`
	padding: 60px 0 0 0;
`

export const NewsModelWrapper = styled.div`
	span {
		width: 100% !important;
		height: auto !important;
		max-height: 450px;
	}
	img {
		width: 100% !important;
		height: 100% !important;
		max-height: 450px !important;
		object-fit: contain; 
	}
	@media ${device.tablet} {
		span {
			width: auto !important;
			height: auto !important;
		}
		img {
			width: auto !important;
			height: auto !important;
			object-fit: cover;
		}
	}
`

export const NewsContentText = styled.div`
	margin: 30px 0 60px 0;
	font-family: PT Sans;
	font-weight: normal;
	font-size: 15px;
	color: ${colors.black};
`

export const NewsContentImg = styled.img`
	width: 100%;
	background-repeat: no-repeat;
	background-size: cover;
`

export const WraperrSlider = styled.div`
	margin-bottom: 130px;
	@media ${device.laptopS} {
		margin-bottom: 0px;
	}
`
