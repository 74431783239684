import { ScrollToTopArrow } from 'components/Icons'
import React, { useState } from 'react'
import { animateScroll as scroll } from 'react-scroll'
import { ScrollWrapper } from './styled'

function ScrollToTop(props) {
	const [showScroll, setShowScroll] = useState(false)
	function scrollToTop() {
		scroll.scrollToTop()
	}
	const checkScrollTop = () => {
		if (!showScroll && window.pageYOffset > 1800) {
			setShowScroll(true)
		} else if (showScroll && window.pageYOffset <= 400) {
			setShowScroll(false)
		}
	}

	window.addEventListener('scroll', checkScrollTop)
	return (
		<ScrollWrapper showScroll={showScroll} onClick={scrollToTop}>
			<ScrollToTopArrow />
		</ScrollWrapper>
	)
}

export default ScrollToTop
