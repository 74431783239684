import { call, put, takeLatest } from '@redux-saga/core/effects'
import { actions } from './slices'
import Api from 'utils/api'

function* getNewItemWorker({ payload }) {
	const { id } = payload
	try {
		const { data } = yield call(Api.post, '/api/articles/getById', {
			filter: { _id: id },
		})
		yield put({
			...actions.getSuccess({ data: data.article }),
		})
	} catch (e) {
		yield put({ ...actions.getError() })
	}
}

export function* histotyItemSaga() {
	yield takeLatest(actions.getHistoryItem, getNewItemWorker)
}
