import { call, put, takeLatest } from '@redux-saga/core/effects'
import Api from 'utils/api'
import { actions } from './slices'

function* getBranch({ payload }) {
	const body = {
		filter: {
			_id: payload.id,
		},
	}
	try {
		const data = yield call(Api.post, '/api/industries/getById', body)
		yield put({
			...actions.getSingleSuccess(data),
		})
	} catch (error) {
		yield put({ ...actions.getSingleError() })
	}
}

export function* barnchSaga() {
	yield takeLatest(actions.getSingleStart, getBranch)
}
