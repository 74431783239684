import { createSlice } from '@reduxjs/toolkit'

export * from './selectors'

const HistoyiesSecReducer = createSlice({
	name: 'histories',
	initialState: {
		histories: [],
		loading: false,
		name: '',
	},
	reducers: {
		getHistoies: (state) => {
			state.loading = true
		},
		getSuccess: (state, { payload }) => {
			state.histories = payload.data.articles
			state.loading = false
		},
		getError: (state) => {
			state.loading = false
		},
	},
})

export const { actions, reducer } = HistoyiesSecReducer
