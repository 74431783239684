import React, { useEffect } from 'react'
import { Viewer } from '@react-pdf-viewer/core'
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout'
import styled from 'styled-components'
import { device } from 'components/variables'

const Wrapper = styled.div`
	height: 600px;
	.rpv-default-layout-container {
		max-width: 550px;
	}
	@media ${device.laptopXS} {
		.rpv-default-layout-container {
			max-width: none;
		}
	}
`

const Mobile = styled.div`
	height: 530px;
	/* .rpv-core-page-layer,
	.rpv-core-canvas-layer {
		width: 100% !important;
	} */
`
const Desktop = styled.div`
	height: 600px;
`

function PDFViewer({ file, ...props }) {
	const defaultLayoutPluginInstance = defaultLayoutPlugin()
	let scale = 0.5

	useEffect(() => {
		scale = window.screen.width < 450 ? 0.45 : 0.65
	}, [])
	return (
		<Wrapper className='mt-6'>
			<Desktop className='d-none d-lg-block'>
				<Viewer fileUrl={file} plugins={[defaultLayoutPluginInstance]} />
			</Desktop>
			<Mobile className='d-block d-lg-none'>
				<Viewer defaultScale={scale} fileUrl={file} />
			</Mobile>
		</Wrapper>
	)
}

export default PDFViewer
