const defaultHost = {
  host: 'crinvest.biz',
  staticUrl: 'https://crinvest.biz/',
  apiUrl: 'https://crinvest.biz/',
  prod: false,
};

const hosts = {
  localhost: defaultHost,
  'crinvest.biz': {
    host: 'crinvest.biz',
    staticUrl: 'https://crinvest.biz/',
    apiUrl: 'https://crinvest.biz/',
    prod: false,
  },
  'crinvest.biz': {
    host: 'crinvest.biz',
    staticUrl: 'https://crinvest.biz/',
    apiUrl: 'https://crinvest.biz/',
    dev: false,
    prod: true,
  },
};

const currentHost = hosts[window.location.hostname]
  ? hosts[window.location.hostname]
  : defaultHost;

export default currentHost;
