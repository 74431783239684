import { call, put, takeLatest } from '@redux-saga/core/effects';
import Api from 'utils/api';
import { actions } from './slices';

function* getCommunityWorker({ payload }) {
  const body = {
    filter: {
      _id: payload.id,
    },
  };
  try {
    const { data } = yield call(Api.post, '/api/communities/getById', body);
    yield put({
      ...actions.getSuccess(data),
    });
  } catch (e) {
    yield put({ ...actions.getError() });
  }
}

export function* communitySaga() {
  yield takeLatest(actions.getCommunity, getCommunityWorker);
}
