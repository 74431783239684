import environment from 'environment'
export class ProjectModel {
	constructor(data = {}) {
		const { project } = data
		this.info = []
		if (!!project?.info) {
			const temp = project.info.filter((obj) => !!obj)
			temp.map((item) => {
				this.info.push(`${environment.staticUrl}docs/${item.file}`)
				return true
			})
		}
		this._id = project?.id || ''
		this.image = `${environment.staticUrl}images/${project?.mainphoto}`
		this.name = project?.name || ''
		this.date = project?.createdAt || ''
		this.hashtag =
			project?.hashtag
				?.split(' ')
				?.map((item) => ({ label: '#' + item, value: item })) || []
		this.content = project?.content || ''
		this.location = project?.location || {}
		this.financeType = project?.financeType || ''
		this.property = project?.properties
			? ReduceProperty(project.properties)
			: {}
	}
}
//projects properties data
export class ProjectProperty {
	constructor(data = {}) {
		this.invest = data.invest || ''
		this.square = data.square || ''
		this.implementation_period = data.implementation_period || ''
		this.phone = data.phone || ''
		this.email = data.email || ''
		this.ownership = data.ownership || ''
		this.state_part = data.state_part || ''
		this.projectGoal = data.projectGoal || ''
		this.adress = data.adress || ''
		this.result = data.result || ''
		this.interest = data.interest || ''
	}
}

function ReduceProperty(array) {
	const temp = array.filter((obj) => !!obj)
	return temp.reduce((acc, obj) => {
		return { ...acc, [obj.name]: obj.value }
	}, {})
}
