export class UserModel {
	constructor(data = {}) {
		this._id = data._id || ''
		this.name = data.name || ''
		this.email = data.email || ''
		this.phone = data?.phoneNumber || ''
		this.community = data.community || ''
		this.admin = data.admin || true
	}
}
