import { createSelector } from 'reselect'

const selectDomain = (state) => state.auth

export const select = {
	isAuth: createSelector(selectDomain, (sub) => sub.isAuth),
	loading: createSelector(selectDomain, (sub) => sub.loading),
	error: createSelector(selectDomain, (sub) => sub.error),
	isValid: createSelector(selectDomain, (sub) => sub.isValid),
}
