import { ArrowDown } from 'components/Icons'
import { SubtitleLarge } from 'components/Styled'
import { colors, device } from 'components/variables'
import React from 'react'
import styled, { css } from 'styled-components'
import { textColor } from 'utils/help-func'

const ItemWrapp = styled.div`
	overflow: hidden;
	margin: 15px;
	max-width: 462px;
	display: flex;
	justify-content: space-between;
	flex-direction: column;
`

const Item = styled.div`
	border: 1px solid ${colors.blue};
	background: ${(props) => (props.open ? colors.white : colors.blue)};
	${(props) => textColor({ defaultColor: 'white', ...props })}
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 20px 10px;
	height: 65px;
	font-family: 'Montserrat', sans-serif;
	transition: 0.25s;
	${(props) =>
		props.open &&
		css`
			.arrow {
				transform: rotate(180deg);
			}
		`}
	@media ${device.tablet} {
		padding: 28px 20px;
		height: 86px;
	}
`

const ItemText = styled.span`
	font-size: 17px;
	line-height: 21px;
	font-family: 'PT Sans', 'sans-serif';
	transition: 0.25s;
	margin-top: ${(props) => (props.open ? '20px' : '0')};
	max-height: 0;
	${(props) =>
		props.open &&
		css`
			max-height: 320px !important;
		`}
	@media ${device.tablet} {
		font-size: 18px;
		line-height: 23px;
	}
`

function AcardionItem({ item, open,t, ...props }) {
	let color = open ? 'blue' : 'white'

	const { title, text } = item

	return (
		<ItemWrapp open={open} {...props}>
			<Item open={open}>
				<SubtitleLarge color={color}>{title}</SubtitleLarge>
				<ArrowDown className='arrow' color={color} width='28' height='16' />
			</Item>
			<ItemText open={open}>{text}</ItemText>
		</ItemWrapp>
	)
}

export default AcardionItem
