import styled from 'styled-components'
import { colors, device } from 'components/variables'

export const ProjectsLinksSearch = styled.div`
	flex-direction: column-reverse;
	padding-bottom: 25px;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	position: relative;
	&::before {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 1px;
		background-color: ${colors.orange};
	}
	@media ${device.size_850} {
		flex-direction: row;
		height: 65px;
	}
`

export const ProjectsLinks = styled.span`
	font-weight: 500;
	font-size: 20px;
	line-height: 40px;
	margin-top: ${(props) => (props.admin ? '0' : '25px')};
	@media ${device.size_850} {
		margin-top: 0;
	}
`

export const ProjectsLink = styled.span`
	color: ${colors.black};
	&:hover {
		color: ${colors.blue};
		text-decoration: underline;
	}
`

export const ProjectsPath = styled.span`
	color: ${colors.blue};
	display: inline-block;
	vertical-align: middle;
	max-width: 300px;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
`

export const ProjectsSearch = styled.div`
	height: 100%;
	display: flex;
	width: 100%;
	flex-direction: column-reverse;
	@media ${device.size_850} {
		justify-content: flex-end;
		margin: 0;
	}
	@media ${device.mobileXL} {
		justify-content: flex-start;
		flex-direction: row;
		width: auto;
		margin-bottom: 25px;
	}
`

export const ProjectsSearchPlas = styled.div`
	width: 100%;
	height: 40px;
	background: ${colors.blue};
	outline: none;
	border: none;
	padding: 0;
	position: relative;
	cursor: pointer;
	margin: 15px 0 25px 0;
	@media ${device.size_850} {
		margin: ${(props) => (props.searchBox ? '0 30px 0 15px' : '0 0px 0 15px')};
	}
	@media ${device.mobileXL} {
		margin: ${(props) => (props.searchBox ? '0 30px 0 0' : '0')};
		width: 70px;
	}
`

export const ProjectsSearchLoop = styled.button`
	height: 40px;
	width: 100%;
	background: ${colors.blue};
	outline: none;
	border: none;
	padding: 0;
	position: relative;
	cursor: pointer;
	@media ${device.mobileXL} {
		width: 70px;
	}
`

export const ProjectsIcons = styled.div`
	left: 50%;
	top: 50%;
	width: 20px;
	height: 20px;
	transform: translate(-50%, -50%);
	position: absolute;
	&.blur {
		display: none;
	}
	&.vis {
		.litlePlus {
			margin-right: 10px;
		}
		height: auto;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		font-weight: bold;
		font-size: 16px;
		line-height: 21px;
		color: ${colors.white};
	}
	@media ${device.mobileXL} {
		&.blur {
			display: block;
		}
		&.vis {
			display: none;
		}
	}
`

export const ProjectsInpContainer = styled.div`
	height: 40px;
	position: relative;
`

export const ProjectsIconsInp = styled.div`
	left: 11px;
	top: 50%;
	width: 19px;
	height: 19px;
	transform: translate(0, -50%);
	position: absolute;
`
